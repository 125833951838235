<style scoped></style>
<template>
  <div>
    <v-dialog v-model="show_dialog" scrollable width="450">
      <v-card class="pb-0">
        <v-card-title class="primary_2 white--text" primary-title>
          <p class="text-h6">Upload Address Proof</p>
          <v-spacer></v-spacer>
          <v-icon large color="white" @click="show_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3 pb-0">
          <v-form ref="upload_address_proof">
            <div>Address Proof Type</div>
            <v-select
              outlined
              dense
              :items="address_proof_type_list"
              item-text="text"
              item-value="value"
              :rules="$rules.REQURIED_FILED"
              required
              v-model="address_proof_type"
              placeholder="Select type"
            ></v-select>
            <div>Document</div>
            <v-file-input
              outlined
              dense
              accept=".pdf"
              v-model="file"
              prepend-icon="mdi-file-pdf-box"
              placeholder="Select"
              :rules="$rules.REQURIED_FILED"
              required
            ></v-file-input>
            <div>Document Status</div>
            <v-radio-group v-model="is_reupload" row>
              <v-radio label="Upload" :value="false"></v-radio>
              <v-radio label="Reupload" :value="true"></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-2">
          <v-btn
            color="secondary_2"
            :loading="btn_loader"
            :disabled="btn_loader"
            class="font-weight-bold white--text"
            @click="uploadAddressProof()"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      address_proof_type: "",
      address_proof_type_list: [
        {
          text: "Electricity Bill",
          value: "electricity_bill",
        },
        {
          text: "Rental Agreement",
          value: "rental_agreement",
        },
        {
          text: "Phone Bill",
          value: "phone_bill",
        },
        {
          text: "Passport",
          value: "passport",
        },
        {
          text: "Driving License",
          value: "driving_license",
        },
        {
          text: "Voter Id",
          value: "voter_id",
        },
        {
          text: "Passbook",
          value: "passbook",
        },
        {
          text: "Other",
          value: "Other",
        },
      ],
      file: null,
      password: "",
      is_reupload: false,
      btn_loader: false,
    };
  },
  methods: {
    async openAddressProofDialog() {
      this.show_dialog = true;
    },
    uploadAddressProof() {
      if (this.file) {
        const self = this;
        self.btn_loader = true;

        let form = new FormData();
        form.append("customer_id", self.decrypt(self.$route.params.id));
        form.append("file", self.file);
        form.append("document_type", self.address_proof_type);
        form.append("is_reupload", self.is_reupload);

        const successHandler = (response) => {
          if (response.status == 200) {
            this.$emit("onSuccess");
            this.$refs.upload_address_proof.reset();
            this.is_reupload = false;
            self.btn_loader = false;
            this.show_dialog = false;
          }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_POST(
          self,
          self.$urls.UPLOAD_ADDRESS_PROOF,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        this.showSnakeBar("error", "Select File before uploading");
      }
    },
  },
};
</script>
