<template>
  <div>
    <v-dialog
      scrollable
      v-model="personal_data.flag"
      width="600"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h5 white--text primary d-flex justify-space-between align-center"
        >
          <div>Edit Personal Details</div>

          <div>
            <v-btn @click="personal_data.flag = false" icon
              ><v-icon color="white">mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="py-5">
          <v-form lazy-validation ref="personalDetailForm">
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Father's Name"
                  dense
                  v-model="personal_info.father_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Mother's Name"
                  dense
                  v-model="personal_info.mother_name"
                  class="pl-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menu1"
                  v-model="dateObMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="personal_info.dob"
                      placeholder="Select Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      background-color="#FFF"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="personal_info.dob"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateObMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="dateObMenu = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  dense
                  :items="education_list"
                  outlined
                  item-text="text"
                  item-value="value"
                  label="Education"
                  v-model="personal_info.education"
                  class="pl-2"
                ></v-select>
              </v-col>
              <v-col cols="12" class="mb-4">
                <h3>CURRENT ADDRESS</h3>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="House No./Building Name"
                  v-model="personal_info.current_add.houseNo"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Street Name/Landmark"
                  v-model="personal_info.current_add.landmark"
                  class="pl-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Area"
                  v-model="personal_info.current_add.area"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Pincode"
                  class="pl-2"
                  v-model="personal_info.current_add.pincode"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="City"
                  v-model="personal_info.current_add.city"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="State"
                  class="pl-2"
                  v-model="personal_info.current_add.state"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-3">
                <h3>PERMANENT ADDRESS</h3>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="House No./Building Name"
                  v-model="personal_info.permanent_add.houseNo"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Street Name/Landmark"
                  v-model="personal_info.permanent_add.landmark"
                  class="pl-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Area"
                  v-model="personal_info.permanent_add.area"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="Pincode"
                  class="pl-2"
                  v-model="personal_info.permanent_add.pincode"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="City"
                  v-model="personal_info.permanent_add.city"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  label="State"
                  class="pl-2"
                  v-model="personal_info.permanent_add.state"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary white--text"
            @click="updatePersonalData()"
            :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import dayjs from "dayjs";
export default {
  props: ["personal_detail"],
  data() {
    return {
      personal_data: this.personal_detail,
      loading: false,
    
      dateObMenu: false,
      education_list: [
        {
          text: "Under Graduate",
          value: "Under Graduate",
        },
        {
          text: "Graduate",
          value: "Graduate",
        },
        {
          text: "Post Graduate",
          value: "Post Graduate",
        },
      ],
      personal_info: {
        father_name: "",
        mother_name: "",
        dob: null,
        education: "",
        current_add: {
          houseNo: "",
          landmark: "",
          area: "",
          pincode: null,
          city: "",
          state: "",
        },
        permanent_add: {
          houseNo: "",
          landmark: "",
          area: "",
          pincode: null,
          city: "",
          state: "",
        },
      },
    };
  },
  watch: {
    "personal_detail.flag": function(value) {
      if (value) {
        console.log(this.personal_data);
        this.personal_info.father_name = this.personal_data.personal_info.fathers_name;
        this.personal_info.mother_name = this.personal_data.personal_info.mothers_name;
        this.personal_info.dob = this.display_date_formate2(
          this.personal_data.personal_info.date_of_birth
        );
        this.personal_info.education = this.personal_data.personal_info.education;
        this.personal_info.current_add.houseNo = this.personal_data.personal_info.current_address.address_details;
        this.personal_info.current_add.landmark = this.personal_data.personal_info.current_address.landmark;
        this.personal_info.current_add.area = this.personal_data.personal_info.current_address.area;
        this.personal_info.current_add.pincode = this.personal_data.personal_info.current_address.pincode;
        this.personal_info.current_add.city = this.personal_data.personal_info.current_address.city;
        this.personal_info.current_add.state = this.personal_data.personal_info.current_address.state;
        this.personal_info.permanent_add.houseNo = this.personal_data.personal_info.permanent_address.address_details;
        this.personal_info.permanent_add.landmark = this.personal_data.personal_info.permanent_address.landmark;
        this.personal_info.permanent_add.area = this.personal_data.personal_info.permanent_address.area;
        this.personal_info.permanent_add.pincode = this.personal_data.personal_info.permanent_address.pincode;
        this.personal_info.permanent_add.city = this.personal_data.personal_info.permanent_address.city;
        this.personal_info.permanent_add.state = this.personal_data.personal_info.permanent_address.state;
      }
    },
  },
  methods: {
    updatePersonalData() {
      if (this.$refs.personalDetailForm.validate()) {
        const self = this;
        let look_up_key = this.personal_data.id;

        let formData = {};

        formData["fathers_name"] = this.personal_info.father_name;
        formData["mothers_name"] = this.personal_info.mother_name;
        formData["dob"] = this.personal_info.dob;
        formData["education"] = this.personal_info.education;
        formData[
          "current_address_details"
        ] = this.personal_info.current_add.houseNo;
        formData["current_landmark"] = this.personal_info.current_add.landmark;
        formData["current_area"] = this.personal_info.current_add.area;
        formData["current_pincode"] = this.personal_info.current_add.pincode;
        formData["current_city"] = this.personal_info.current_add.city;
        formData["current_state"] = this.personal_info.current_add.state;
        formData[
          "permanent_address_details"
        ] = this.personal_info.permanent_add.houseNo;
        formData[
          "permanent_landmark"
        ] = this.personal_info.permanent_add.landmark;
        formData["permanent_area"] = this.personal_info.permanent_add.area;
        formData[
          "permanent_pincode"
        ] = this.personal_info.permanent_add.pincode;
        formData["permanent_city"] = this.personal_info.permanent_add.city;
        formData["permanent_state"] = this.personal_info.permanent_add.state;

        const successHandler = () => {
          self.personal_data.flag = false;
          self.$emit("reload");
        };
        self.request_PATCH(
          self,
          self.$urls.UPDATE_PERSONAL_DETAIL,
          `${look_up_key}/`,
          formData,
          successHandler,
          null,
          null,
          null
        );
      }
    },
  },
};
</script>
