export const namespaced = true;

export const state = {
  primary_drawer: true,
  items: [
    {
      title: "New Customer",
      url: { name: "new-customers" },
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Customer",
      url: { name: "customers" },
      // src: require('@/assets/images/customer.svg'),
      // src_active: require('@/assets/images/customer-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Business Loan",
      url: { name: "business-loan" },
      // src: require('@/assets/images/customer.svg'),
      // src_active: require('@/assets/images/customer-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "LAS",
      url: { name: "loan-against-securities" },
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Pre Verification",
      url: { name: "pre-verification" },
      // src: require('@/assets/images/customer.svg'),
      // src_active: require('@/assets/images/customer-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Loan Applied",
      url: { name: "loan-applied" },
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "PL Funnel",
      url: { name: "pl-funnel" },
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Team",
      url: { name: "team" },
      // src: require('@/assets/images/team.svg'),
      // src_active: require('@/assets/images/team-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Notification Panel",
      url: { name: "notification-panel" },
      // src: require('@/assets/images/team.svg'),
      // src_active: require('@/assets/images/team-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "EMI Analysis",
      url: { name: "emi-analysis" },
      // src: require('@/assets/images/team.svg'),
      // src_active: require('@/assets/images/team-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Payment",
      url: { name: "payment" },
      // src: require('@/assets/images/team.svg'),
      // src_active: require('@/assets/images/team-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Review",
      url: { name: "review" },
      // src: require('@/assets/images/team.svg'),
      // src_active: require('@/assets/images/team-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Service Area",
      url: { name: "service_area" },
      // src: require('@/assets/images/team.svg'),
      // src_active: require('@/assets/images/team-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    {
      title: "Session History",
      url: { name: "session_history" },
      // src: require('@/assets/images/team.svg'),
      // src_active: require('@/assets/images/team-active.svg'),
      is_active_tab: false,
      is_django_tab: false,
    },
    // {
    //     title: "Manage your Team",
    //     url: 'https://api.cashpo.in/admin/webpanel/ourteamdata/',
    //     // src: require('@/assets/images/team.svg'),
    //     // src_active: require('@/assets/images/team-active.svg'),
    //     is_active_tab: false,
    //     is_django_tab: true,
    // },
    // {
    //     title: "Manage FAQ",
    //     url: 'https://api.cashpo.in/admin/master/masterfaqdata/',
    //     // src: require('@/assets/images/team.svg'),
    //     // src_active: require('@/assets/images/team-active.svg'),
    //     is_active_tab: false,
    //     is_django_tab: true,
    // },
    // {
    //     title: "Manage User Contacts",
    //     url: 'https://api.cashpo.in/admin/users/usercontactdata/',
    //     // src: require('@/assets/images/team.svg'),
    //     // src_active: require('@/assets/images/team-active.svg'),
    //     is_active_tab: false,
    //     is_django_tab: true,
    // },
  ],
};

export const mutations = {
  SET_ACTIVE_TAB(state, index) {
    localStorage.setItem("active-tab", index);

    // for (let i = 0; i < state.items.length; i++) {
    //     state.items[i].is_active_tab = false;
    // }

    // //setting adtive tab

    if (index != -1) {
      state.items[0].is_active_tab = true;
    }
  },
  SET_PRIMARY_DRAWER(state, value) {
    state.primary_drawer = value;
  },
};

export const actions = {
  setActiveTab({ commit }, index) {
    commit("SET_ACTIVE_TAB", index);
  },
  setPrimaryDrawer({ commit }, value) {
    commit("SET_PRIMARY_DRAWER", value);
  },
};
export const getters = {
  getDrawerItems: (state) => {
    return state.items;
  },
  getManageTab: (state) => {
    return state.items[9];
  },
};
