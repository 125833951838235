<style scoped>
.switch-text {
  /* text-decoration: underline; */
  /* text-decoration-color: #F18324; */
  color: #f18324;
  font-weight: 900 !important;
  text-decoration-thickness: 3px;
}
.toggle-switch-wrapper {
  background: #f5ab6a56;
  border-radius: 30px;
  max-width: fit-content;
  margin: 0 auto;
  padding: 8px 1px;
  position: relative;
  display: flex;
}
.toggle-switch-text {
  border-radius: 40px;
  padding: 6px;
  font-weight: 800;
  width: 130px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.toggle-switch-bg {
  background: #f18324;
  border-radius: 40px;
  position: absolute;
  /* width: 120px; */
  /* height: 42px; */
  width: 45%;
  height: 38px;
  z-index: 1;
  top: 6px;
  left: 2px;
  transition: transform 400ms ease-in-out;
}
.toggle-switch-bg-left {
  transform: translateX(3%);
}
.toggle-switch-bg-right {
  transform: translateX(115%);
}
.active-switch-text {
  color: #fff;
  transition: color 500ms ease-in-out;
}
</style>
<template>
  <div>
    <v-dialog scrollable width="600" v-model="dialog.flag">
      <v-card>
        <v-card-title class="primary_2 d-flex">
          <p class="text-h6 white--text">
            Request Again Reason
          </p>
          <v-spacer></v-spacer>
          <v-btn fab text small @click="dialog.flag = false">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <div v-if="this.dialog.document_type == 'bank_statement'">
            <div class="my-3">
              <div class="toggle-switch-wrapper">
                <div :class="toggleSwitchBg"></div>
                <div
                  @click="showBasic()"
                  class="toggle-switch-text text-center"
                  :class="basicSwitchActive"
                >
                  Basic
                </div>
                <div
                  @click="showCalendar()"
                  class="toggle-switch-text text-center"
                  :class="loanSwitchActive"
                >
                  Calendar
                </div>
              </div>
            </div>
            <div v-if="is_calendar_requried">
              <v-radio-group v-model="reason_with_months" class="text-caption">
                <v-radio
                  class=""
                  v-for="item in reason_list_months"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
                ></v-radio>
              </v-radio-group>
              <v-card class="">
                <v-date-picker
                  full-width
                  v-model="months_list"
                  type="month"
                  multiple
                  landscape
                  :max="current_month"
                ></v-date-picker>
              </v-card>
            </div>
            <div v-else>
              <v-radio-group
                v-model="reason_without_months"
                class="text-caption"
              >
                <v-radio
                  class="mb-2"
                  v-for="item in reason_list"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
          <!-- <div> -->
          <div v-else-if="this.dialog.document_type == 'address_proof'">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="requestAgainAddressProof()"
            >
              <v-text-field
                v-model="request_again_reason"
                outlined
                dense
                class="mt-4"
                required
                placeholder="Enter request again reason"
                :rules="$rules.REQURIED_FILED"
              >
              </v-text-field>
            </v-form>
            <p>Reference:</p>
            <ul>
              <li>UPLOAD CURRENT ADDRESS PROOF</li>
            </ul>
          </div>
          <!-- </div> -->
        </v-card-text>
        <v-card-actions class="mt-0 pt-0 pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            dark
            class="secondary_2"
            :loading="btn_loader"
            :disabled="btn_loader"
            @click="
              dialog.document_type == 'address_proof'
                ? requestAgainAddressProof()
                : dialog.document_type == 'bank_statement'
                ? requestAgainBankStatement()
                : false
            "
            elevation="0"
          >
            Request Again
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  props: ["dialog"],
  data() {
    return {
      btn_loader: false,
      request_again_reason: "",
      valid: true,
      months_list: [],
      menu: false,
      formated_month: "",
      current_month: dayjs(new Date()).format("YYYY-MM  "),
      reason_with_months: "",
      reason_without_months: "",
      bank_statement_required_reason: "",
      select_month: false,
      is_calendar_requried: true,
      reason_list_months: [
        {
          text:
            "SALARY NOT FOUND. PLEASE UPLOAD BANK STATEMENT(S) CONTAINING SALARY OF ____ MONTH(S)",
        },
        {
          text:
            "INCORRECT BANK STATEMENT PASSWORD. PLEASE UPLOAD BANK STATEMENT(S) WITH CORRECT PASSWORD OF ____ MONTH(S)",
        },
        {
          text:
            "BANK STATEMENT PASSWORD NOT FOUND. PLEASE UPLOAD BANK STATEMENT(S) WITH PASSWORD OF ____ MONTH(S)",
        },
        {
          text: "PLEASE PROVIDE BANK STATEMENT(S) FOR THE ____ MONTH(S)",
        },
      ],
      reason_list: [
        {
          text:
            "SALARY NOT FOUND. PLEASE UPLOAD BANK STATEMENT(S) CONTAINING SALARY.",
        },
        {
          text:
            "INCORRECT BANK STATEMENT PASSWORD. PLEASE UPLOAD BANK STATEMENT(S) WITH CORRECT PASSWORD.",
        },
        {
          text:
            "BANK STATEMENT PASSWORD NOT FOUND. PLEASE UPLOAD BANK STATEMENT(S) WITH PASSWORD.",
        },
      ],
    };
  },
  watch: {
    is_calendar_requried() {
      this.reason_without_months = "";
      this.reason_with_months = "";
    },
    months_list(value) {
      var new_list = [];
      for (let i = 0; i < value.length; i++) {
        new_list[i] = dayjs(value[i])
          .format("MMMM")
          .toUpperCase();
      }
      this.formated_month = new_list.toString().replace(/,/g, ", ");
      // console.log(this.formated_month);
      // this.formated_month = dayjs(value)
      //   .format("MMMM")
      //   .toUpperCase()
      //   .toString();
    },
  },
  computed: {
    toggleSwitchBg() {
      let a = "toggle-switch-bg";

      if (this.is_calendar_requried == false) {
        a = "toggle-switch-bg toggle-switch-bg-left";
        return a;
      } else {
        a = "toggle-switch-bg toggle-switch-bg-right";
        return a;
      }
    },
    basicSwitchActive() {
      let a = "";
      if (this.is_calendar_requried == false) a = "active-switch-text";
      return a;
    },
    loanSwitchActive() {
      let a = "";
      if (this.is_calendar_requried == true) a = "active-switch-text";
      return a;
    },
  },
  methods: {
    showBasic() {
      this.is_calendar_requried = false;
    },
    showCalendar() {
      this.is_calendar_requried = true;
    },
    /**
     * @description Post address proof request again reason
     */
    requestAgainAddressProof() {
      if (this.$refs.form.validate()) {
        const self = this;
        self.btn_loader = true;
        let form = new FormData();
        // form.append("document_id", this.document_id);
        form.append("customer_id", self.decrypt(this.$route.params.id));
        form.append("document_type", self.dialog.document_type);
        form.append("document_status", "requested");
        form.append("rejection_reason", "");
        form.append("address_proof_required_reason", self.request_again_reason);
        const successHandler = (response) => {
          if (response.data.success) {
            self.dialog.flag = false;
            self.$emit("recallAddressDetails");
          }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_POST(
          self,
          self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
    /**
     * @description Post bank statement request again reason
     */
    requestAgainBankStatement() {
      if (this.is_calendar_requried && this.reason_with_months) {
        if (this.months_list.length != 0 && this.months_list.length < 5) {
          const self = this;
          self.btn_loader = true;
          let form = new FormData();
          // form.append("document_id", this.document_id);
          let bank_statement_required_reason = this.reason_with_months.replace(
            "____",
            this.formated_month
          );
          form.append("customer_id", self.decrypt(this.$route.params.id));
          form.append("document_type", self.dialog.document_type);
          form.append("document_status", "requested");
          form.append("rejection_reason", "");
          form.append(
            "bank_statement_required_reason",
            bank_statement_required_reason
          );
          const successHandler = (response) => {
            if (response.data.success) {
              self.dialog.flag = false;
              self.$emit("recallBankDetails");
            }
          };
          const finallyHandler = () => {
            self.btn_loader = false;
          };
          self.request_POST(
            self,
            self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
            form,
            successHandler,
            null,
            null,
            finallyHandler
          );
        } else {
          if (this.months_list.length == 0) {
            this.showSnakeBar("error", "Please select atleast one month");
          } else if (this.months_list.length > 4) {
            this.showSnakeBar("error", "Cannot select more than 4 months");
          }
        }
      } else if (!this.is_calendar_requried && this.reason_without_months) {
        const self = this;
        self.btn_loader = true;
        let form = new FormData();
        // form.append("document_id", this.document_id);
        form.append("customer_id", self.decrypt(this.$route.params.id));
        form.append("document_type", self.dialog.document_type);
        form.append("document_status", "requested");
        form.append("rejection_reason", "");
        form.append(
          "bank_statement_required_reason",
          this.reason_without_months
        );
        const successHandler = (response) => {
          if (response.data.success) {
            self.dialog.flag = false;
            self.$emit("recallBankDetails");
          }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_POST(
          self,
          self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else if (!this.reason_with_months && !this.reason_without_months) {
        this.showSnakeBar("error", "Please select reason");
      }
    },
  },
};
</script>
