<style scoped>
.pin_code_enabled {
  color: #003399;
  font-weight: bold;
  text-align: center;
  border-radius: 30px;
  padding: 5px;
}

.pin_code_disabled {
  color: #808080bf;
  font-weight: bold;
  text-align: center;
  border-radius: 30px;
  padding: 5px;
}

.filter-btn {
  display: inline-block;
}

.active-filter {
  background-color: #ffff;
  border-radius: 50px;
}
</style>
<template>
  <div>
    <div>
      <v-row class="pl-3">
        <v-col class="text-center" cols="12" md="3">
          <v-select
            :items="personal_loan_pincode_type_list"
            item-value="value"
            item-text="title"
            v-model="is_serviceable"
            class="filter"
            dense
            clearable
            filled
            label="Personal Loan Serviceable"
            outlined
            placeholder="Personal Loan"
            @change="getPincodeList()"
          >
          </v-select>
        </v-col>
        <v-col class="text-center" cols="12" md="3">
          <v-select
            :items="business_loan_pincode_type_list"
            item-value="value"
            item-text="title"
            v-model="is_serviceable_for_business_loan"
            class="filter"
            dense
            clearable
            filled
            label="Business Loan Serviceable"
            outlined
            placeholder="Personal Loan"
            @change="getPincodeList()"
          >
          </v-select>
        </v-col>
      </v-row>
    </div>
    <!-- <div
      v-for="(item, i) in personal_loan_pincode_type_list"
      :key="i"
      :class="
        is_serviceable == item.value
          ? 'elevation-1 active-filter'
          : 'elevation-0'
      "
      class="px-4 py-1 mx-2 filter-btn cursor-pointer"
      @click="applyFilter(item)"
    >
      {{ item.title }}
    </div>
    <div
      v-for="(item, i) in business_loan_pincode_type_list"
      :key="i"
      :class="
        is_serviceable_for_business_loan == item.value
          ? 'elevation-1 active-filter'
          : 'elevation-0'
      "
      class="px-4 py-1 mx-2 filter-btn cursor-pointer"
      @click="applyBusinessLoanFilter(item)"
    >
      {{ item.title }}
    </div> -->

    <v-card class="mt-10" flat>
      <v-data-table
        :disable-sort="true"
        :headers="headers"
        :items="pin_code_list"
        :items-per-page="$keys.PAGE_LENGTH"
        :loading="table_loading"
        class="border-a border-0 custome-header-bg"
        hide-default-footer
      >
        <template v-slot:item.pin_code="{ item }">
          <p
            :class="
              item.is_serviceable ? 'pin_code_enabled' : 'pin_code_disabled'
            "
          >
            {{ item.pin_code }}
          </p>
        </template>

        <template v-slot:item.is_serviceable="{ item }">
          <div class="d-flex align-center justify-center">
            <v-switch
              v-model="item.is_serviceable"
              :color="item.is_serviceable ? 'primary' : 'error'"
              dense
              inset
              @change="openConfirmationDialog(item, 'personal_loan')"
            ></v-switch>
          </div>
        </template>
        <template v-slot:item.is_serviceable_for_business_loan="{ item }">
          <div class="d-flex align-center justify-center">
            <v-switch
              v-model="item.is_serviceable_for_business_loan"
              :color="
                item.is_serviceable_for_business_loan ? 'primary' : 'error'
              "
              dense
              inset
              @change="openConfirmationDialog(item, 'business_loan')"
            ></v-switch>
          </div>
        </template>

        <!--        <template v-slot:item.action="{ item }">-->
        <!--          <v-btn class="mr-2" fab small text @click="openEditDialog(item.id)">-->
        <!--            <img alt="" src="@/assets/images/edit.svg" width="20">-->
        <!--          </v-btn>-->
        <!--        </template>-->
      </v-data-table>
    </v-card>

    <v-pagination
      v-model="page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getPincodeList"
    ></v-pagination>
    <ConformationDialog
      :dialog="conformation_dialog_data"
      @update="getPincodeList"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    ConformationDialog: () =>
      import("@/components/service-area/ConformationDialog"),
  },
  data() {
    return {
      table_loading: false,
      page_number: 1,
      total_page_count: 0,
      pincode_filter: null,
      personal_loan_pincode_type_list: [
        {
          title: "All",
          value: null,
        },
        {
          title: "Serviceable",
          value: true,
        },
        {
          title: "Un-Serviceable",
          value: false,
        },
      ],
      business_loan_pincode_type_list: [
        {
          title: "All",
          value: null,
        },
        {
          title: "Serviceable",
          value: true,
        },
        {
          title: "Un-Serviceable",
          value: false,
        },
      ],
      pin_code_list: [],
      is_serviceable: null,
      is_serviceable_for_business_loan: null,
      date_range: [],
      headers: [
        // { text: "SN", value: "sno" },
        { text: "Pin Code", value: "pin_code", align: "center" },
        { text: "Division/City", value: "division" },
        { text: "Circle", value: "circle" },
        { text: "Region", value: "region" },
        { text: "State", value: "state" },
        {
          text: "Personal Loan Serviceable",
          value: "is_serviceable",
          width: "160px",
          align: "center",
        },
        {
          text: "Business Loan Serviceable",
          value: "is_serviceable_for_business_loan",
          width: "160px",
          align: "center",
        },
        // {text: '', value: 'action', align: 'center', width: "40px"},
      ],
      /*dialog data*/
      conformation_dialog_data: {
        flag: false,
        pin_code: null,
        is_serviceable: false,
        loan_status: "",
        pincode_id: null,
        is_serviceable_for_business_loan: false,
      },
      /*dialog data*/
      dialog_data: {
        flag: false,
        title: "",
        is_edit: false,
        loading: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
    }),
    itemsWithSno() {
      return this.pin_code_list;
    },
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      if (new_search_query != old_search_query) {
        this.getPincodeList();
      }
    },
  },
  created() {
    console.log(this.$route.meta.show_back_btn);
    this.getPincodeList();
  },
  methods: {
    /* fetching the customer list form server*/
    getPincodeList() {
      const self = this;
      console.log("oin url", self.$urls.PINCODE_LIST_2);
      let lookup_key = "";
      self.table_loading = true;
      self.toggle_loading();
      let params = {
        page_number: this.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        is_serviceable: this.is_serviceable,
        is_serviceable_for_business_loan: this.is_serviceable_for_business_loan,
      };
      console.log(params);
      const successHandler = (response) => {
        console.log("response", response);
        // if (response.data.success) {
        self.pin_code_list = response.data.result.data;
        console.log("pincode list", self.pin_code_list);
        self.total_page_count = response.data.result.total_page_count;
        // }
      };
      const finallyHandler = () => {
        self.table_loading = false;
        // self.toggle_loading()
      };

      self.GET_request(
        self,
        self.$urls.PINCODE_LIST_2,
        lookup_key,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    /* Open the Add member dialog form */
    openAddDialog() {
      this.dialog_data.title = this.$lang.ADD_MEMBER;
      this.dialog_data.is_edit = false;
      this.dialog_data.flag = true;
      setTimeout(() => {
        this.$refs.add_member_dialog.resetForm();
        this.$refs.add_member_dialog.id = null;
      }, 80);
    },
    /*Open the edit dialog form*/
    openEditDialog(id) {
      this.dialog_data.title = this.$lang.EDIT_MEMBER;
      this.$refs.add_member_dialog.getMemberDetails(id);
      this.dialog_data.is_edit = true;
      this.dialog_data.flag = true;
    },
    /* confirmation dialog */
    openConfirmationDialog(item, loan_status) {
      this.conformation_dialog_data.pin_code = item.pin_code;
      this.conformation_dialog_data.is_serviceable = item.is_serviceable;
      this.conformation_dialog_data.is_serviceable_for_business_loan =
        item.is_serviceable_for_business_loan;
      this.conformation_dialog_data.loan_status = loan_status;
      this.conformation_dialog_data.pincode_id = item.id;
      this.conformation_dialog_data.flag = true;
    },
    applyPersonalLoanFilter() {
      console.log("this.is_serviceable", this.is_serviceable);
      this.getPincodeList();
    },
    applyFilter(item) {
      console.log("applyFilter", item);
      this.is_serviceable = item;
      this.getPincodeList();
    },
    applyBusinessLoanFilter(item) {
      console.log("applyFilter", item);
      this.is_serviceable_for_business_loan = item;
      this.getPincodeList();
    },
  },
};
</script>
