<template>
  <div>
    <v-dialog v-model="show_dialog" width="400">
      <div class="neumorphism-pressed">
        <div
          class="add-topics-title-section d-flex justify-space-between align-center py-4 px-8"
        >
          <div class="topic-title">
            <span v-if="add_topic">Add</span>
            <span v-else>Edit</span>
            Topic
          </div>
          <div>
            <button class="close-btn" @click="show_dialog = false">
              <v-icon class="close-icon">
                mdi-window-close
              </v-icon>
            </button>
          </div>
        </div>
        <div class="add-topics-table-wrapper">
          <v-form
            @submit.prevent="createTopic()"
            lazy-validation
            v-model="is_valid"
            ref="add_topic_form"
          >
            <div>
              <div class="add-topic-field-title">
                Topic Name
                <span class="add-topic-field-title-warning"
                  >(Cannot be edited once added)</span
                >
              </div>
              <!-- <div>
                <v-text-field
                  v-model="topic_name"
                  outlined
                  dense
                  :readonly="!add_topic"
                  placeholder="Enter Topic Name"
                  required
                ></v-text-field>
              </div> -->
              <div class="mb-5">
                <input
                  placeholder="Enter Topic Name"
                  :readonly="!add_topic"
                  :disabled="!add_topic"
                  v-model="topic_name"
                  class="notification-type-input neumorphism-pressed"
                  type="text"
                />
              </div>
            </div>
            <!-- <div>
              <div class="notification-type-wrapper mt-0">
                <v-switch
                  class="mt-0"
                  v-model="is_active"
                  label="Active"
                ></v-switch>
              </div>
            </div> -->
            <div>
              <div class="add-topic-field-title mb-3">Active</div>
              <div>
                <label class="label">
                  <div class="mr-4 font-weight-bold">No</div>
                  <div class="toggle">
                    <input
                      class="toggle-state"
                      type="checkbox"
                      name="check"
                      v-model="is_active"
                    />
                    <div class="indicator"></div>
                  </div>
                  <div class="ml-4 font-weight-bold">Yes</div>
                </label>
              </div>
            </div>
            <div class="d-flex align-center justify-end">
              <v-btn
                @click="createTopic()"
                elevation="0"
                class="add-btn"
                :loading="btn_loader"
                :disabled="btn_loader"
                rounded
              >
                <span v-if="add_topic">Add</span>
                <span v-else>Edit</span>
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      btn_loader: false,
      is_valid: true,
      topic_name: "",
      is_active: false,
      add_topic: false,
      topic_id: null,
    };
  },
  methods: {
    async openAddTopicDialog(item) {
      this.show_dialog = true;
      if (item) {
        console.log(item);
        this.add_topic = false;
        this.topic_name = item.topic_name;
        this.is_active = item.is_active;
        this.topic_id = item.id;
      } else {
        this.add_topic = true;
        this.topic_name = "";
        this.is_active = false;
      }
      // console.log("topic", item);
    },
    createTopic() {
      if (this.topic_name) {
        const self = this;

        self.btn_loader = true;
        // var form = new FormData();
        let form = {
          is_active: this.is_active,
        };
        if (this.add_topic) {
          form.topic_name = this.topic_name;
        }
        // form.append("is_active", this.is_active);

        const successHandler = (response) => {
          console.log("createTopic", response);
          // if (response.data.success) {
          self.$emit("update");
          self.show_dialog = false;
          self.btn_loader = false;
          // }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        const failureHandler = () => {
          self.btn_loader = false;
        };
        if (this.add_topic) {
          self.POST_request(
            self,
            self.$urls.FCM_TOPIC,
            form,
            successHandler,
            null,
            null,
            failureHandler,
            finallyHandler
          );
        } else {
          let params = "";
          let lookup_key = "";
          form.topic_id = this.topic_id;
          self.PATCH_request(
            self,
            self.$urls.FCM_TOPIC,
            lookup_key,
            params,
            form,
            successHandler,
            null,
            null,
            failureHandler,
            finallyHandler
          );
        }
      } else {
        this.showSnakeBar("error", "Please enter topic name before adding");
      }
    },
  },
};
</script>
<style scoped>
.add-topics-title-section {
  border-bottom: 1px solid #ffa70257 !important;
}
.topic-title {
  font-size: 20px;
  font-weight: 800;
  /* color: #6d7073; */
  color: #616467;
}
.close-btn {
  padding: 5px;
  border-radius: 50%;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.close-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: #ffd641;
  background-image: linear-gradient(90deg, #ffd641 0%, #ff003c 100%);

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.close-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.add-btn {
  padding: 10px 20px;
  border-radius: 20px;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}

.add-topics-table-wrapper {
  padding: 15px 30px 20px 30px;
}
.add-topic-field-title {
  color: #616467;
  font-weight: 700;
  padding: 0 0 5px 5px;
}
.add-btn {
  padding: 0 20px;
  color: #fff;
  font-weight: 800;
  background: hsla(197, 100%, 63%, 1) !important;

  background: linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  ) !important;

  background: -moz-linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  ) !important;

  background: -webkit-linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  ) !important;

  /* box-shadow: 0px 0px 12px hsla(294, 100%, 55%, 1) !important; */

  /* box-shadow: 6px 6px 12px #b5b5b5, -6px -6px 12px #ffffff; */
  box-shadow: 6px 6px 12px #7e7e7ea9, -6px -6px 6px #ffffff !important;
  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#40C9FF", endColorstr="#E81CFF", GradientType=1 ); */
}
.add-btn:hover {
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.notification-type-wrapper {
  /* width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; */
}
.notification-type-input {
  width: 100%;
  border-radius: 20px;
  padding: 10px 15px;
  border-color: #8bbbe557 !important;
}
/* body {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 300;
  background-color: #ecf0f3;
} */

.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
}

/* .label-text {
  margin-left: 16px;
  font-weight: 800;
} */

.toggle {
  isolation: isolate;
  position: relative;
  height: 30px;
  width: 60px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
    4px 4px 4px 0px #d1d9e6 inset, -4px -4px 4px 0px #ffffff inset;
}

.toggle-state {
  display: none;
}

.indicator {
  height: 100%;
  width: 200%;
  background: #edf6ff;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
}

.toggle-state:checked ~ .indicator {
  transform: translate3d(25%, 0, 0);
  background: hsla(197, 100%, 63%, 1);

  background: linear-gradient(
    45deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  background: -moz-linear-gradient(
    45deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  background: -webkit-linear-gradient(
    45deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );
}
.add-topic-field-title-warning {
  font-size: 12px;
  color: #ff003c;
}
</style>
