<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <AnalysisSection />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <ListSection />
        </v-col>
        <v-col cols="3">
          <CardSection />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  watch: {},
  components: {
    AnalysisSection: () =>
      import("@/components/notifications/AnalysisSection.vue"),
    ListSection: () => import("@/components/notifications/ListSection.vue"),
    CardSection: () => import("@/components/notifications/CardsSection.vue"),
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
    }),
  },
  mounted() {},
  methods: {
    getNotificationHistoryList() {
      const self = this;
      self.table_loading = true;
      const lookup_key = "";
      self.items_list = [];
      // self.tabs_item = [];
      let params = {};
      const successHandler = (response) => {
        // console.log("getNotificationHistoryList", response);
        self.notification_history_list = response.data.result;
        self.table_loading = false;
      };
      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_LAS_LOAN_LIST,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
    getNotificationTemplateList() {
      const self = this;
      self.table_loading = true;
      const lookup_key = "";
      self.items_list = [];
      // self.tabs_item = [];
      let params = {};
      const successHandler = (response) => {
        // console.log("getNotificationTemplateList", response);
        self.notification_template_list = response.data.result;
        self.table_loading = false;
      };
      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.FCM_NOTIFICATION,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
    getNotificationTopicList() {
      const self = this;
      self.table_loading = true;
      const lookup_key = "";
      self.items_list = [];
      // self.tabs_item = [];
      let params = {};
      const successHandler = (response) => {
        // console.log("getNotificationTopicList", response);
        self.notification_topic_list = response.data.result;
        self.table_loading = false;
      };
      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.FCM_TOPIC,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped></style>
