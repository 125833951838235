<template>
  <div>
    <!-- confirmation confirm_dialog box -->
    <v-dialog v-model="confirm_dialog.flag" width="400">
      <v-card>
        <v-card-title class="headline flex-column lighten-2" primary-title>
          <v-icon color="warning" size="5rem"> mdi-help-circle-outline </v-icon>
          {{ $lang.ARE_YOU_SURE }}?
        </v-card-title>
        <v-card-text class="flex-column text-center mb-5">
          Are you sure Video Verification is Completed
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn
            class="fw-bold"
            color="light"
            width="80"
            @click="confirm_dialog.flag = false"
          >
            No
          </v-btn>
          <v-btn
            :loading="btn_loading"
            class="fw-bold ml-5"
            color="primary"
            width="80"
            @click="confirm"
          >
            Yes
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["confirm_dialog"],
  data() {
    return {
      btn_loading: false,
    };
  },
  methods: {
    confirm() {
      const self = this;
      var form = new FormData();
      form.append("loan_id", this.confirm_dialog.loan_id);

      self.btn_loading = true;
      const successHandler = (response) => {
        console.log(response.data.success);
        if (response.data.success) {
          this.$emit("update");

          self.confirm_dialog.flag = false;
        }
      };
      const finallyHandler = () => {
        self.btn_loading = false;
      };
      this.request_POST(
        self,
        this.$urls.VIDEO_VERIFICATION_DONE,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>