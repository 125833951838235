<template>
  <div>
    <v-dialog width="500" v-model="dialog.flag">
      <v-card>
        <v-card-title class="primary_2 text-h6 white--text py-2">
          Allot NBFC
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialog.flag = false">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-4 pb-1">
          <v-select
            outlined
            dense
            hide-details
            placeholder="Select NBFC"
            :items="nbfc_list"
            v-model="selected_nbfc"
            item-text="nbfc_name"
            item-value="id"
            class="mr-2"
            clearable
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text secondary_2 px-4 mr-4 font-weight-bold my-1"
            small
          text
            :disabled="btn_loader"
            :loading="btn_loader"
            @click="allotNbfc()"
          >
            Allot
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["dialog"],
  data() {
    return {
      btn_loader: false,
      nbfc_list: [],
      selected_nbfc: "",
    };
  },
  mounted() {
    this.getNbfclist();
  },
  computed: {
    ...mapGetters({
      active_loan_id: "pre_verification/getActiveLoanId",
    }),
  },
  methods: {
    allotNbfc() {
      if (this.selected_nbfc) {
        const self = this;
        self.btn_loader = true;
        let form = new FormData();
        form.append("loan_id", self.active_loan_id);
        form.append("nbfc_id", self.selected_nbfc);
        const successHandler = (response) => {
          // if (response.data.success) {
          self.approvePreVerification();
          // }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.ALLOT_NBFC,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        if (
          !this.is_address_proof_verified &&
          this.is_bank_statement_verified &&
          this.selected_nbfc
        ) {
          this.showSnakeBar(
            "error",
            "Atleast one address proof needs to be verified"
          );
        } else {
          this.showSnakeBar("error", "Allot NBFC before Approving");
        }
      }
    },
    approvePreVerification() {
      const self = this;
      self.btn_loader = true;

      let form = new FormData();
      form.append("precheck_status", "Precheck Approved");
      form.append("loan_id", this.active_loan_id);
      form.append("rejection_reason", "");

      const successHandler = (response) => {
        // if (response.data.success) {
        self.dialog.flag = false;
        self.$emit("closeDialog");
        // }
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.POST_request(
        self,
        self.$urls.UPDATE_PRE_VERIFICATION_STATUS,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getNbfclist() {
      const self = this;
      let params = {};
      let look_up_key = "";
      const successHandler = (response) => {
        // console.log("nbfc list",response);
        self.nbfc_list = response.data.result;
      };
      const finallyHandler = () => {};

      self.GET_request(
        self,
        self.$urls.GET_NBFC_LIST,
        look_up_key,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
