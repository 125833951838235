<template>
  <div>
    <v-dialog width="600" v-model="show_dialog" scrollable>
      <v-card>
        <v-card-title
          class="primary_2 d-flex justify-space-between font-weight-bold white--text"
        >
          Allot NBFC
          <div>
            <v-btn text small fab @click="closeDialog()">
              <v-icon large color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="disbursal_details_form">
            <v-select
              v-model="alloted_nbfc"
              :loading="nbfc_list_loader"
              outlined
              dense
              :items="nbfc_list"
              item-text="nbfc_name"
              item-value="id"
              :rules="$rules.REQURIED_FILED"
              placeholder="Select NBFC"
              required
              @input="showNbfcDetails()"
            ></v-select>
            <v-card elevation="5" v-if="nbfc_details.id">
              <v-card-text>
                <v-row>
                  <v-col cols="4" class="nbfc-img-wrapper d-flex align-center">
                    <img alt="nbfc logo" :src="nbfc_details.nbfc_image" />
                  </v-col>
                  <v-col cols="8" class="font-weight-bold text-body-1">
                    <div>{{ nbfc_details.nbfc_name }}</div>
                    <div>{{ nbfc_details.nbfc_contact_email }}</div>
                    <div>{{ nbfc_details.nbfc_contact_phone }}</div>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="blue--text text-subtitle"
                            @click="openLink(nbfc_details.nbfc_link)"
                          >
                            visit site
                          </span>
                        </template>
                        <span>{{ nbfc_details.nbfc_link }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text secondary_2 px-4 mr-4 font-weight-bold my-1"
            small
            text
            :disabled="btn_loader"
            :loading="btn_loader"
            @click="allotNbfc()"
          >
            Allot
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      btn_loader: false,
      dusbursment_date_picker: false,
      disbursement_date: null,
      loan_amount: null,
      entity_type: null,
      first_name: "",
      last_name: "",
      email: null,
      mobile_number: "",
      nbfc_name: null,
      nbfc_contact_name: null,
      nbfc_contact_email: null,
      nbfc_contact_phone: null,
      entity_type_list: [
        {
          text: "Sole Proprietor",
          value: "sole_proprietor",
        },
        {
          text: "Partnership",
          value: "partnership",
        },
        {
          text: "Company",
          value: "company",
        },
        {
          text: "HUF",
          value: "HUF",
        },
        {
          text: "LLP",
          value: "LLP",
        },
      ],
      nbfc_list: [],
      nbfc_list_loader: false,
      alloted_nbfc: null,
      nbfc_details: {
        id: null,
        nbfc_contact_email: "",
        nbfc_contact_phone: "",
        nbfc_image: "",
        nbfc_link: "",
        nbfc_name: "",
      },
      basic_details: {},
    };
  },
  methods: {
    async openDisbursalDetailsDialog() {
      this.show_dialog = true;
      await this.getDisbursalDetails();
      await this.getNbfcList();
    },
    closeDialog() {
      this.show_dialog = false;
    },
    showNbfcDetails() {
      this.nbfc_list.filter((item) => {
        if (item.id == this.alloted_nbfc) {
          this.nbfc_details.id = item.id;
          this.nbfc_details.nbfc_contact_email = item.nbfc_contact_email;
          this.nbfc_details.nbfc_contact_phone = item.nbfc_contact_phone;
          this.nbfc_details.nbfc_image = item.nbfc_image;
          this.nbfc_details.nbfc_link = item.nbfc_link;
          this.nbfc_details.nbfc_name = item.nbfc_name;
        }
      });
      console.log(this.nbfc_details);
    },
    getNbfcList() {
      const self = this;
      this.nbfc_list_loader = true;
      let look_up_key = "";
      const successHandler = (response) => {
        // console.log("NBFC LIST", response);
        this.nbfc_list = response.data.result;
        this.nbfc_list_loader = false;
      };
      const finallyHandler = () => {
        this.nbfc_list_loader = false;
      };
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_NBFC_LIST,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getDisbursalDetails() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        this.basic_details = response.data.result;
        // this.disbursement_date = response.data.result.disbursement_date;
        // this.loan_amount = response.data.result.loan_amount;
        // this.entity_type = response.data.result.entity_type;
        // this.first_name = response.data.result.first_name;
        // this.last_name = response.data.result.last_name;
        // this.email = response.data.result.email;
        // this.mobile_number = response.data.result.mobile_number;
        // this.nbfc_name = response.data.result.nbfc_name;
        // this.nbfc_contact_name = response.data.result.nbfc_contact_name;
        // this.nbfc_contact_email = response.data.result.nbfc_contact_email;
        // this.nbfc_contact_phone = response.data.result.nbfc_contact_phone;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_POST_DISBURSAL_DETAILS,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    postDisbursalDetails() {
      const self = this;
      let look_up_key = self.decrypt(this.$route.params.id);

      var form = new FormData();
      form.append("disbursement_date", this.disbursement_date);
      form.append("loan_amount", this.loan_amount);
      form.append("nbfc_name", this.nbfc_name);
      form.append("nbfc_contact_name", this.nbfc_contact_name);
      form.append("nbfc_contact_email", this.nbfc_contact_email);
      form.append("nbfc_contact_phone", this.nbfc_contact_phone);

      const successHandler = () => {
        self.show_dialog = false;
        self.$emit("reload");
      };
      self.request_POST(
        self,
        self.$urls.GET_POST_DISBURSAL_DETAILS + `${look_up_key}/`,
        form,
        successHandler,
        null,
        null,
        null
      );
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    allotNbfc() {
      if (this.alloted_nbfc) {
        const self = this;
        let look_up_key = self.decrypt(this.$route.params.id);

        var form = new FormData();
        form.append("alloted_nbfc", this.alloted_nbfc);

        const successHandler = () => {
          self.show_dialog = false;
          self.$emit("reload");
        };
        self.request_POST(
          self,
          self.$urls.GET_POST_DISBURSAL_DETAILS + `${look_up_key}/`,
          form,
          successHandler,
          null,
          null,
          null
        );
      }
    },
  },
};
</script>
<style scoped>
.nbfc-img-wrapper img {
  width: 100%;
}
</style>
