<template>
  <div>
    <v-card elevation="0" class="mb-4">
      <v-card-text>
        <div>
          <v-data-table
            :items="finvu_list"
            :headers="headers"
            :loading="table_loading"
            hide-default-footer
          >
            <template v-slot:[`item.Finvu_fetched_date_from`]="{ item }">
              <div class="py-2">
                <div>
                  <span class="font-weight-bold">From:</span>
                  {{ formatDate(item.Finvu_fetched_date_from) }}
                </div>
                <div>
                  <span class="font-weight-bold">To:</span>
                  {{ formatDate(item.Finvu_fetched_date_to) }}
                </div>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div
                v-if="isDataFetched(item)"
                class="text-body-2 ml-2 font-weight-bold success--text"
              >
                <v-icon class="success--text mr-1">
                  mdi-check-circle-outline</v-icon
                >Fetched
              </div>
              <div
                v-else
                class="text-body-2 ml-2 font-weight-bold secondary_2--text"
              >
                <v-icon class="secondary_2--text mr-1">
                  mdi-alert-circle-outline</v-icon
                >Pending
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="py-2 d-flex align-center justify-end">
                <v-btn
                  outlined
                  small
                  color="red"
                  :disabled="isValueEmpty(item.Finvu_fetched_pdf_file)"
                  @click="openPfdAndCsv(item.Finvu_fetched_pdf_file)"
                >
                  PDF
                </v-btn>
                <!-- <v-btn
                  outlined
                  small
                  color="green darken-4"
                  class="mx-2"
                  @click="openXml(item.Finvu_fetched_xml_data)"
                >
                  XML
                </v-btn> -->
                <v-btn
                  outlined
                  color="orange accent-4"
                  small
                  class="mx-2"
                  :disabled="isValueEmpty(item.Finvu_fetched_csv_file)"
                  @click="openPfdAndCsv(item.Finvu_fetched_csv_file)"
                >
                  CSV
                </v-btn>
                <v-btn
                  outlined
                  small
                  class=""
                  color="deep-purple accent-4"
                  :disabled="isValueEmpty(item.Finvu_fetched_json_data)"
                  @click="openJson(item.id)"
                >
                  JSON
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      table_loading: false,
      finvu_list: [],
      headers: [
        {
          text: "ID",
          value: "id",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Fetched Date",
          value: "Finvu_fetched_date_from",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Status",
          value: "status",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Actions",
          value: "actions",
          class: "blue-grey lighten-4 font-weight-bold",
          width: "150",
        },
      ],
    };
  },
  mounted() {
    this.getFinvuList();
  },
  methods: {
    formatDate(value) {
      return dayjs(value).format("DD/MM/YYYY");
    },
    getFinvuList() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        // console.log("FINVU DATA", response);
        this.finvu_list = response.data.result;
      };
      const finallyHandler = () => {};

      self.GET_request(
        self,
        self.$urls.GET_FINVU_LIST,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    openPfdAndCsv(url) {
      window.open(url, "_blank");
    },
    openXml(xml_url) {
      //   console.log("XML", url);
      //   xml.responseXML("GET", url, false);
      let blob = new Blob([xml_url], {
        type: "text/xml",
      });
      let url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url);
    },
    openJson(id) {
      // console.log("JSON", id);
      // this.$router.push({
      //   name: "compelete-finvu-details",
      //   params: {
      //     finvu_doc_id: this.encript(id),
      //   },
      // });

      let route = this.$router.resolve({
        name: "compelete-finvu-details",
        params: {
          finvu_doc_id: this.encript(id),
        },
      });
      window.open(route.href, "_blank");
    },
    getOtherDocument() {
      const self = this;
      let params = {
        loan_id: self.active_loan_id,
        customer_id: self.decrypt(this.$route.params.id),
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          self.other_documents = response.data.other_doc_list;
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_GET_OTHER_DOCUMENT,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      console.log("image preview", url);
      let image_url = url;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_name),
        },
      });
      window.open(route.href, "_blank");
    },
    isValueEmpty(value) {
      if (value == null || value == "") return true;
      else return false;
    },
    isDataFetched(value) {
      if (
        value.Finvu_fetched_pdf_file != null ||
        value.Finvu_fetched_csv_file != null ||
        value.Finvu_fetched_json_data != null
      ) {
        return true;
      } else return false;
    },
  },
};
</script>
<style scoped>
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.253);
}
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
</style>
