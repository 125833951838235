import messages from './messages.js'

const rules = {
    NAME: [v => !!v || messages.NAME_ERROR],
    REQURIED_FILED: [v => !!v || messages.FIELD_CANNOT_BE_EMPTY],
    MOBILE: [
        v => !!v || messages.MOBILE_NUMBER_ERROR,
        v => {
            if (!isNaN(parseFloat(v)) && v >= 5000000000 && v <= 9999999999) return true;
            return messages.INVALID_MOBILE_NUMBER_ERROR;
        }
    ],
    OTP: [
        v => !!v || messages.INVALID_OTP,
        v => {
            if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) return true;
            if (v.length == 5) return true;
            return messages.INVALID_OTP;
        }
    ],
    EMAIL: [
        v => !!v || messages.EMAIL_ERROR,
        v => /.+@.+\..+/.test(v) || messages.EMAIL_MUST_BE_VALID,
    ],
    AMOUNT: [
        v => !!v || "Amount is Requried",
        v => /^[0-9]+$/.test(v) || "Invalid Amount",
    ],
}


export default rules