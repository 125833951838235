<template>
  <v-skeleton-loader
      class="mx-auto"
      :type="loading_type"
    ></v-skeleton-loader>
</template>
<script>

export default {
  props: {
    loading_type: {
      type: String,
      default: "card"
    },
  },
}
</script>
