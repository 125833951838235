<template>
  <div>
    <v-dialog
      scrollable
      v-model="show_dialog"
      width="600"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h5 white--text primary_2 d-flex justify-space-between align-center"
        >
          <div>Loan Track Data</div>

          <div>
            <v-btn @click="show_dialog = false" icon
              ><v-icon color="white">mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="py-5">
          <v-data-table
            hide-default-footer
            :items-per-page="page_length"
            :headers="track_table_headers"
            :items="loan_track_data"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["basic_detail"],
  data() {
    return {
      show_dialog: false,
      loading: false,
      loan_track_data: [],
      loan_id: null,
      page_length: -1,
      track_table_headers: [
        {
          text: "Loan Status",
          align: "center",
          filterable: false,
          value: "action",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Date & time",
          align: "center",
          filterable: false,
          value: "status_created_at",
          class: "blue-grey lighten-4 font-weight-bold",
        },
      ],
    };
  },
  methods: {
    async openLoanTrackDataDialog(item) {
      //   console.log("openLoanTrackDataDialog", item);
      this.show_dialog = true;
      this.loan_id = item.loan_id;
      this.getLoanTrackData();
    },
    getLoanTrackData() {
      const self = this;

      const successHandler = (response) => {
        self.loan_track_data = response.data.result;
        // console.log("getLoanTrackData", response);
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_LOAN_TRACK_DATA,
        self.loan_id,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
