<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}

.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.account-details-table-content {
  font-size: 12px;
  color: #000000;
  font-weight: 600;
}

.field-title-blue {
  font-weight: 400;
  font-size: 12px;
  color: rgb(14, 153, 192);
}

.account-details-table-title {
  font-weight: 600;
  font-size: 12px;
  color: rgb(14, 153, 192);
}
.caps-details-table-title {
  font-weight: 600;
  font-size: 12px;
  color: rgb(14, 153, 192);
}

.field-content-blue {
  font-size: 14px;
  font-weight: 700;
  color: rgb(14, 153, 192);
}

.report-summary-table,
.credit-account-info-table,
.application-info-table,
.consumer-personal-details-table,
.account-details-table,
.phone-details-table,
.holder-id-details-table {
  width: 100%;
  border-collapse: collapse;
}
.report-summary-table-cell {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.account-details-table-cell {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.application-info-table-cell {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.credit-account-info-table > tr > th {
  font-size: 13px;
  background-color: #cfd8dc9f;
}
.credit-account-info-table > tr > td {
  font-size: 13px;
  color: #000000;
  padding: 5px 0;
  /* font */
}
.credit-account-info-table > tr:hover {
  background-color: #e2e2e24a;
}
.display-flex {
  display: flex;
}
.title-banner {
  color: #000000;
  background-color: rgb(208, 208, 208);
  padding: 5px;
  font-weight: 800;
}
.red-text {
  color: #e50000;
  font-size: 10px;
}
.logo {
  width: 150px;
}
.border-around {
  border: 1px solid rgba(144, 144, 144, 0.211);
}
.dummy-border {
  border: 1px solid #000;
  margin-bottom: 20px;
}
.wrapper-border {
  /* border: 1px solid #000; */
  border: 1px solid #5f5f5f88;
  border-radius: 5px;
  margin-bottom: 10px;
  /* margin-left: 10px; */
  padding: 10px;
  padding-right: 0px;
}
.phone-details-table-border {
  border: 1px solid #5f5f5f71;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px;
}
.holder-id-details-table-border {
  border: 1px solid #5f5f5f6d;
  border-radius: 5px;
  margin-bottom: 20px;
  /* padding: 5px; */
}
.phone-details-table-content {
  text-align: center !important;
  font-size: 12px;
}
.bottom-padding {
  padding-bottom: 0px !important;
}
.credit-enquiry-section {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #9b9b9b71;
}
.credit-enquiry-section-table {
  padding: 0 10px;
}
.cibil-score {
  font-size: 60px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 60px;
  color: #333;
}
.account-info-section {
  margin-bottom: 20px;
  border-bottom: 1px solid #9b9b9b71;
}
/* @media print {
  #print-section {
    font-size: 12px !important;
  }
} */
.left-padding {
  padding-left: 0px;
}
.loading-text {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.dot-1 {
  position: relative;
  animation: dot 500ms linear alternate-reverse infinite;
}
.dot-2 {
  position: relative;
  animation: dot 500ms 150ms linear alternate-reverse infinite;
}
.dot-3 {
  position: relative;
  animation: dot 500ms 250ms linear alternate-reverse infinite;
}
.dot-4 {
  position: relative;
  animation: dot 500ms 300ms linear alternate-reverse infinite;
}
@keyframes dot {
  from {
    opacity: 1;
    /* top: 0px; */
  }
  to {
    opacity: 0;
    /* top: -15px; */
  }
}
.text-align-center {
  text-align: center;
}
</style>
<template>
  <div>
    <v-card>
      <v-card-text v-if="page_loader" class="loading-text">
        Loading<span class="dot-1">●</span>
        <span class="dot-2">●</span>
        <span class="dot-3">●</span>
        <span class="dot-4">●</span>
      </v-card-text>
      <v-card-text v-else id="print-section">
        <v-container fluid v-if="cibil.INProfileResponse">
          <!-- CURRENT APPLICATION INFORMATION -->
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="6"
                  class="px-3 pt-6 d-flex align-center justify-space-between"
                >
                  <div>
                    <img
                      class="logo"
                      alt="experian logo"
                      src="../../assets/images/experian_logo.svg"
                    />
                    <!-- src="../../../assets/images/experian_logo.svg" -->
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="px-3 pt-6 d-flex align-center justify-space-between"
                >
                  <div class="text-right">
                    <div class="text-h5 primary--text">
                      Experian Credit Report
                    </div>
                    <div>
                      Experian Report Number:
                      <span class="field-content">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CreditProfileHeader
                              .ReportNumber
                          )
                        }}
                      </span>
                    </div>
                    <div>
                      Report Date:
                      <span class="field-content">
                        {{
                          checkForDateValue(
                            cibil.INProfileResponse.CreditProfileHeader
                              .ReportDate
                          )
                        }}
                      </span>
                    </div>
                  </div></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" class="px-0 pb-0">
                  <div
                    class="pt-2 pb-2 px-3 text-body-2 font-weight-bold title-banner d-flex align-text"
                  >
                    <v-icon color="success" size="20" class="mr-3"
                      >mdi-check-circle</v-icon
                    >
                    CURRENT APPLICATION INFORMATION
                  </div>
                  <div class="red-text pl-4">
                    <!-- These are the details you give us when you apply for your
                    Experian Credit Report. -->
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-9">
                  <table class="application-info-table">
                    <tr>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">
                            Name
                          </div>
                          <div
                            class="field-content"
                            v-if="
                              cibil.INProfileResponse.Current_Application
                                .Current_Application_Details
                                .Current_Applicant_Details
                            "
                          >
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.First_Name
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.Middle_Name1
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.Middle_Name2
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.Middle_Name3
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.Last_Name
                              )
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Address</div>
                          <div class="field-content">
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsFlatNoPlotNoHouseNo
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsBldgNoSocietyName
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsRoadNoNameAreaLocality
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsCity
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsLandmark
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsState
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsPINCode
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse
                                  .Current_Applicant_Address_DetailsCountry_Code
                              )
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Date of Birth</div>
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.Current_Application
                                .Current_Application_Details
                                .Current_Applicant_Details
                            "
                          >
                            {{
                              checkForDateValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details
                                  .Date_Of_Birth_Applicant
                              )
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">PAN</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.IncomeTaxPan
                              )
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Telephone</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details
                                  .Telephone_Number_Applicant_1st
                              )
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Gender</div>
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.Current_Application
                                .Current_Application_Details
                                .Current_Applicant_Details
                            "
                          >
                            {{
                              checkForGenderValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.Gender_Code
                              )
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Passport Number</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.Passport_number
                              )
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Mobile Number</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.MobilePhoneNumber
                              )
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Email</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details.EMailId
                              )
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Voter ID</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details
                                  .Voter_s_Identity_Card
                              )
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Driving License</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details
                                  .Driver_License_Number
                              )
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Aadhaar Number</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details
                              )
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="application-info-table-cell">
                          <div class="field-title">Passport Number</div>
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.Current_Application
                                  .Current_Application_Details
                                  .Current_Applicant_Details
                              )
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- EXPERIAN CREDIT SCORE -->
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="px-0">
                  <div
                    class="pt-2 pb-2 px-3 text-body-2 font-weight-bold title-banner d-flex align-text"
                  >
                    <v-icon color="success" size="20" class="mr-3"
                      >mdi-check-circle</v-icon
                    >
                    EXPERIAN CREDIT SCORE
                  </div>
                  <div class="red-text pl-4">
                    <!-- These are the details you give us when you apply for your
                    Experian Credit Report. -->
                  </div>
                </v-col>
              </v-row>
              <v-row class="pb-6">
                <v-col cols="4" class="cibil-score">
                  <div>
                    {{
                      checkForValue(cibil.INProfileResponse.SCORE.BureauScore)
                    }}
                  </div>
                </v-col>
                <v-col cols="8">
                  <div class="border-around px-4 py-2">
                    <table style="padding: 5px;">
                      <tr>
                        <td style="text-decoration: underline;">
                          Score Factors
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          1. <span style="font-weight: 700;">Recency:</span>
                        </td>
                        <td style="padding-left: 20px;">
                          Recent Credit Account Defaults
                        </td>
                      </tr>
                      <tr>
                        <td>
                          2.
                          <span style="font-weight: 700;">Leverage:</span>
                        </td>
                        <td style="padding-left: 20px;">
                          Credit Accounts with on-time re-payment history
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3.
                          <span style="font-weight: 700;">Coverage:</span>
                        </td>
                        <td style="padding-left: 20px;">
                          Non-delinquent and delinquent Credit Accounts
                        </td>
                      </tr>
                      <tr>
                        <td>
                          4.
                          <span style="font-weight: 700;"
                            >Delinquency Status:</span
                          >
                        </td>
                        <td style="padding-left: 20px;">
                          Defaults on Credit Accounts (current & recent periodic
                          intervals)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          5.
                          <span style="font-weight: 700;"
                            >Credit Applications:</span
                          >
                        </td>
                        <td style="padding-left: 20px;">
                          Credit Account Applications over last 30 days
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Report Summary -->
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="px-0">
                  <div
                    class="pt-2 pb-2 px-3 text-body-2 font-weight-bold title-banner d-flex align-text"
                  >
                    <v-icon color="success" size="20" class="mr-3"
                      >mdi-check-circle</v-icon
                    >
                    REPORT SUMMARY
                  </div>
                  <div class="red-text pl-4">
                    <!-- These are the details you give us when you apply for your
                    Experian Credit Report. -->
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-10">
                  <div class="px-3">
                    <table class="report-summary-table">
                      <tr class="font-weight-bold">
                        <td>Credit Account Summary</td>
                        <td>Current Balance Amount Summary</td>
                        <td>Credit Enquiry Summary</td>
                        <td>Non-Credit Enquiry Summary</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Total number of Accounts
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAIS_Account
                                    .CAIS_Summary.Credit_Account
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Total Current Bal. amt
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAIS_Account
                                    .CAIS_Summary.Total_Outstanding_Balance
                                    .Outstanding_Balance_All
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 7 days credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAPS.CAPS_Summary
                                    .CAPSLast7Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 7 days non-credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.NonCreditCAPS
                                    .NonCreditCAPS_Summary
                                    .NonCreditCAPSLast7Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Active Accounts
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAIS_Account
                                    .CAIS_Summary.CreditAccountActive
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              SF/WD/WO/Settled amt
                            </div>
                            <div class="field-content ">
                              NA
                              <!-- {{checkForValue(cibil.)}} -->
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 30 days credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAPS.CAPS_Summary
                                    .CAPSLast30Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 30 days non-credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.NonCreditCAPS
                                    .NonCreditCAPS_Summary
                                    .NonCreditCAPSLast30Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Closed Accounts
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAIS_Account
                                    .CAIS_Summary.CreditAccountClosed
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Secured Accounts amt
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAIS_Account
                                    .CAIS_Summary.Total_Outstanding_Balance
                                    .Outstanding_Balance_Secured
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 90 days credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAPS.CAPS_Summary
                                    .CAPSLast90Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 90 days non-credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.NonCreditCAPS
                                    .NonCreditCAPS_Summary
                                    .NonCreditCAPSLast90Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              SF/WD/WO/Settled
                            </div>
                            <div class="field-content ">
                              <!-- {{checkForValue(cibil.)}} -->
                              NA
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Unsecured Accounts amt
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAIS_Account
                                    .CAIS_Summary.Total_Outstanding_Balance
                                    .Outstanding_Balance_UnSecured
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 180 days credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.CAPS.CAPS_Summary
                                    .CAPSLast180Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="report-summary-table-cell">
                            <div class="field-title">
                              Last 180 days non-credit enquiries
                            </div>
                            <div class="field-content ">
                              {{
                                checkForValue(
                                  cibil.INProfileResponse.NonCreditCAPS
                                    .NonCreditCAPS_Summary
                                    .NonCreditCAPSLast180Days
                                )
                              }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Summary: Credit Account Information -->
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="px-0">
                  <div
                    class="pt-2 pb-2 px-3 text-body-2 font-weight-bold title-banner d-flex align-text"
                  >
                    <v-icon color="success" size="20" class="mr-3"
                      >mdi-check-circle</v-icon
                    >
                    SUMMARY: CREDIT ACCOUNT INFORMATION
                  </div>
                  <div class="red-text pl-4">
                    <!-- These are the details you give us when you apply for your
                    Experian Credit Report. -->
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-10">
                  <div
                    class="px-3"
                    v-if="
                      cibil.INProfileResponse.CAIS_Account
                        .CAIS_Account_DETAILS[0]
                    "
                  >
                    <table class="credit-account-info-table">
                      <tr>
                        <th>S. No.</th>
                        <th>Ledger</th>
                        <th>Account Type</th>
                        <th>Account No.</th>
                        <th>Ownership</th>
                        <th>Date Reported</th>
                        <th>Account Status</th>
                        <th>Date Opened</th>
                        <th>
                          Santioned Amt/<br />
                          Highest Credit
                        </th>
                        <th>Current Balance</th>
                        <th>Amount Overdue</th>
                      </tr>
                      <tr
                        v-for="(item, index) in cibil.INProfileResponse
                          .CAIS_Account.CAIS_Account_DETAILS"
                        :key="index"
                      >
                        <!-- <td class="text-caption">Acct {{index}}</td> -->
                        <td class="px-4">{{ index + 1 }}</td>
                        <td class="text-align-center">
                          {{ checkForValue(item.Subscriber_Name) }}
                        </td>
                        <td class="text-align-center">
                          {{ checkForAccountTypeValue(item.Account_Type) }}
                        </td>
                        <td class="text-align-center">
                          {{ checkForValue(item.Account_Number) }}
                        </td>
                        <td class="text-align-center">
                          <!-- {{checkForValue(item.Subscriber_Name)}} -->
                          NA
                        </td>
                        <td class="text-align-center">
                          {{ checkForDateValue(item.Date_Reported) }}
                        </td>
                        <td class="text-align-center">
                          {{ checkForAccountStatusValue(item.Account_Status) }}
                        </td>
                        <td class="text-align-center">
                          {{ checkForDateValue(item.Open_Date) }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForValue(
                              item.Highest_Credit_or_Original_Loan_Amount
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{ checkForValue(item.Current_Balance) }}
                        </td>
                        <td class="text-align-center">
                          {{ checkForValue(item.Amount_Past_Due) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div v-else>
                    <table class="credit-account-info-table">
                      <tr>
                        <th></th>
                        <th>Ledger</th>
                        <th>Account Type</th>
                        <th>Account No.</th>
                        <th>Ownership</th>
                        <th>Date Reported</th>
                        <th>Account Status</th>
                        <th>Date Opened</th>
                        <th>
                          Santioned Amt/<br />
                          Highest Credit
                        </th>
                        <th>Current Balance</th>
                        <th>Amount Overdue</th>
                      </tr>
                      <tr>
                        <!-- <td class="text-caption">Acct {{index}}</td> -->
                        <td class="px-4">1</td>
                        <td class="text-align-center">
                          {{
                            checkForValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Subscriber_Name
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForAccountTypeValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Account_Type
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Account_Number
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          <!-- {{checkForValue(cibil.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.Subscriber_Name)}} -->
                          NA
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForDateValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Date_Reported
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForAccountStatusValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Account_Status
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForDateValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Open_Date
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS
                                .Highest_Credit_or_Original_Loan_Amount
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Current_Balance
                            )
                          }}
                        </td>
                        <td class="text-align-center">
                          {{
                            checkForValue(
                              cibil.INProfileResponse.CAIS_Account
                                .CAIS_Account_DETAILS.Amount_Past_Due
                            )
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- CREDIT ACCOUNT INFORMATION DETAILS -->
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="px-0">
                  <div
                    class="pt-2 pb-2 px-3 text-body-2 font-weight-bold title-banner d-flex align-text"
                  >
                    <v-icon color="success" size="20" class="mr-3"
                      >mdi-check-circle</v-icon
                    >
                    CREDIT ACCOUNT INFORMATION DETAILS
                  </div>
                  <div class="red-text pl-4">
                    <!-- These are the details you give us when you apply for your
                    Experian Credit Report. -->
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div
                    class="px-3"
                    v-if="
                      cibil.INProfileResponse.CAIS_Account
                        .CAIS_Account_DETAILS[0]
                    "
                  >
                    <div
                      v-for="(item, index) in cibil.INProfileResponse
                        .CAIS_Account.CAIS_Account_DETAILS"
                      :key="index"
                      class="account-info-section"
                    >
                      <v-row>
                        <v-col cols="4">
                          <div class="field-content-blue">
                            {{ checkForAccountTypeValue(item.Account_Type) }}
                          </div>
                        </v-col>
                        <v-col cols="4" class="text-center">
                          <div class="field-content-blue text-center">
                            {{ checkForValue(item.Subscriber_Name) }}
                          </div>
                        </v-col>
                        <v-col cols="4" class="d-flex justify-end">
                          <div class="field-content-blue text-right">
                            <v-icon color="success" size="20" class="mr-3"
                              >mdi-check-circle</v-icon
                            >
                            Acct {{ index + 1 }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="1">
                          <div class="field-content-blue text-center mr-3">
                            Address
                          </div>
                        </v-col>
                        <v-col cols="11">
                          <div class="">
                            {{
                              checkForValueEmpty(
                                item.CAIS_Holder_Address_Details
                                  .First_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                item.CAIS_Holder_Address_Details
                                  .Second_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                item.CAIS_Holder_Address_Details
                                  .Third_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                item.CAIS_Holder_Address_Details
                                  .Fifth_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                item.CAIS_Holder_Address_Details
                                  .City_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                item.CAIS_Holder_Address_Details
                                  .ZIP_Postal_Code_non_normalized
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="field-content-blue text-center">
                            Credit Account Details
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <table class="account-details-table">
                            <tr>
                              <th>
                                <div class="field-content-blue text-left">
                                  Amount Terms
                                </div>
                              </th>
                              <th>
                                <div class="field-content-blue text-left">
                                  Amount Description
                                </div>
                              </th>
                              <th>
                                <div class="field-content-blue text-left">
                                  Account Details
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Account Number
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForValue(item.Account_Number) }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date Reported
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForDateValue(item.Date_Reported) }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Credit Limit Amt
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(item.Credit_Limit_Amount)
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date Opened
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForDateValue(item.Open_Date) }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Loan Type
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForAccountTypeValue(
                                        item.Account_Type
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date EMI
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 3 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date Closed
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForDateValue(item.Date_Closed) }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Account Status
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForAccountStatusValue(
                                        item.Account_Status
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Repayment Tenure
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForValue(item.Repayment_Tenure) }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 4 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Ownership
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Sanctioned Amt
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Total Write-off Amt
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(item.Written_Off_Amt_Total)
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 5 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Rate of Interest
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForValue(item.Rate_of_Interest) }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Current Balance
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForValue(item.Current_Balance) }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Principal Write-off
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        item.Written_Off_Amt_Principal
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 6 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Value of Collateral
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(item.Value_of_Collateral)
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Amount Overdue
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Settelement Amt
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForValue(item.Settlement_Amount) }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 7 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Type of Collateral
                                  </div>
                                  <div class="field-content ">
                                    {{ checkForValue(item.Type_of_Collateral) }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Last Payment Date
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForDateValue(
                                        item.Date_of_Last_Payment
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td></td>
                            </tr>

                            <!-- 8 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    SuitFiled Willful Default WrittenOff Status
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        item.SuitFiledWillfulDefaultWrittenOffStatus
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    SuitFiled Willful Default
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        item.SuitFiled_WilfulDefault
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Written off Settled Status
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        item.Written_off_Settled_Status
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="field-content-blue text-center">
                            Consumer Personal Details on the Credit Account
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-row>
                            <v-col
                              cols="4"
                              v-if="item.CAIS_Holder_Details.Date_of_birth"
                            >
                              <div class="wrapper-border">
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date of Birth
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForDateValue(
                                        item.CAIS_Holder_Details.Date_of_birth
                                      )
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Gender
                                  </div>
                                  <div
                                    class="account-details-table-content "
                                    v-if="item.CAIS_Holder_Details"
                                  >
                                    {{
                                      checkForGenderValue(
                                        item.CAIS_Holder_Details.Gender_Code
                                      )
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Occupation
                                  </div>
                                  <div class="account-details-table-content ">
                                    NA
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Email Address
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForValue(
                                        item.CAIS_Holder_Details.EMailId
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="4" v-else class="">
                              <div
                                class="wrapper-border"
                                v-for="(sub_item,
                                index) in item.CAIS_Holder_Details"
                                :key="index"
                              >
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date of Birth
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForDateValue(sub_item.Date_of_birth)
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Gender
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForGenderValue(sub_item.Gender_Code)
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Occupation
                                  </div>
                                  <div class="account-details-table-content ">
                                    NA
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Email Address
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{ checkForValue(sub_item.EMailId) }}
                                  </div>
                                </div>
                              </div>
                            </v-col>
                            <template v-if="item.CAIS_Holder_Phone_Details">
                              <v-col cols="4" class="left-padding">
                                <div
                                  v-for="(sub_item,
                                  index) in item.CAIS_Holder_Phone_Details"
                                  :key="index"
                                  class="phone-details-table-border"
                                >
                                  <table class="phone-details-table">
                                    <tr>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Type
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Number
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Extension
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Telephone_Type
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Mobile_Telephone_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Telephone_Extension
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr></tr>
                                  </table>
                                </div>
                              </v-col>
                            </template>
                            <template v-else>
                              <v-col cols="4" class="left-padding">
                                <div class="phone-details-table-border">
                                  <table class="phone-details-table">
                                    <tr>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Type
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Number
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Extension
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_Phone_Details
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content  phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_Phone_Details
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_Phone_Details
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </v-col>
                            </template>
                            <template v-if="item.CAIS_Holder_ID_Details">
                              <v-col cols="4" class="left-padding">
                                <div
                                  v-for="(sub_item,
                                  index) in item.CAIS_Holder_ID_Details"
                                  :key="index"
                                >
                                  <div
                                    v-if="checkIdDetails(sub_item)"
                                    class="holder-id-details-table-border"
                                  >
                                    <table class="holder-id-details-table">
                                      <tr>
                                        <th>
                                          <div class="field-content-blue ">
                                            ID Type
                                          </div>
                                        </th>
                                        <th>
                                          <div class="field-content-blue ">
                                            ID Number
                                          </div>
                                        </th>
                                        <th>
                                          <div class="field-content-blue ">
                                            Date
                                            <!-- <br /> -->
                                            of
                                            <!-- <br /> -->
                                            issue
                                          </div>
                                        </th>
                                        <th>
                                          <div class="field-content-blue ">
                                            Date
                                            <!-- <br /> -->
                                            of
                                            <!-- <br /> -->
                                            expiry
                                          </div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            PAN
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForValue(
                                                sub_item.Income_TAX_PAN
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.PAN_Issue_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.PAN_Expiration_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            Passport
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForValue(
                                                sub_item.Passport_Number
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Passport_Issue_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Passport_Expiration_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            Voter ID
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForValue(
                                                sub_item.Voter_ID_Number
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Voter_ID_Issue_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Voter_ID_Expiration_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            Aadhaar/UID
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForValue(
                                                sub_item.Universal_ID_Number
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Universal_ID_Issue_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Universal_ID_Expiration_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            Driving License
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForValue(
                                                sub_item.Driver_License_Number
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Driver_License_Issue_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Driver_License_Expiration_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            Ration Card
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForValue(
                                                sub_item.Ration_Card_Number
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Ration_Card_Issue_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="field-content phone-details-table-content"
                                          >
                                            {{
                                              checkForDateValue(
                                                sub_item.Ration_Card_Expiration_Date
                                              )
                                            }}
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </v-col>
                            </template>
                            <template v-else>
                              <v-col cols="4" class="left-padding">
                                <div class="holder-id-details-table-border">
                                  <table class="holder-id-details-table">
                                    <tr>
                                      <th>
                                        <div class="field-content-blue ">
                                          ID Type
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          ID Number
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Date
                                          <!-- <br /> -->
                                          of
                                          <!-- <br /> -->
                                          issue
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Date
                                          <!-- <br /> -->
                                          of
                                          <!-- <br /> -->
                                          expiry
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          PAN
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Income_TAX_PAN
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .PAN_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .PAN_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Passport
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Passport_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Passport_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Passport_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Voter ID
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Voter_ID_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Voter_ID_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Voter_ID_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Aadhaar/UID
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Universal_ID_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Universal_ID_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Universal_ID_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Driving License
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Driver_License_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Driver_License_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Driver_License_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Ration Card
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Ration_Card_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Ration_Card_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Ration_Card_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </v-col>
                            </template>
                          </v-row>
                        </v-col>
                      </v-row>
                      <!-- <v-row v-else>
                        <v-col cols="12">
                          object
                        </v-col>
                      </v-row> -->
                    </div>
                  </div>
                  <div class="px-3" v-else>
                    <div class="account-info-section">
                      <v-row>
                        <v-col cols="4">
                          <div class="field-content-blue">
                            {{
                              checkForAccountTypeValue(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS.Account_Type
                              )
                            }}
                          </div>
                        </v-col>
                        <v-col cols="4" class="text-center">
                          <div class="field-content-blue text-center">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS.Subscriber_Name
                              )
                            }}
                          </div>
                        </v-col>
                        <v-col cols="4" class="d-flex justify-end">
                          <div class="field-content-blue text-right">
                            <v-icon color="success" size="20" class="mr-3"
                              >mdi-check-circle</v-icon
                            >
                            Acct {{ index + 1 }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="1">
                          <div class="field-content-blue text-center mr-3">
                            Address
                          </div>
                        </v-col>
                        <v-col cols="11">
                          <div class="">
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS
                                  .CAIS_Holder_Address_Details
                                  .First_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS
                                  .CAIS_Holder_Address_Details
                                  .Second_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS
                                  .CAIS_Holder_Address_Details
                                  .Third_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS
                                  .CAIS_Holder_Address_Details
                                  .Fifth_Line_Of_Address_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS
                                  .CAIS_Holder_Address_Details
                                  .City_non_normalized
                              )
                            }}
                            {{
                              checkForValueEmpty(
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS
                                  .CAIS_Holder_Address_Details
                                  .ZIP_Postal_Code_non_normalized
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="field-content-blue text-center">
                            Credit Account Details
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <table class="account-details-table">
                            <tr>
                              <th>
                                <div class="field-content-blue text-left">
                                  Amount Terms
                                </div>
                              </th>
                              <th>
                                <div class="field-content-blue text-left">
                                  Amount Description
                                </div>
                              </th>
                              <th>
                                <div class="field-content-blue text-left">
                                  Account Details
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Account Number
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Account_Number
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date Reported
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForDateValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Date_Reported
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Credit Limit Amt
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Credit_Limit_Amount
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date Opened
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForDateValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Open_Date
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Loan Type
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForAccountTypeValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Account_Type
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date EMI
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 3 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date Closed
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForDateValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Date_Closed
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Account Status
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForAccountStatusValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Account_Status
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Repayment Tenure
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Repayment_Tenure
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 4 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Ownership
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Sanctioned Amt
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Total Write-off Amt
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Written_Off_Amt_Total
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 5 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Rate of Interest
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Rate_of_Interest
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Current Balance
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS.Current_Balance
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Principal Write-off
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Written_Off_Amt_Principal
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 6 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Value of Collateral
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Value_of_Collateral
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Amount Overdue
                                  </div>
                                  <div class="field-content ">
                                    NA
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Settelement Amt
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Settlement_Amount
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <!-- 7 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Type of Collateral
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Type_of_Collateral
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Last Payment Date
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForDateValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Date_of_Last_Payment
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td></td>
                            </tr>

                            <!-- 8 -->
                            <tr>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    SuitFiled Willful Default WrittenOff Status
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .SuitFiledWillfulDefaultWrittenOffStatus
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    SuitFiled Willful Default
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .SuitFiled_WilfulDefault
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Written off Settled Status
                                  </div>
                                  <div class="field-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .Written_off_Settled_Status
                                      )
                                    }}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="field-content-blue text-center">
                            Consumer Personal Details on the Credit Account
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-row>
                            <v-col
                              cols="4"
                              v-if="
                                cibil.INProfileResponse.CAIS_Account
                                  .CAIS_Account_DETAILS.CAIS_Holder_Details
                                  .Date_of_birth
                              "
                            >
                              <div class="wrapper-border">
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date of Birth
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForDateValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .CAIS_Holder_Details.Date_of_birth
                                      )
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Gender
                                  </div>
                                  <div
                                    class="account-details-table-content "
                                    v-if="
                                      cibil.INProfileResponse.CAIS_Account
                                        .CAIS_Account_DETAILS
                                        .CAIS_Holder_Details
                                    "
                                  >
                                    {{
                                      checkForGenderValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .CAIS_Holder_Details.Gender_Code
                                      )
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Occupation
                                  </div>
                                  <div class="account-details-table-content ">
                                    NA
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Email Address
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForValue(
                                        cibil.INProfileResponse.CAIS_Account
                                          .CAIS_Account_DETAILS
                                          .CAIS_Holder_Details.EMailId
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="4" v-else class="">
                              <div
                                class="wrapper-border"
                                v-for="(sub_item,
                                index) in item.CAIS_Holder_Details"
                                :key="index"
                              >
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Date of Birth
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForDateValue(sub_item.Date_of_birth)
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Gender
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{
                                      checkForGenderValue(sub_item.Gender_Code)
                                    }}
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Occupation
                                  </div>
                                  <div class="account-details-table-content ">
                                    NA
                                  </div>
                                </div>
                                <div class="account-details-table-cell">
                                  <div class="account-details-table-title">
                                    Email Address
                                  </div>
                                  <div class="account-details-table-content ">
                                    {{ checkForValue(sub_item.EMailId) }}
                                  </div>
                                </div>
                              </div>
                            </v-col>
                            <template v-if="item.CAIS_Holder_Phone_Details">
                              <v-col cols="4" class="left-padding">
                                <div
                                  v-for="(sub_item,
                                  index) in item.CAIS_Holder_Phone_Details"
                                  :key="index"
                                  class="phone-details-table-border"
                                >
                                  <table class="phone-details-table">
                                    <tr>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Type
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Number
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Extension
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Telephone_Type
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Mobile_Telephone_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Telephone_Extension
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr></tr>
                                  </table>
                                </div>
                              </v-col>
                            </template>
                            <template v-else>
                              <v-col cols="4" class="left-padding">
                                <div class="phone-details-table-border">
                                  <table class="phone-details-table">
                                    <tr>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Type
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Phone Number
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Extension
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_Phone_Details
                                                .Telephone_Type
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content  phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_Phone_Details
                                                .Mobile_Telephone_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_Phone_Details
                                                .Telephone_Extension
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </v-col>
                            </template>
                            <template v-if="item.CAIS_Holder_ID_Details">
                              <v-col cols="4" class="left-padding">
                                <div
                                  v-for="(sub_item,
                                  index) in item.CAIS_Holder_ID_Details"
                                  :key="index"
                                  class="holder-id-details-table-border"
                                >
                                  <table class="holder-id-details-table">
                                    <tr>
                                      <th>
                                        <div class="field-content-blue ">
                                          ID Type
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          ID Number
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Date
                                          <!-- <br /> -->
                                          of
                                          <!-- <br /> -->
                                          issue
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Date
                                          <!-- <br /> -->
                                          of
                                          <!-- <br /> -->
                                          expiry
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          PAN
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Income_TAX_PAN
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.PAN_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.PAN_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Passport
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Passport_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Passport_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Passport_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Voter ID
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Voter_ID_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Voter_ID_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Voter_ID_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Aadhaar/UID
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Universal_ID_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Universal_ID_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Universal_ID_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Driving License
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Driver_License_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Driver_License_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Driver_License_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Ration Card
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              sub_item.Ration_Card_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Ration_Card_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              sub_item.Ration_Card_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </v-col>
                            </template>
                            <template v-else>
                              <v-col cols="4" class="left-padding">
                                <div class="holder-id-details-table-border">
                                  <table class="holder-id-details-table">
                                    <tr>
                                      <th>
                                        <div class="field-content-blue ">
                                          ID Type
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          ID Number
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Date
                                          <!-- <br /> -->
                                          of
                                          <!-- <br /> -->
                                          issue
                                        </div>
                                      </th>
                                      <th>
                                        <div class="field-content-blue ">
                                          Date
                                          <!-- <br /> -->
                                          of
                                          <!-- <br /> -->
                                          expiry
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          PAN
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Income_TAX_PAN
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .PAN_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .PAN_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Passport
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Passport_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Passport_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Passport_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Voter ID
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Voter_ID_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Voter_ID_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Voter_ID_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Aadhaar/UID
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Universal_ID_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Universal_ID_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Universal_ID_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Driving License
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Driver_License_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Driver_License_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Driver_License_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          Ration Card
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForValue(
                                              item.CAIS_Holder_ID_Details
                                                .Ration_Card_Number
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Ration_Card_Issue_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="field-content phone-details-table-content"
                                        >
                                          {{
                                            checkForDateValue(
                                              item.CAIS_Holder_ID_Details
                                                .Ration_Card_Expiration_Date
                                            )
                                          }}
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </v-col>
                            </template>
                          </v-row>
                        </v-col>
                      </v-row>
                      <!-- <v-row v-else>
                        <v-col cols="12">
                          object
                        </v-col>
                      </v-row> -->
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- CREDIT ENQUIRY -->
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="px-0">
                  <div
                    class="pt-2 pb-2 px-3 text-body-2 font-weight-bold title-banner d-flex align-text"
                  >
                    <v-icon color="success" size="20" class="mr-3"
                      >mdi-check-circle</v-icon
                    >
                    CREDIT ENQUIRY
                  </div>
                  <div class="red-text pl-4">
                    <!-- These are the details you give us when you apply for your
                    Experian Credit Report. -->
                  </div>
                </v-col>
              </v-row>
              <template
                v-if="cibil.INProfileResponse.CAPS.CAPS_Application_Details[0]"
              >
                <v-row
                  v-for="(sub_item, index) in cibil.INProfileResponse.CAPS
                    .CAPS_Application_Details"
                  :key="index"
                  class="credit-enquiry-section"
                >
                  <v-col cols="6">
                    <div
                      class="field-content-blue"
                      v-if="sub_item.CAPS_Applicant_Details"
                    >
                      {{
                        checkForValueEmpty(
                          sub_item.CAPS_Applicant_Details.First_Name
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          sub_item.CAPS_Applicant_Details.Middle_Name1
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          sub_item.CAPS_Applicant_Details.Middle_Name2
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          sub_item.CAPS_Applicant_Details.Middle_Name3
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          sub_item.CAPS_Applicant_Details.Last_Name
                        )
                      }}
                    </div>
                    <div class="mt-2">
                      <div class="field-content-blue">Address</div>
                      <div
                        class="field-content"
                        v-if="sub_item.CAPS_Applicant_Address_Details"
                      >
                        {{
                          checkForValueEmpty(
                            sub_item.CAPS_Applicant_Address_Details
                              .FlatNoPlotNoHouseNo
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            sub_item.CAPS_Applicant_Address_Details
                              .BldgNoSocietyName
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            sub_item.CAPS_Applicant_Address_Details
                              .RoadNoNameAreaLocality
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            sub_item.CAPS_Applicant_Address_Details.City
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            sub_item.CAPS_Applicant_Address_Details.Landmark
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            sub_item.CAPS_Applicant_Address_Details.State
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            sub_item.CAPS_Applicant_Address_Details.PINCode
                          )
                        }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6"> </v-col>
                  <v-row class="credit-enquiry-section-table">
                    <v-col cols="4">
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Date of Birth
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="sub_item.CAPS_Applicant_Details"
                          >
                            {{
                              checkForDateValue(
                                sub_item.CAPS_Applicant_Details
                                  .Date_Of_Birth_Applicant
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Telephone
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="sub_item.CAPS_Applicant_Details"
                          >
                            {{
                              checkForValue(
                                sub_item.CAPS_Applicant_Details
                                  .Telephone_Number_Applicant_1st
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Mobile Phone
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="sub_item.CAPS_Applicant_Details"
                          >
                            {{
                              checkForValue(
                                sub_item.CAPS_Applicant_Details
                                  .Telephone_Number_Applicant_1st
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Gender
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="sub_item.CAPS_Applicant_Details"
                          >
                            {{
                              checkForGenderValue(
                                sub_item.CAPS_Applicant_Details.Gender_Code
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Marital Status
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="sub_item.CAPS_Other_Details"
                          >
                            {{
                              checkForValue(
                                sub_item.CAPS_Other_Details.Marital_Status
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Email
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <div
                            class="field-content "
                            v-if="sub_item.CAPS_Applicant_Details"
                          >
                            {{
                              checkForValue(
                                sub_item.CAPS_Applicant_Details.EMailId
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              PAN
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Passport Number
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Voter ID
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Driving License
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Ration Card
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              ERN
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Search Type
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Credit Institution Name
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            {{ checkForValue(sub_item.Subscriber_Name) }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Application Date
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            {{ checkForDateValue(sub_item.Date_of_Request) }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Amount Applied for
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            {{ checkForValue(sub_item.Amount_Financed) }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Duration of Agreement
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <div class="field-content ">
                            {{ checkForValue(sub_item.Duration_Of_Agreement) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-row>
              </template>
              <template v-else>
                <v-row class="credit-enquiry-section">
                  <v-col cols="6">
                    <div
                      class="field-content-blue"
                      v-if="
                        cibil.INProfileResponse.CAPS.CAPS_Application_Details
                          .CAPS_Applicant_Details
                      "
                    >
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.CAPS.CAPS_Application_Details
                            .CAPS_Applicant_Details.First_Name
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.CAPS.CAPS_Application_Details
                            .CAPS_Applicant_Details.Middle_Name1
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.CAPS.CAPS_Application_Details
                            .CAPS_Applicant_Details.Middle_Name2
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.CAPS.CAPS_Application_Details
                            .CAPS_Applicant_Details.Middle_Name3
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.CAPS.CAPS_Application_Details
                            .CAPS_Applicant_Details.Last_Name
                        )
                      }}
                    </div>
                    <div class="mt-2">
                      <div class="field-content-blue">Address</div>
                      <div
                        class="field-content"
                        v-if="
                          cibil.INProfileResponse.CAPS.CAPS_Application_Details
                            .CAPS_Applicant_Address_Details
                        "
                      >
                        {{
                          checkForValueEmpty(
                            cibil.INProfileResponse.CAPS
                              .CAPS_Application_Details
                              .CAPS_Applicant_Address_Details
                              .FlatNoPlotNoHouseNo
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            cibil.INProfileResponse.CAPS
                              .CAPS_Application_Details
                              .CAPS_Applicant_Address_Details.BldgNoSocietyName
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            cibil.INProfileResponse.CAPS
                              .CAPS_Application_Details
                              .CAPS_Applicant_Address_Details
                              .RoadNoNameAreaLocality
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            cibil.INProfileResponse.CAPS
                              .CAPS_Application_Details
                              .CAPS_Applicant_Address_Details.City
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            cibil.INProfileResponse.CAPS
                              .CAPS_Application_Details
                              .CAPS_Applicant_Address_Details.Landmark
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            cibil.INProfileResponse.CAPS
                              .CAPS_Application_Details
                              .CAPS_Applicant_Address_Details.State
                          )
                        }}
                        {{
                          checkForValueEmpty(
                            cibil.INProfileResponse.CAPS
                              .CAPS_Application_Details
                              .CAPS_Applicant_Address_Details.PINCode
                          )
                        }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6"> </v-col>
                  <v-row class="credit-enquiry-section-table">
                    <v-col cols="4">
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Date of Birth
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.CAPS
                                .CAPS_Application_Details.CAPS_Applicant_Details
                            "
                          >
                            {{
                              checkForDateValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details
                                  .CAPS_Applicant_Details
                                  .Date_Of_Birth_Applicant
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Telephone
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.CAPS
                                .CAPS_Application_Details.CAPS_Applicant_Details
                            "
                          >
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details
                                  .CAPS_Applicant_Details
                                  .Telephone_Number_Applicant_1st
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Mobile Phone
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.CAPS
                                .CAPS_Application_Details.CAPS_Applicant_Details
                            "
                          >
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details
                                  .CAPS_Applicant_Details
                                  .Telephone_Number_Applicant_1st
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Gender
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.CAPS
                                .CAPS_Application_Details.CAPS_Applicant_Details
                            "
                          >
                            {{
                              checkForGenderValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details
                                  .CAPS_Applicant_Details.Gender_Code
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Marital Status
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.CAPS
                                .CAPS_Application_Details.CAPS_Other_Details
                            "
                          >
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details.CAPS_Other_Details
                                  .Marital_Status
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Email
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <div
                            class="field-content "
                            v-if="
                              cibil.INProfileResponse.CAPS
                                .CAPS_Application_Details.CAPS_Applicant_Details
                            "
                          >
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details
                                  .CAPS_Applicant_Details.EMailId
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              PAN
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Passport Number
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Voter ID
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Driving License
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Ration Card
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              ERN
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Search Type
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            NA
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Credit Institution Name
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details.Subscriber_Name
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Application Date
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            {{
                              checkForDateValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details.Date_of_Request
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="bottom-padding">
                        <v-col cols="4" class="bottom-padding">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Amount Applied for
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8" class="bottom-padding">
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details.Amount_Financed
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <div class="caps-details-table-cell">
                            <div class="caps-details-table-title">
                              Duration of Agreement
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <div class="field-content ">
                            {{
                              checkForValue(
                                cibil.INProfileResponse.CAPS
                                  .CAPS_Application_Details
                                  .Duration_Of_Agreement
                              )
                            }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid v-else>
          <div class="text-center text-body-2 pt-3">No data available</div>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import cibil_res from "./cibil-response";

export default {
  data() {
    return {
      cibil_data: [],
      page_loader: false,
      account_type: [
        { value: "01", text: "AUTO LOAN" },
        { value: "02", text: "HOUSING LOAN" },
        { value: "03", text: "PROPERTY LOAN" },
        { value: "04", text: "LOAN AGAINST SHARES/SECURITIES" },
        { value: "05", text: "PERSONAL LOAN" },
        { value: "06", text: "CONSUMER LOAN" },
        { value: "07", text: "GOLD LOAN" },
        { value: "08", text: "EDUCATIONAL LOAN" },
        { value: "09", text: "LOAN TO PROFESSIONAL" },
        { value: "10", text: "CREDIT CARD" },
        { value: "11", text: "LEASING" },
        { value: "12", text: "OVERDRAFT" },
        { value: "13", text: "TWO-WHEELER LOAN" },
        { value: "14", text: "NON-FUNDED CREDIT FACILITY" },
        { value: "15", text: "LOAN AGAINST BANK DEPOSITS" },
        { value: "16", text: "FLEET CARD" },
        { value: "17", text: "Commercial Vehicle Loan" },
        { value: "18", text: "Telco - Wireless" },
        { value: "19", text: "Telco - Broadband" },
        { value: "20", text: "Telco - Landline" },
        { value: "23", text: "GECL Secured" },
        { value: "24", text: "GECL Unsecured" },
        { value: "31", text: "Secured Credit Card" },
        { value: "32", text: "Used Car Loan" },
        { value: "33", text: "Construction Equipment Loan" },
        { value: "34", text: "Tractor Loan" },
        { value: "35", text: "Corporate Credit Card" },
        { value: "36", text: "Kisan Credit Card" },
        { value: "37", text: "Loan on Credit Card" },
        { value: "38", text: "Prime Minister Jaan Dhan Yojana - Overdraft" },
        { value: "39", text: "Mudra Loans - Shishu / Kishor / Tarun" },
        { value: "40", text: "Microfinance - Business Loan" },
        { value: "41", text: "Microfinance - Personal Loan" },
        { value: "42", text: "Microfinance - Housing Loan" },
        { value: "43", text: "Microfinance - Others" },
        {
          value: "44",
          text:
            "Pradhan Mantri Awas Yojana - Credit Link Subsidy Scheme MAY CLSS",
        },
        { value: "45", text: "P2P Personal Loan" },
        { value: "46", text: "P2P Auto Loan" },
        { value: "47", text: "P2P Education Loan" },
        { value: "51", text: "BUSINESS LOAN - GENERAL" },
        {
          value: "52",
          text: "BUSINESS LOAN -PRIORITY SECTOR - SMALL BUSINESS",
        },
        { value: "53", text: "BUSINESS LOAN -PRIORITY SECTOR - AGRICULTURE" },
        { value: "54", text: "BUSINESS LOAN -PRIORITY SECTOR - OTHERS" },
        { value: "55", text: "BUSINESS NON-FUNDED CREDIT FACILITY - GENERAL" },
        {
          value: "56",
          text:
            "BUSINESS NON-FUNDED CREDIT FACILITY - PRIORITY SECTOR - SMALL BUSINESS",
        },
        {
          value: "57",
          text:
            "BUSINESS NON-FUNDED CREDIT FACILITY - PRIORITY SECTOR - AGRICULTURE",
        },
        {
          value: "58",
          text:
            "BUSINESS NON-FUNDED CREDIT FACILITY - PRIORITY SECTOR - OTHERS",
        },
        { value: "59", text: "BUSINESS LOANS AGAINST BANK DEPOSITS" },
        { value: "60", text: "Staff Loan" },
        { value: "61", text: "Business Loan - Unsecured" },
        { value: "00", text: "Others" },
      ],
      account_status: [
        { value: "00", text: "No Suit Filed [00]" },
        { value: "89", text: "Wilful default [89]" },
        { value: "93", text: "Suit Filed(Wilful default) [93]" },
        {
          value: "97",
          text: "Suit Filed(Wilful Default) and Written-off [97]",
        },
        { value: "30", text: "Restructured [30]" },
        { value: "31", text: "Restructured Loan (Govt. Mandated) [31]" },
        { value: "32", text: "Settled [32]" },
        { value: "33", text: "Post (WO) Settled [33]" },
        { value: "34", text: "Account Sold [34]" },
        { value: "35", text: "Written Off and Account Sold [35]" },
        { value: "36", text: "Account Purchased [36]" },
        { value: "37", text: "Account Purchased and Written Off [37]" },
        { value: "38", text: "Account Purchased and Settled [38]" },
        { value: "39", text: "Account Purchased and Restructured [39]" },
        { value: "40", text: "Status Cleared [40]" },
        { value: "41", text: "Restructured Loan [41]" },
        { value: "42", text: "Restructured Loan (Govt. Mandated) [42]" },
        { value: "43", text: "Written-off [43]" },
        { value: "44", text: "Settled [44]" },
        { value: "45", text: "Post (WO) Settled [45]" },
        { value: "46", text: "Account Sold [46]" },
        { value: "47", text: "Written Off and Account Sold [47]" },
        { value: "48", text: "Account Purchased [48]" },
        { value: "49", text: "Account Purchased and Written Off [49]" },
        { value: "50", text: "Account Purchased and Settled [50]" },
        { value: "51", text: "Account Purchased and Restructured [51]" },
        { value: "52", text: "Status Cleared [52]" },
        { value: "53", text: "Suit Filed [53]" },
        { value: "54", text: "Suit Filed and Written-off [54]" },
        { value: "55", text: "Suit Filed and Settled [55]" },
        { value: "56", text: "Suit Filed and Post (WO) Settled [56]" },
        { value: "57", text: "Suit Filed and Account Sold [57]" },
        {
          value: "58",
          text: "Suit Filed and Written Off and Account Sold [58]",
        },
        { value: "59", text: "Suit Filed and Account Purchased [59]" },
        {
          value: "60",
          text: "Suit Filed and Account Purchased and Written Off [60]",
        },

        {
          value: "61",
          text: "Suit Filed and Account Purchased and Settled [61]",
        },
        {
          value: "62",
          text: "Suit Filed and Account Purchased and Restructured [62]",
        },
        { value: "63", text: "Suit Filed and Status Cleared [63]" },
        { value: "64", text: "Wilful Default and Restructured Loan [64]" },
        {
          value: "65",
          text: "Wilful Default and Restructured Loan (Govt. Mandated) [65]",
        },
        { value: "66", text: "Wilful Default and Settled [66]" },
        { value: "67", text: "Wilful Default and Post (WO) Settled [67]" },
        { value: "68", text: "Wilful Default and Account Sold [68]" },
        {
          value: "69",
          text: "Wilful Default and Written Off and Account Sold [69]",
        },
        { value: "70", text: "Wilful Default and Account Purchased [70]" },
        {
          value: "72",
          text: "Wilful Default and Account Purchased and Written Off [72]",
        },
        {
          value: "73",
          text: "Wilful Default and Account Purchased and Settled [73]",
        },
        {
          value: "74",
          text: "Wilful Default and Account Purchased and Restructured [74]",
        },
        { value: "75", text: "Wilful Default and Status Cleared [75]" },
        {
          value: "76",
          text: "Suit filed (Wilful default) and Restructured [76]",
        },
        {
          value: "77",
          text:
            "Suit filed (Wilful default) and Restructured Loan (Govt. Mandated) [77]",
        },
        { value: "79", text: "Suit filed (Wilful default) and Settled [79]" },
        {
          value: "81",
          text: "Suit filed (Wilful default) and Post (WO) Settled [81]",
        },
        {
          value: "85",
          text: "Suit filed (Wilful default) and Account Sold [85]",
        },
        {
          value: "86",
          text:
            "Suit filed (Wilful default) and Written Off and Account Sold [86]",
        },
        {
          value: "87",
          text: "Suit filed (Wilful default) and Account Purchased [87]",
        },
        {
          value: "88",
          text:
            "Suit filed (Wilful default) and Account Purchased and Written Off [88]",
        },
        {
          value: "94",
          text:
            "Suit filed (Wilful default) and Account Purchased and Settled [94]",
        },
        {
          value: "90",
          text:
            "Suit filed (Wilful default) and Account Purchased and Restructured [90]",
        },
        {
          value: "91",
          text: "Suit filed (Wilful default) and Status Cleared [91]",
        },
        { value: "13", text: "Paid or closed account/zero balance [13]" },
        { value: "14", text: "CLOSED [14]" },
        { value: "15", text: "CLOSED [15]" },
        { value: "16", text: "CLOSED [16]" },
        { value: "17", text: "CLOSED [17]" },
        { value: "12", text: "CLOSED [12]" },
        { value: "11", text: "CURRENT ACCOUNT [11]" },
        { value: "71", text: "Account 30 days past the due date [71]" },
        { value: "78", text: "Account 60 days past the due date [78]" },
        { value: "80", text: "Account 90 days past the due date [80]" },
        { value: "82", text: "Account 120 days past the due date [82]" },
        { value: "83", text: "Account 150 days past the due date [83]" },

        { value: "84", text: "Account 180 days past the due date [84]" },
        { value: "DEFAULTVALUE", text: "ACTIVE" },
        { value: "21", text: "ACTIVE [21]" },
        { value: "22", text: "ACTIVE [22]" },
        { value: "23", text: "ACTIVE [23]" },
        { value: "24", text: "ACTIVE [24]" },
        { value: "25", text: "ACTIVE [25]" },
        { value: "131", text: "Restructured due to natural calamity [131]" },
        { value: "130", text: "Restructured due to COVID-19 [130]" },
      ],
      marital_status: [
        { value: "1", text: "Single" },
        { value: "2", text: "Married" },
        { value: "4", text: "Divorced" },
        { value: "3", text: "Widow/Widower" },
      ],
    };
  },
  computed: {
    cibil() {
      // return cibil_res ? cibil_res : "";
      return this.cibil_data ? this.cibil_data : "";
    },
  },
  mounted() {
    this.getCibilData();
  },
  methods: {
    checkForValue(value) {
      if (value) {
        if (value.text) return value.text;
        else return "-";
      } else {
        return "-";
      }
    },
    checkForValueEmpty(value) {
      if (value) {
        if (value.text) return value.text;
        else return "";
      } else {
        return "";
      }
    },
    checkForGenderValue(value) {
      if (value) {
        if (value.text) {
          if (value.text == 1) {
            return "Male";
          } else if (value.text == 2) {
            return "Female";
          } else if (value.text == 3) {
            return "Transgender";
          }
        } else return "-";
      } else {
        return "-";
      }
    },
    checkForAccountTypeValue(value) {
      if (value) {
        if (value.text) {
          let result = "";
          this.account_type.filter((item) => {
            // console.log(item.text);
            if (value.text == item.value) {
              // console.log(item.text);
              return (result = item.text);
            }
          });
          return result;
          // return value.text;
        } else return "-";
      } else {
        return "-";
      }
    },

    checkForAccountStatusValue(value) {
      if (value) {
        if (value.text) {
          let result = "";
          this.account_status.filter((item) => {
            // console.log(item.text);
            if (value.text == item.value) {
              // console.log(item.text);
              return (result = item.text);
            }
          });
          return result;
          // return value.text;
        } else return "-";
      } else {
        return "-";
      }
    },
    checkForDateValue(value) {
      if (value) {
        if (value.text) {
          let year = value.text.slice(0, 4);
          let month = value.text.slice(4, 6);
          let date = value.text.slice(6, 8);
          return `${date}-${month}-${year}`;
          // return value.text
        } else return "-";
      } else {
        return "-";
      }
    },
    checkIdDetails(value) {
      if (
        this.checkForValueEmpty(value.Income_TAX_PAN) ||
        this.checkForValueEmpty(value.PAN_Issue_Date) ||
        this.checkForValueEmpty(value.PAN_Expiration_Date) ||
        this.checkForValueEmpty(value.Passport_Number) ||
        this.checkForValueEmpty(value.Passport_Issue_Date) ||
        this.checkForValueEmpty(value.Passport_Expiration_Date) ||
        this.checkForValueEmpty(value.Voter_ID_Number) ||
        this.checkForValueEmpty(value.Voter_ID_Issue_Date) ||
        this.checkForValueEmpty(value.Voter_ID_Expiration_Date) ||
        this.checkForValueEmpty(value.Universal_ID_Number) ||
        this.checkForValueEmpty(value.Universal_ID_Issue_Date) ||
        this.checkForValueEmpty(value.Universal_ID_Expiration_Date) ||
        this.checkForValueEmpty(value.Driver_License_Number) ||
        this.checkForValueEmpty(value.Driver_License_Issue_Date) ||
        this.checkForValueEmpty(value.Driver_License_Expiration_Date) ||
        this.checkForValueEmpty(value.Ration_Card_Number) ||
        this.checkForValueEmpty(value.Ration_Card_Issue_Date) ||
        this.checkForValueEmpty(value.Ration_Card_Expiration_Date)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCibilData() {
      const self = this;
      self.page_loader = true;
      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };

      const successHandler = (response) => {
        if (response.data.success) {
          this.cibil_data = response.data.data;
          // console.log("CIBIL date", response);
          self.page_loader = false;
        }
      };
      const finallyHandler = () => {
        self.page_loader = false;
      };

      self.request_GET(
        self,
        self.$urls.GET_CIBIL_INFO,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    printDoc() {
      // document.getElementById("print-btn").onclick = function() {
      //   printElement(document.getElementById("print-section"));
      // };
      // function printElement(elem) {
      //   var domClone = elem.cloneNode(true);
      //   var $printSection = document.getElementById("print-section");
      //   // if (!$printSection) {
      //   //   var $printSection = document.createElement("div");
      //   //   $printSection.id = "print-section";
      //   //   document.body.appendChild($printSection);
      //   // }
      //   $printSection.innerHTML = "";
      //   $printSection.appendChild(domClone);
      //   window.print();
      // }
      // works but without css
      // const printableContent = document.getElementById("print-section");
      // const printWindow = window.open("", "", "height=1000,width=1000");
      // printWindow.document.write(printableContent.innerHTML);
      // printWindow.print();
      // window.print()
    },
  },
};
</script>
