<template>
  <div>
    <v-dialog width="500" v-model="show_dialog">
      <v-card>
        <v-card-title
          class="primary_2 text-h6 white--text py-3 d-flex justify-space-between"
        >
          <div>Reset Confirmation</div>
          <v-btn fab text small @click="show_dialog = false">
            <v-icon color="white" large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="font-weight-medium pt-3 text-body-1">
          Are you you want to reset
          <span class="font-weight-black">
            {{
              request_type
                .replace(/_/g, " ")
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")
            }}
          </span>
          request?
          <!-- Are you you want to reset request? -->
        </v-card-text>
        <v-card-actions class="d-flex justify-end font-weight-bold mb-2">
          <v-btn
            class="secondary_2 mr-2  white--text"
            depressed
            :disabled="btn_loader"
            :loading="btn_loader"
            @click="refreshRequest"
            >Yes</v-btn
          >
          <v-btn
            depressed
            color="secondary_2"
            outlined
            @click="show_dialog = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      request_type: "",
      btn_loader: false,
    };
  },
  methods: {
    async openResetConfirmation(value) {
      this.show_dialog = true;
      this.request_type = value;
    },
    refreshRequest() {
      const self = this;

      self.btn_loader = true;

      let lookup_key = self.decrypt(this.$route.params.id);
      let body = {
        request_type: self.request_type,
      };

      const successHandler = () => {
        self.show_dialog = false;
        self.$emit("refresh");
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.POST_request(
        self,
        self.$urls.RESET_REQUEST + `${lookup_key}/`,
        body,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
