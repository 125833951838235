const messages = {
    MOBILE_NUMBER_ERROR: "Mobile Number can not be empty",
    INVALID_MOBILE_NUMBER_ERROR: "Mobile number must be valid",
    INVALID_OTP: "Invalid OTP",
    EMAIL_ERROR: "Email can not be empty",
    EMAIL_MUST_BE_VALID: "Email must be valid",
    NAME_ERROR: "Name can not be empty",

    NO_REFERENCE: "Reference data not found",
    CURRENT_ADDRESS_NOT_FOUND: "Current Address not found",
    PERMANENT_ADDRESS_NOT_FOUND: "Permanent Address not found",
    EMPLOYMENT_DETAILS_NOT_FOUND: "Employment details not found",
    AADHAAR_DETAILS_NOT_FOUND: "Aadhaar details not found",
    BANK_DETAILS_NOT_FOUND: "Bank details not found",
    DOCUMENTS_NOT_FOUND: "Documents not found",
    VERIFICATION_DETAILS_NOT_FOUND: 'Verification details not found',

    FIELD_CANNOT_BE_EMPTY: 'This field cannot be empty',

    NO_ACTIVE_LOAN_AVAILABLE: "No active loan available",
    NO_PAYMENT_DONE: "No payment done",


}

export default messages