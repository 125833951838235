<template>
  <div>
    <div class="list-section-wrapper neumorphism-pressed">
      <div class="mb-4">
        <v-row>
          <v-col cols="4" class="notification-filter-section neumorphism-flat">
            <span class="px-3">
              <v-icon>mdi-filter</v-icon>
            </span>
            <!-- <v-select
              v-model="notification_type"
              :items="notification_type_list"
              dense
              append-outer-icon="mdi-filter-outline"
              label="Select Notification Type"
              item-text="text"
              item-value="value"
            ></v-select> -->
            <div class="notification-filter-wrapper">
              <select v-model="notification_type" class="notification-filter">
                <option class="select-option" value="bulk">Bulk</option>
                <option class="select-option" value="individual"
                  >Individual</option
                >
              </select>
              <span class="pr-2 notification-filter-arrow">
                <v-icon>mdi-chevron-down</v-icon>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-data-table
          class="neumorphism-flat"
          :items="notification_history_list"
          :headers="dynamic_header"
          :loading="table_loading"
          hide-default-footer
        >
          <template v-slot:[`item.customer_details`]="{ item }">
            <div class="py-2">
              {{ item.full_name }}
            </div>
            <div class="py-2">
              {{ item.mobile }}
            </div>
            <div class="py-2">
              {{ item.email }}
            </div>
          </template>
          <template v-slot:[`item.notification_detail.image`]="{ item }">
            <div class="notification-image pa-3">
              <img :src="item.notification_detail.image" />
            </div>
          </template>
          <template v-slot:[`item.notification_detail.body`]="{ item }">
            <!-- <div class="notification-body pa-3">
              {{ item.notification_detail.body }}
            </div> -->
            <v-tooltip bottom max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <div class="body-text-wrapper" v-bind="attrs" v-on="on">
                  {{ item.notification_detail.body }}
                </div>
              </template>
              <div>{{ item.notification_detail.body }}</div>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="mt-4">
          <v-pagination
            class="custom"
            v-model="page_number"
            light
            circle
            :length="total_page_count"
            :total-visible="7"
            @input="getNotificationHistoryList"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      notification_history_list: [],
      table_loading: false,
      page_number: 1,
      total_page_count: 1,
      notification_type: "bulk",
      notification_type_list: [
        { text: "Bulk", value: "bulk" },
        { text: "Individual", value: "individual" },
      ],
      bulk_headers: [
        // {
        //   text: "ID",
        //   align: "start",
        //   sortable: false,
        //   value: "id",
        //   // class: "primary_2 white--text",
        // },
        {
          text: "Initaited On",
          align: "start",
          sortable: false,
          value: "notification_date",
          width: "140",
          // class: "primary_2 white--text",
        },
        {
          text: "Image",
          align: "start",
          sortable: false,
          width: "150",
          value: "notification_detail.image",
          // class: "primary_2 white--text",
        },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "notification_detail.title",
          // class: "primary_2 white--text",
        },
        {
          text: "Topic",
          align: "start",
          sortable: false,
          value: "notification_detail.topic",
          // class: "primary_2 white--text",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "notification_detail.type",
          // class: "primary_2 white--text",
        },
        {
          text: "Body",
          align: "start",
          sortable: false,
          value: "notification_detail.body",
          // class: "primary_2 white--text",
        },
      ],
      individual_headers: [
        // {
        //   text: "ID",
        //   align: "start",
        //   sortable: false,
        //   value: "id",
        //   // class: "primary_2 white--text",
        // },
        {
          text: "Initaited On",
          align: "start",
          sortable: false,
          value: "notification_date",
          // class: "primary_2 white--text",
        },
        {
          text: "Target User(s)",
          align: "start",
          sortable: false,
          value: "target_users",
          // class: "primary_2 white--text",
        },
        // {
        //   text: "Image",
        //   align: "start",
        //   sortable: false,
        //   value: "notification_detail.image",
        //   // class: "primary_2 white--text",
        // },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "notification_detail.title",
          // class: "primary_2 white--text",
        },
        {
          text: "Topic",
          align: "start",
          sortable: false,
          value: "notification_detail.topic",
          // class: "primary_2 white--text",
        },
      ],
    };
  },
  watch: {
    notification_type() {
      this.getNotificationHistoryList();
    },
  },
  computed: {
    dynamic_header() {
      switch (this.notification_type) {
        case "bulk":
          return this.bulk_headers;
        case "individual":
          return this.individual_headers;
        default:
          return "";
      }
    },
  },
  mounted() {
    this.getNotificationHistoryList();
  },
  methods: {
    getNotificationHistoryList() {
      const self = this;
      self.table_loading = true;
      const lookup_key = "";
      self.notification_history_list = [];
      // self.tabs_item = [];
      let params = {
        notification_type: this.notification_type,
      };
      const successHandler = (response) => {
        // console.log("getNotificationHistoryList", response);
        self.notification_history_list = response.data.result.customer_list;
        self.total_page_count = response.data.result.total_page_count;
        self.table_loading = false;
      };
      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {
        self.table_loading = false;
      };
      self.GET_request(
        self,
        self.$urls.GET_NOTIFICATION_HISTORY,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped>
.list-section-wrapper {
  padding: 40px 25px;
  border-radius: 25px;
}
.notification-filter {
  /* background: blue; */
  padding: 10px 15px;
  width: 100%;
  /* border-radius: 10px;
  background: #e0e0e0;
  box-shadow: 6px 6px 12px #b5b5b5, -6px -6px 12px #ffffff; */
  outline: none;
  border: none;
}
/* .notification-filter:hover {
  background: blue;
} */
.notification-filter-section {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0;
  margin: 0 0 20px 10px;
  border-radius: 10px;
}
.notification-filter-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.notification-filter-arrow {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(40%);
}
.select-option {
  padding: 20px !important;
}

.notification-image img {
  width: 180px;
}

.notification-body {
  /* display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 43px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.custom .v-pagination--circle {
  background: green !important;
}
.body-text-wrapper {
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
