<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}

.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.kyc-row:hover {
  background-color: rgba(169, 169, 169, 0.147);
}
</style>
<template>
  <div>
    <div class="tab_header d-flex black--text font-weight-bold px-2 mb-1">
      <div style="width: 95%" class="d-flex align-center py-3" @click="toggleAccordion()">
        <div class="text-body-1 font-weight-medium px-3">
          KYC
        </div>
      </div>
      <div style="width: 5%" class="d-flex align-center justify-end">
        <v-btn color="deep-purple" outlined class="mr-2" @click="sendEventToDocumentsDialog"
          v-if="getPermission('View All Documents')" small dense>View All
          Documents</v-btn>
        <v-icon color="black" class="mr-3" v-if="show" @click="toggleAccordion()">
          mdi-chevron-up
        </v-icon>
        <v-icon color="black" class="mr-3" v-else @click="toggleAccordion()">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
    <div v-if="show" class="px-4 pb-2">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between pb-0">
          <v-col cols="4">
            <p class="field-title  d-flex align-center ">
              Aadhaar Card
            </p>
            <p v-if="aadhaar_details.aadhaar_uid" class="field-content">
              {{ aadhaar_details.aadhaar_uid }}
            </p>
            <p v-else class="field-content">
              -
            </p>
          </v-col>
          <v-col cols="3" class="d-flex justify-center">
            <span class="text-body-2 ml-2 font-weight-bold success--text" v-if="kyc_details.aadhar_status == 'Success'">
              <v-icon class="success--text mr-1">
                mdi-check-circle-outline </v-icon>Verified
            </span>
            <span class="text-body-2 ml-2 font-weight-bold blue--text"
              v-else-if="kyc_details.aadhar_status == 'Under Review'">
              Under Review
            </span>
            <span v-else-if="kyc_details.aadhar_status == 'Failed'" class="text-body-2 ml-2 font-weight-bold error--text">
              <v-icon class="error--text mr-1"> mdi-close-circle-outline</v-icon>Falied
            </span>
            <span v-else-if="kyc_details.aadhar_status == 'Pending'"
              class="text-body-2 ml-2 font-weight-bold secondary_2--text">
              <v-icon class="secondary_2--text mr-1">
                mdi-alert-circle-outline</v-icon>Pending
            </span>
            <span v-else> </span>
          </v-col>
          <v-col cols="5" class="d-flex justify-end">
            <v-btn small text class="indigo lighten-5 py-4" :disabled="!aadhaar_details.address"
              @click="openCompareAadhaarDialog(kyc_details.aadhar_status)">
              <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between py-0">
          <v-col cols="4">
            <p class="field-title">
              PAN Card
            </p>
            <p v-if="kyc_extra_details.pan_number" class="field-content">
              {{ kyc_extra_details.pan_number }}
            </p>
            <p v-else class="field-content">
              -
            </p>
          </v-col>
          <v-col cols="3" class="d-flex justify-center">
            <span class="text-body-2 ml-2 font-weight-bold success--text" v-if="kyc_details.pan_status == 'Success'">
              <v-icon class="success--text mr-1">
                mdi-check-circle-outline </v-icon>Verified
            </span>
            <span class="text-body-2 ml-2 font-weight-bold blue--text"
              v-else-if="kyc_details.pan_status == 'Under Review'">
              Under Review
            </span>
            <span v-else-if="kyc_details.pan_status == 'Failed'" class="text-body-2 ml-2 font-weight-bold error--text">
              <v-icon class="error--text mr-1"> mdi-close-circle-outline</v-icon>Falied
            </span>
            <span v-else-if="kyc_details.pan_status == 'Pending'"
              class="text-body-2 ml-2 font-weight-bold secondary_2--text">
              <v-icon class="secondary_2--text mr-1">
                mdi-alert-circle-outline</v-icon>Pending
            </span>
            <span v-else> </span>
          </v-col>
          <v-col cols="5" class="d-flex justify-end">
            <v-btn small text @click="openComparePanDialog(kyc_details.pan_status)" :disabled="!kyc_extra_details.pan_url"
              class="indigo lighten-5 py-4">
              <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between pb-8">
          <v-col cols="4">
            <p class="field-title">
              Selfie
            </p>
          </v-col>
          <v-col cols="3" class="d-flex justify-center">
            <span class="text-body-2 ml-2 font-weight-bold success--text" v-if="kyc_details.selfie_status == 'Success'">
              <v-icon class="success--text mr-1">
                mdi-check-circle-outline </v-icon>Verified
            </span>
            <span class="text-body-2 ml-2 font-weight-bold blue--text"
              v-else-if="kyc_details.selfie_status == 'Under Review'">
              Under Review
            </span>
            <span v-else-if="kyc_details.selfie_status == 'Failed'" class="text-body-2 ml-2 font-weight-bold error--text">
              <v-icon class="error--text mr-1"> mdi-close-circle-outline</v-icon>Falied
            </span>
            <span v-else-if="kyc_details.selfie_status == 'Pending'"
              class="text-body-2 ml-2 font-weight-bold secondary_2--text">
              <v-icon class="secondary_2--text mr-1">
                mdi-alert-circle-outline</v-icon>Pending
            </span>
            <span v-else> </span>
          </v-col>
          <v-col cols="5" class="d-flex justify-end align-center">
            <v-btn outlined small color="success" class="mr-3" :loading="kyc_selfie_btn_loader"
              :disabled="kyc_selfie_btn_loader" v-if="kyc_details.selfie_status == 'Under Review'"
              @click="bypassSelfie()">
              Pass
            </v-btn>
            <v-btn small text :disabled="!kyc_extra_details.selfie_url" @click="openCompareSelfieDialog()"
              class="indigo lighten-5 py-4">
              <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <!-- compare aadhar details dialog -->
    <CompareAadhaarDetailsDialog v-if="compare_aadhaar_details_data.flag" :dialog="compare_aadhaar_details_data"
      @success="getKycDetails()"></CompareAadhaarDetailsDialog>
    <!-- compare selfie dialog -->
    <CompareSelfieDialog v-if="compare_selfie_data.flag" :dialog="compare_selfie_data" @success="getKycDetails()">
    </CompareSelfieDialog>
    <ComparePanDetailsDialog v-if="compare_pan_details_data.flag" :dialog="compare_pan_details_data"
      @success="getKycDetails()"></ComparePanDetailsDialog>
    <AllDocumentModal ref="childComponentRef"></AllDocumentModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AllDocumentModal from "./AllDocumentModal.vue";
export default {
  data() {
    return {
      show: true,
      kyc_details: [],
      kyc_extra_details: [],
      aadhaar_details: [],
      check_eligibility_details: [],
      compare_selfie_data: {
        flag: false,
        selfie_status: "",
        user_uploaded_photo_url: false,
        aadhar_photo_url: false,
        liveness_data: [],
      },


      compare_aadhaar_details_data: {
        flag: false,
        aadhaar_status: "",
        user_fiiled_data: {
          name: "",
          dob: "",
          father_name: "",
          permanent_address: "",
          current_address: "",
        },
        signzy_fetched_data: {
          name: "",
          dob: "",
          address: "",
        },
        pan_fetch_response: "",
      },
      compare_pan_details_data: {
        flag: false,
        aadhaar_status: "",
        user_fiiled_data: {
          name: "",
          dob: "",
          father_name: "",
          pan_number: "",
        },
        pan_url: "",
        pan_ocr_response: "",
        pan_fetch_response: "",
        aadhaar_details: {},
      },
      allDocuments: {}
    };
  },
  computed: {
    ...mapGetters({
      customer_id: "pre_verification/getCustomerId",
    }),
  },
  components: {
    CompareAadhaarDetailsDialog: () =>
      import("@/components/new-customer/dialog/CompareAadhaarDetailsDialog"),
    CompareSelfieDialog: () =>
      import("@/components/new-customer/dialog/CompareSelfieDialog"),
    ComparePanDetailsDialog: () =>
      import("@/components/new-customer/dialog/ComparePanDetailsDialog"),
    AllDocumentModal,
  },
  mounted() {
    this.getKycDetails();
    this.getApplicationFormDetails();
    this.getCheckEligiblityDetails();
  },
  methods: {
    sendEventToDocumentsDialog() {
      this.$refs.childComponentRef.viewAllDocuments();
    },
    // viewAllDocuments() {
    //   this.dialog.flag = true;
    //   // api call to retrieve all the documents
    //   const self = this;
    //   // let form = new FormData();
    //   // form.append("customer_id", this.customer_id);
    //   let params = {
    //     customer_id: this.customer_id,
    //   };
    //   // let header = {
    //   //   token: 'Bearer' + "58b31250ee0f7a1ccbe12bbaf103f7746212e094"
    //   // };
    //   const successHandler = (res) => {
    //     console.log("on success of get all documents api", res.data.result);
    //     this.allDocuments = res.data.result
    //   };
    //   self.request_GET(
    //     self,
    //     self.$urls.GET_ALL_DOCUMENTS,
    //     params,
    //     successHandler
    //   );
    // },
    // closeModal() {
    //   this.dialog.flag = false;
    // },
    toggleAccordion() {
      this.show = !this.show;
      if (this.show) {
        this.getKycDetails();
      }
    },
    // view document
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      // console.log("image preview", url);
      let image_url = url;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_name),
        },
      });
      window.open(route.href, "_blank");
    },
    openCompareAadhaarDialog(status) {
      this.compare_aadhaar_details_data.flag = true;
      this.compare_aadhaar_details_data.aadhaar_status = status;
      this.compare_aadhaar_details_data.user_fiiled_data.name = this.check_eligibility_details.name.toUpperCase();
      this.compare_aadhaar_details_data.user_fiiled_data.dob = this.check_eligibility_details.date_of_birth;
      this.compare_aadhaar_details_data.user_fiiled_data.father_name = this.check_eligibility_details.father_name.toUpperCase();
      this.compare_aadhaar_details_data.user_fiiled_data.current_address = `
          ${this.current_address_details.home_number}
          ${this.current_address_details.area}
          ${this.current_address_details.city}
          ${this.current_address_details.state}
          ${this.current_address_details.pin_code}
      `.toUpperCase();
      this.compare_aadhaar_details_data.user_fiiled_data.permanent_address = `
          ${this.permanent_address_details.home_number}
          ${this.permanent_address_details.area}
          ${this.permanent_address_details.city}
          ${this.permanent_address_details.state}
          ${this.permanent_address_details.pin_code}
      `.toUpperCase();
      this.compare_aadhaar_details_data.signzy_fetched_data.name = this.aadhaar_details.name;
      this.compare_aadhaar_details_data.signzy_fetched_data.dob = this.aadhaar_details.date_of_birth;
      this.compare_aadhaar_details_data.signzy_fetched_data.address = this.aadhaar_details.address;
    },
    // open compare pan details dialog
    openComparePanDialog(status) {
      // if (this.pan_details) {
      this.compare_pan_details_data.flag = true;
      this.compare_pan_details_data.aadhaar_status = status;
      this.compare_pan_details_data.user_fiiled_data.name = this.check_eligibility_details.name.toUpperCase();
      this.compare_pan_details_data.user_fiiled_data.dob = this.check_eligibility_details.date_of_birth;
      this.compare_pan_details_data.user_fiiled_data.father_name = this.check_eligibility_details.father_name.toUpperCase();
      this.compare_pan_details_data.user_fiiled_data.pan_number = this.check_eligibility_details.pan_number;
      this.compare_pan_details_data.pan_url = this.kyc_extra_details.pan_url;
      this.compare_pan_details_data.aadhaar_details = this.aadhaar_details;
      // } else {
      //   this.previewImage(this.kyc_extra_details.pan_url, "PAN");
      // }
    },
    openCompareSelfieDialog() {
      this.compare_selfie_data.flag = true;
      this.compare_selfie_data.selfie_status = this.kyc_details.selfie_status;
      this.compare_selfie_data.user_uploaded_photo_url = this.kyc_extra_details.selfie_url;
      this.compare_selfie_data.aadhar_photo_url = this.aadhaar_details.aadhar_photo_url;
      this.compare_selfie_data.liveness_data = this.kyc_extra_details;
    },
    getKycDetails() {
      const self = this;
      let params = {
        customer_id: this.customer_id,
      };

      const successHandler = (response) => {
        if (response.data.success) {
          self.kyc_details = response.data.kyc_status;
          self.kyc_extra_details = response.data.kyc_extra_info;
          self.aadhaar_details = response.data.aadhar_data;
          if (response.data.kyc_extra_info.pan_ocr_response) {
            self.compare_pan_details_data.pan_ocr_response = JSON.parse(
              response.data.kyc_extra_info.pan_ocr_response
            );
          }
          if (response.data.kyc_extra_info.pan_fetch_response) {
            self.compare_pan_details_data.pan_fetch_response = JSON.parse(
              response.data.kyc_extra_info.pan_fetch_response
            );
            self.compare_aadhaar_details_data.pan_fetch_response = JSON.parse(
              response.data.kyc_extra_info.pan_fetch_response
            );
          }
        }
      };
      const finallyHandler = () => { };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_KYC_DETAILS,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getApplicationFormDetails() {
      const self = this;
      let params = {
        customer_id: this.customer_id,
      };

      const successHandler = (response) => {
        if (response.data.success) {
          self.current_address_details =
            response.data.application_form.address_details.current_address;
          if (
            response.data.application_form.address_details.current_address
              .same_as_current_address == true
          ) {
            self.permanent_address_details =
              response.data.application_form.address_details.current_address;
          } else {
            self.permanent_address_details =
              response.data.application_form.address_details.permanent_address;
          }
        }
      };
      const finallyHandler = () => { };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_APPLICATION_FORM,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getCheckEligiblityDetails() {
      const self = this;

      self.loader = true;

      let params = {
        customer_id: this.customer_id,
      };

      const successHandler = (response) => {
        if (response.data.success) {
          self.check_eligibility_details = response.data.eligibility_check;
        }
      };
      const finallyHandler = () => {
        self.loader = false;
      };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_CHECK_ELIGIBLITY,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
