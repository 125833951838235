import Vue from 'vue';
import Vuex from 'vuex';

import * as snackbar from './modules/snakebar'
import * as drawer from './modules/drawer'
import * as customer from './modules/customer'
import * as emi_analysis from './modules/emi_analysis'
import * as payment from './modules/payment'
import * as new_customer from './modules/new-customer'
import * as pre_verification from './modules/per-verification'
import * as loan_applied from './modules/loan_applied'
import * as business_loan from './modules/business-loan'
import * as las from './modules/las'
import * as notification from './modules/notification'
import * as pl_funnel from './modules/pl-funnel'

Vue.use(Vuex);

export const store = new Vuex.Store({

    modules: {
        snackbar,
        drawer,
        customer,
        emi_analysis,
        payment,
        new_customer,
        pre_verification,
        loan_applied,
        business_loan,
        las,
        notification,
        pl_funnel,
    },
    state: {
        is_production: true,
        page_loader: false,
        search_text: "",
        // token:localStorage.getItem("token") || null,
        token: sessionStorage.getItem("token") || null,
        user_type: sessionStorage.getItem("user_type") || null,
        permission_list: JSON.parse(sessionStorage.getItem("permission_list")) || [],
        // user_type:localStorage.getItem("user_type") || null,
        warehouse: JSON.parse(localStorage.getItem('warehouse')) || null,
    },
    categories: [],
    getters: {
        getUsertype: state => {
            return state.user_type;
        },
        getSearchText: state => {
            return state.search_text;
        },
        getPermissionList: state => {
            return state.permission_list;
        },
    },
    mutations: {
        SET_USER_TYPE(state, value) {
            state.user_type = value
        },
        SET_PAGE_LOADER(state, value) {
            state.page_loader = value
        },
        SET_SEARCH_TEXT(state, value) {
            state.search_text = value
        },
        SET_PERMISSION_LIST(state, value) {
            state.permission_list = value
        },
    },
    actions: {
        setUserType({commit}, value) {
            commit('SET_USER_TYPE', value)
        },
        setPageLoader({commit}, value) {
            commit('SET_PAGE_LOADER', value)
        },
        setSearchText({commit}, value) {
            commit('SET_SEARCH_TEXT', value)
        },
        setPermissionList({commit}, value) {
            commit('SET_PERMISSION_LIST', value)
        },
    }
});


