<template>
  <div >
    <div class=" pt-4 pb-7">
      <router-view></router-view>
    </div>
    <!-- full screen page loader -->
    <!--		<page-loader/>-->
  </div>
</template>

<script type="text/javascript">


export default {
  components: {
    // Navbar: () => import('./Navbar.vue'),
  },
}
</script>