<template>
  <div>
    <div v-if="show_loader" class="loading-text">
      Loading<span class="dot-1">●</span>
      <span class="dot-2">●</span>
      <span class="dot-3">●</span>
      <span class="dot-4">●</span>
    </div>
    <div v-else class="d-flex justify-center align-center">
      <!-- {{finvu}} -->
      <v-simple-table>
        <thead class="text-center">
          <tr>
            <th class="main_heading">Finvu Fetched Data</th>
          </tr>
          <tr>
            <th colspan="2">Customer Details</th>
          </tr>
        </thead>
        <v-container>
          <tbody class="display_flex">
            <v-row>
              <v-col md="4">
                <v-row
                  class="display_flex"
                  v-for="item in finvu"
                  :key="item.Title"
                >
                  <div v-for="item in item.fiObjects" :key="item.Title">
                    <v-col>
                      <span class="title">Name</span> :
                      {{ item.Profile.Holders.Holder.name }}
                    </v-col>
                    <v-col>
                      <span class="title">Mobile Number</span> :
                      {{ item.Profile.Holders.Holder.mobile }}
                    </v-col>
                    <v-col>
                      <span class="title">Email</span> :
                      {{ item.Profile.Holders.Holder.email }}
                    </v-col>
                  </div>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-row
                  class="display_flex"
                  v-for="item in finvu"
                  :key="item.Title"
                >
                  <div v-for="item in item.fiObjects" :key="item.Title">
                    <v-col>
                      <span class="title">DOB</span> :
                      {{ item.Profile.Holders.Holder.dob }}
                    </v-col>
                    <v-col>
                      <span class="title">Pan Number</span> :
                      {{ item.Profile.Holders.Holder.pan }}
                    </v-col>
                    <v-col>
                      <span class="title">Nominee</span>:
                      {{ item.Profile.Holders.Holder.nominee }}
                    </v-col>
                  </div>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-row
                  class="display_flex"
                  v-for="item in finvu"
                  :key="item.Title"
                >
                  <div v-for="item in item.fiObjects" :key="item.Title">
                    <v-col>
                      <span class="title">Address</span> :
                      {{ item.Profile.Holders.Holder.address }}
                    </v-col>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </tbody>
        </v-container>
      </v-simple-table>
      <v-simple-table>
        <thead class="text-center">
          <tr>
            <th colspan="2">Account Details</th>
          </tr>
        </thead>
        <v-container>
          <tbody class="d-flex flex-column justify-center align-center">
            <v-row>
              <v-col md="6">
                <v-row
                  class="display_flex"
                  v-for="item in finvu"
                  :key="item.Title"
                >
                  <div v-for="item in item.fiObjects" :key="item.Title">
                    <v-col>
                      <span class="title">Status</span> :
                      {{ item.Summary.status }}
                    </v-col>
                    <v-col>
                      <span class="title">Type</span> : {{ item.Summary.type }}
                    </v-col>
                    <v-col>
                      <span class="title">Current Balance</span> :
                      {{ item.Summary.currentBalance }}
                    </v-col>
                  </div>
                </v-row>
              </v-col>
              <v-col md="6">
                <v-row
                  class="display_flex"
                  v-for="item in finvu"
                  :key="item.Title"
                >
                  <div v-for="item in item.fiObjects" :key="item.Title">
                    <v-col>
                      <span class="title">IFSC Code</span> :
                      {{ item.Summary.ifscCode }}
                    </v-col>
                    <v-col>
                      <span class="title"> Current OverDraft Limit</span> :
                      {{ item.Summary.currentODLimit }}
                    </v-col>
                    <v-col>
                      <span class="title"> Drawing Limit</span> :
                      {{ item.Summary.drawingLimit }}
                    </v-col>
                  </div>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-row
                  class="display_flex"
                  v-for="item in finvu"
                  :key="item.Title"
                >
                  <div v-for="item in item.fiObjects" :key="item.Title">
                    <v-col>
                      <span class="title"> Opening Date</span> :
                      {{ item.Summary.openingDate }}
                    </v-col>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </tbody>
        </v-container>
      </v-simple-table>
      <v-simple-table>
        <thead class="text-center">
          <tr>
            <th colspan="2">Finvu Profile Details</th>
          </tr>
        </thead>
        <v-container>
          <tbody class="d-flex flex-column justify-center align-center">
            <v-row>
              <v-col cols="12">
                <v-row
                  class="display_flex"
                  v-for="item in finvu"
                  :key="item.Title"
                >
                  <v-col>
                    <span class="title"> Finvu Profile Id</span> :
                    {{ item.fipId }}
                  </v-col>
                  <v-col>
                    <span class="title"> Finvu Profile Name</span> :
                    {{ item.fipName }}
                  </v-col>
                  <v-col>
                    <span class="title"> Customer Id</span>
                    : {{ item.custId }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </tbody>
        </v-container>
      </v-simple-table>

      <div>
        <v-simple-table v-for="item in finvu" :key="item.Title">
          <thead class="text-center">
            <tr>
              <th colspan="2">Transaction History</th>
            </tr>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                outlined
                dense
                rounded
                hide-details
                class="align-left"
                style="width:350px;margin-bottom:25px;margin-top:25px;margin-left: 25px;"
              ></v-text-field>
            </div>
          </thead>
          <v-data-table
            v-for="item in item.fiObjects"
            :key="item.Title"
            :headers="headers"
            :items-per-page="-1"
            class="data_table"
            hide-default-footer
            :items="item.Transactions.Transaction"
            :search="search"
          >
          </v-data-table>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "finvuDocuments",
  data() {
    return {
      finvu_data: {},
      finvu: [],
      show_loader: false,
      search: "",
      headers: [
        {
          text: "Type",
          align: "start",
          filterable: true,
          value: "type",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Mode",
          align: "start",
          filterable: true,
          value: "mode",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Amount",
          align: "start",
          filterable: true,
          value: "amount",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Current Balance",
          align: "start",
          filterable: true,
          value: "currentBalance",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        // {
        //   text: "transactionTimestamp",
        //   align: "start",
        //   filterable: true,
        //   value: "transactionTimestamp",
        //   sortable: true,
        // },
        {
          text: "Date",
          align: "start",
          filterable: true,
          value: "valueDate",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "txnId",
          align: "start",
          filterable: true,
          value: "txnId",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Narration",
          align: "start",
          filterable: true,
          value: "narration",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Reference",
          align: "start",
          filterable: true,
          value: "reference",
          sortable: true,
          class: "blue-grey lighten-4 font-weight-bold",
        },
      ],
      customer_details: [
        {
          Title: "Name",
          Value: "Tanvir",
        },
        {
          Title: "Mobile Number",
          Value: "6371075499",
        },
        {
          Title: "Email",
          Value: "tanvirkaurbedi@gmail.com",
        },
        {
          Title: "Address",
          Value: "Odisha,Khariar Road",
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getFinvuDetails();
  },
  methods: {
    getFinvuDetails() {
      // console.log("");
      const self = this;
      self.show_loader = true;
      let look_up_key = `${self.decrypt(this.$route.params.finvu_doc_id)}/`;
      const successHandler = (response) => {
        // console.log("FINVU JSON", response);
        this.finvu = response.data.result.Finvu_fetched_json_data.body;
        self.show_loader = false;
      };
      const finallyHandler = () => {
        self.show_loader = false;
      };

      self.GET_request(
        self,
        self.$urls.GET_FINVU_DATA_BY_ID,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
  //   computed: {
  //     finvu() {
  //       return this.finvu_data ? this.finvu_data : "";
  //     },
  //   },
};
</script>
<style scoped>
th {
  color: #000 !important;
  background-color: #d0d0d0;
  font-size: 20px !important;
  padding: 11px !important;
  font-weight: 800;
}

.title {
  color: black !important;
  font-weight: 700 !important;
}

.main_heading {
  background-color: white !important;
  margin-bottom: 25px !important;
  height: 76px !important;
  font-size: 30px !important;
}

/* .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    color: rgba(0, 0, 0, 0.6) !important;
} */

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

/* .v-data-table-header {
    color: red !important;
} */

.v-card__title {
  display: block !important;
}

.display_flex {
  display: flex;
  flex-direction: column;
}

.data_table {
  padding: 15px 4% !important;
}
.loading-text {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-top: 50px;
}
.dot-1 {
  position: relative;
  animation: dot 500ms linear alternate-reverse infinite;
}
.dot-2 {
  position: relative;
  animation: dot 500ms 150ms linear alternate-reverse infinite;
}
.dot-3 {
  position: relative;
  animation: dot 500ms 250ms linear alternate-reverse infinite;
}
.dot-4 {
  position: relative;
  animation: dot 500ms 300ms linear alternate-reverse infinite;
}
@keyframes dot {
  from {
    opacity: 1;
    /* top: 0px; */
  }
  to {
    opacity: 0;
    /* top: -15px; */
  }
}
</style>
