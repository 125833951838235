<template>
  <div>
    <v-dialog
      scrollable
      v-model="show_dialog"
      width="600"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h5 white--text primary_2 d-flex justify-space-between align-center"
        >
          <div>Push to Fibe confirmation</div>

          <div>
            <v-btn @click="show_dialog = false" icon
              ><v-icon color="white">mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="py-5 text-center text-h6">
          Are you sure you want to push
          <b>{{ customer_name }} - {{ mobile }} to FIbe?</b>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end pb-4">
          <v-btn
            color="secondary_2"
            rounded
            class="mx-1 white--text px-4"
            @click="pushLead()"
            depressed
            :loading="push_btn_loading"
            :disabled="push_btn_loading"
          >
            Push
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      push_btn_loading: false,
      customer_id: "",
      loan_id: "",
      customer_name: "",
      mobile: "",
    };
  },
  methods: {
    async openPushToFibe(item) {
      //   console.log("openLoanTrackDataDialog", item);
      this.show_dialog = true;
      this.customer_id = item.customer_id;
      this.loan_id = item.id;
      this.customer_name = item.name;
      this.mobile = item.mobile;
    },
    pushLead() {
      const self = this;
      self.push_btn_loading = true;
      let form = {};
      form["loan_id"] = this.loan_id;

      const successHandler = () => {
        this.push_btn_loading = false;
        this.$emit("update");
        this.show_dialog = false;
      };
      const finallyHandler = () => {
        self.push_btn_loading = false;
      };
      self.POST_request(
        self,
        self.$urls.PUSH_LEAD_TO_FIBE + `${this.customer_id}/`,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
