<style scoped></style>
<template>
  <div>
    <v-card elevation="5">
      <v-card-text class="pa-0 font-weight-bold">
        <div>
          <div class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1">
            <div
              @click="toggleBasicDetailsAccordion()"
              style="width: 75%"
              class="d-flex align-center py-3"
            >
              <div class="text-body-1 font-weight-medium px-3">
                {{ basic_details_accordion.title }}
              </div>
            </div>
            <div style="width: 25%" class="d-flex align-center justify-end">
              <v-btn
                outlined
                class="white--text mr-2"
                small
                @click="editBasicInformation()"
                ><v-icon small>mdi-pencil</v-icon>Edit</v-btn
              >
              <v-btn fab small text @click="toggleBasicDetailsAccordion()">
                <v-icon color="white" v-if="basic_details_accordion.show">
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="basic_details_accordion.show">
            <div class="pb-3 px-3 pt-3">
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Customer Name
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.full_name }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Entity Type
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.entity_type }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    How old is Business
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.bussines_age }} Year(s)
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Loan Amount
                  </p>
                  <p class="field-content">
                    <span v-if="basic_details.loan_amount">
                      ₹{{
                        this.basic_details.loan_amount.toLocaleString("en-IN")
                      }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Sale/Turnover (Yearly)
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.turnover }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Loan Purpose
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.loan_purpose }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Business Registration
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.bussines_registration }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Residence
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.residence }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Working Experience
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.work_experience }} Year(s)
                  </p>
                </v-col>
                <!-- <v-col cols="4">
                  <p class="field-title">
                    Loan Purpose
                  </p>
                  <p class="field-content">
                    Purpose
                  </p>
                </v-col> -->
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Montly Business Sales
                  </p>
                  <p class="field-content">
                    {{ this.basic_details.monthly_business_sales }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <edit-basic-info :basic_detail="basic_dialog" @reload="getBasicDetails" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import EditBasicInfo from "../dialog/editBasicInfo.vue";
export default {
  components: {
    EditBasicInfo,

    // LoanTable: () => import("@/components/customer/LoanTable"),
  },
  data() {
    return {
      //   basic_details_accordion: {
      //     id: 1,
      //     title: "Basic Details",
      //     show: true,
      //   },

      are_all_collapsed: false,

      active_loan_id: null,
      is_other_doc_required: false,
      is_other_doc_verified: false,
      is_active_loan_available: false,

      basic_details: [],
      basic_dialog: {
        basic_info: {},
        id: "",
        flag: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      basic_details_accordion: "business_loan/getBasicDetailsAccordion",
    }),
  },
  mounted() {
    this.getBasicDetails();
  },
  methods: {
    toggleBasicDetailsAccordion() {
      //   this.basic_details_accordion.show = !this.basic_details_accordion.show;
      this.$store.dispatch("business_loan/setBasicDetailsAccordion");
      //   console.log(this.basic_details_accordion);
    },
    getBasicDetails() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        console.log("basic", response);
        this.basic_details = response.data.result;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_LOAN_BASIC_DETAILS,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    editBasicInformation() {
      this.basic_dialog.basic_info = this.basic_details;
      this.basic_dialog.flag = true;
    },
  },
};
</script>
<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
</style>
