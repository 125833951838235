<style lang="scss" scoped>
.active-btn-tab {
  background: #2f80ed;
  display: inline-block;
  cursor: pointer;

  min-width: fit-content;
  padding: 15px;
  color: #ffffff !important;

  .text-amount {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;

    text-align: left;
    font-weight: 800;
  }

  .text-tab-title {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    font-weight: 600;
    min-width: 120px;
  }

  .amount-title {
    font-size: 10px;
    font-weight: 600;
    text-align: left;
  }

  .total-amount {
    font-size: 14px;
    font-weight: 700;
  }

  .sub-tab {
    background: #d2d2d263;
    border-radius: 10px;
    font-size: 20px;
    min-width: fit-content;
  }
}

.btn-tab {
  background: #ffffff;
  display: inline-block;
  cursor: pointer;

  padding: 15px;
  min-width: fit-content;
  color: #333333 !important;

  .text-amount {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;

    font-weight: 800;
    text-align: left;
  }

  .text-tab-title {
    font-size: 16px;
    line-height: 19px;

    text-align: left;
    font-weight: 600;
    min-width: 120px;
    text-align: left;
  }

  .amount-title {
    font-size: 10px;
    font-weight: 600;
    text-align: left;
  }

  .total-amount {
    font-size: 14px;
    font-weight: 700;
  }

  .sub-tab {
    background: #e8e8eb;
    border-radius: 10px;
    font-size: 20px;
    min-width: fit-content;
  }
}
</style>
<template>
  <div class="py-4">
    <v-row grow class="border-a bg-white mx-0" height="160">
      <v-col
        :class="
          filter_status == $keys.INSTALLMENT_OVERDUE
            ? 'active-btn-tab'
            : 'btn-tab'
        "
        class="text-center"
        md="3"
        @click="handleTabClick($keys.INSTALLMENT_OVERDUE, 1)"
      >
        <v-row>
          <v-col class="pa-7">
            <v-row>
              <v-col cols="6" class="pt-0">
                <p class="text-amount">{{ total_over_due_emi_count }}</p>
                <p class="text-tab-title">Overdue EMI</p>
              </v-col>
              <!-- added no. of overdue emi users -->
              <v-col cols="6" class="pt-0">
                <p class="text-amount">{{ total_overdue_user_count }}</p>
                <p class="text-tab-title">Users</p>
              </v-col>
            </v-row>
            <!-- added overdue emi total amount, amount and charges -->
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-space-around align-center sub-tab"
              >
                <div class="mr-1">
                  <p class="amount-title">Total</p>
                  <p class="total-amount">₹ {{ total_overdue.toLocaleString('en-IN') }}</p>
                </div>
                =
                <div class="mx-1">
                  <p class="amount-title">Amount</p>
                  <p class="total-amount">₹ {{ overdue_amount.toLocaleString('en-IN') }}</p>
                </div>
                +
                <div class="ml-1">
                  <p class="amount-title">Charge</p>
                  <p class="total-amount">₹ {{ overdue_charge.toLocaleString('en-IN') }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        :class="
          filter_status == $keys.INSTALLMENT_DUE ? 'active-btn-tab' : 'btn-tab'
        "
        class="text-center"
        md="3"
        @click="handleTabClick($keys.INSTALLMENT_DUE, 2)"
      >
        <v-row>
          <v-col class="pa-7">
            <v-row>
              <v-col cols="6" class="pt-0">
                <p class="text-amount">{{ total_due_emi_count }}</p>
                <p class="text-tab-title">Upcoming EMI</p>
              </v-col>
              <!-- added no. of upcoming emi users -->
              <v-col cols="6" class="pt-0">
                <p class="text-amount">{{ total_due_user_count }}</p>
                <p class="text-tab-title">
                  Users
                </p>
              </v-col>
            </v-row>
            <!-- added upcoming emi total amount -->
            <v-row>
              <v-col
                cols="5"
                class="d-flex justify-space-around align-center sub-tab"
              >
                <div class="mr-1">
                  <p class="amount-title">Total</p>
                  <p class="total-amount">₹ {{ upcoming_amount.toLocaleString('en-IN') }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        :class="
          filter_status == $keys.INSTALLMENT_PAID ? 'active-btn-tab' : 'btn-tab'
        "
        class="text-center"
        md="3"
        @click="handleTabClick($keys.INSTALLMENT_PAID, 3)"
      >
        <v-row>
          <v-col class="pa-7">
            <v-row>
              <v-col cols="6" class="pt-0">
                <p class="text-amount">{{ total_paid_emi_count }}</p>
                <p class="text-tab-title">Paid EMI</p>
              </v-col>
              <!-- added no. of paid emi users -->
              <v-col cols="6" class="pt-0">
                <p class="text-amount">{{ total_paid_user_count }}</p>
                <p class="text-tab-title">Users</p>
              </v-col>
            </v-row>
            <!-- added paid emi total amount, amount and charge -->
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-space-around align-center sub-tab"
              >
                <div class="mr-1">
                  <p class="amount-title">Total</p>
                  <p class="total-amount">₹ {{ total_paid_amount.toLocaleString('en-IN') }}</p>
                </div>
                =
                <div class="mx-1">
                  <p class="amount-title">Amount</p>
                  <p class="total-amount">₹ {{ paid_amount.toLocaleString('en-IN') }}</p>
                </div>
                +
                <div class="ml-1">
                  <p class="amount-title">Charge</p>
                  <p class="total-amount">₹ {{ paid_charge.toLocaleString('en-IN') }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: [
    "filter_status",
    "total_over_due_emi_count",
    "total_due_emi_count",
    "total_paid_emi_count",
    "total_paid_amount",
    "paid_charge",
    "paid_amount",
    "overdue_charge",
    "overdue_amount",
    "total_overdue",
    "upcoming_amount",
    "total_overdue_user_count",
    "total_due_user_count",
    "total_paid_user_count",
  ],
  data() {
    return {
      // 'filter_status': 'Overdue',
    };
  },
  created() {
    console.log(this.$route.meta.show_back_btn);
  },
  methods: {
    handleTabClick(status, step) {
      this.$emit("update", status, step);
    },
  },
};
</script>
