<template>
  <div>
    <v-dialog
      v-model="show_dialog"
      width="500"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h6 white--text primary_2 d-flex justify-space-between"
        >
          Add {{ formateTitle(document_type) }}
          <v-btn @click="show_dialog = false" icon
            ><v-icon color="white">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="pb-1">
          <v-form ref="document_form">
            <v-row class="pt-3">
              <v-col cols="12">
                <v-file-input
                  show-size
                  truncate-length="30"
                  :rules="$rules.REQURIED_FILED"
                  v-model="file"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row
              class="pb-3"
              v-if="
                document_type == 'cc_account_statement' ||
                  document_type == 'bank_statement'
              "
            >
              <v-col cols="12">
                <v-text-field
                  v-model="document_password"
                  outlined
                  dense
                  placeholder="Enter Password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            elevation="0"
            color="success"
            class="white--text px-4 mr-3 mb-3"
            :disabled="btn_loader"
            :loading="btn_loader"
            @click="postDocument()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_dialog: false,
      valid: true,
      btn_loader: false,
      document_type: "",
      documents_list: [],
      file: null,
      document_password: "",
    };
  },
  methods: {
    async openAddOtherDocument(value) {
      this.show_dialog = true;
      this.document_type = value.key;
      // await this.getEnachDetails();
    },
    formateTitle(value) {
      let title = value
        .replace(/_/g, " ")
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return title;
    },
    postDocument() {
      if (this.$refs.document_form.validate()) {
        const self = this;
        self.btn_loader = true;
        let look_up_key = `${self.decrypt(this.$route.params.id)}/`;

        let form = new FormData();
        form.append("doc_type", this.document_type);
        form.append("doc_file", this.file);
        if (this.document_password) {
          form.append("document_password", this.document_password);
        }

        const successHandler = () => {
          self.btn_loader = false;
          this.$refs.document_form.reset();
          this.$emit("reload");
          self.show_dialog = false;
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_PUT(
          self,
          self.$urls.POST_OTHER_DOCUMENTS,
          look_up_key,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
  },
};
</script>
<style scoped></style>
