<template>
  <div>
    <v-dialog width="600" v-model="show_dialog">
      <v-card>
        <v-card-title
          class="primary_2 d-flex justify-space-between font-weight-bold white--text"
        >
          Update Loan Status
          <div>
            <v-btn text small fab @click="closeDialog()">
              <v-icon large color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="update_loan_status_form">
            <v-row>
              <v-col cols="12" class="pb-0 pt-3">
                <div>Loan Status</div>
                <v-select
                  v-model="loan_status"
                  outlined
                  dense
                  :items="loan_status_list"
                  :rules="$rules.REQURIED_FILED"
                  placeholder="Select Loan Status"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="
                loan_status == 'Rejected by NBFC' ||
                  loan_status == 'Rejected by Cashpo'
              "
            >
              <v-col cols="12" class="py-0">
                <div>Rejection Reason</div>
                <v-text-field
                  v-model="rejection_reason"
                  outlined
                  dense
                  :rules="$rules.REQURIED_FILED"
                  placeholder="Enter Rejection Reason"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <template v-if="loan_status == 'Disbursed'">
              <v-row>
                <v-col cols="6" class="py-0">
                  <div>Disbursement Date</div>
                  <v-menu
                    v-model="dusbursment_date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="disbursement_date"
                        placeholder="Select Disbursement Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        required
                        :rules="$rules.REQUIRED_FIELD"
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :disabled="disable_edit_disbursal_details"
                        :background-color="
                          disable_edit_disbursal_details ? '#eee' : ''
                        "
                        class="stepper-content-data"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="disbursement_date"
                      @input="dusbursment_date_picker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="py-0">
                  <div>Disbursed Amount</div>
                  <v-text-field
                    v-model="disbursed_amount"
                    outlined
                    dense
                    prepend-inner-icon="mdi-currency-inr"
                    :rules="$rules.AMOUNT"
                    placeholder="Enter Disbursed Amount"
                    :disabled="disable_edit_disbursal_details"
                    :background-color="
                      disable_edit_disbursal_details ? '#eee' : ''
                    "
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <div>EMI Amount</div>
                  <v-text-field
                    v-model="emi"
                    outlined
                    dense
                    prepend-inner-icon="mdi-currency-inr"
                    :disabled="disable_edit_disbursal_details"
                    :background-color="
                      disable_edit_disbursal_details ? '#eee' : ''
                    "
                    :rules="$rules.AMOUNT"
                    placeholder="Enter EMI Amount"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <div>Tenure</div>
                  <v-text-field
                    v-model="tenure"
                    outlined
                    dense
                    :rules="$rules.REQURIED_FILED"
                    :disabled="disable_edit_disbursal_details"
                    :background-color="
                      disable_edit_disbursal_details ? '#eee' : ''
                    "
                    placeholder="Enter Tenure"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <div>1st EMI Due Date</div>
                  <v-menu
                    v-model="due_date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="due_date"
                        placeholder="Select 1st EMI Due Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        :disabled="disable_edit_disbursal_details"
                        :background-color="
                          disable_edit_disbursal_details ? '#eee' : ''
                        "
                        required
                        :rules="$rules.REQUIRED_FIELD"
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        class="stepper-content-data"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="due_date"
                      @input="due_date_picker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col cols="12" class="d-flex justify-end align-center pa-0">
                <v-btn
                  color="secondary_2"
                  :disable="btn_loader"
                  :loading="btn_loader"
                  class="px-4 mr-3 mb-2 font-weight-bold white--text"
                  @click="updateLoanStatus()"
                >
                  Update
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      btn_loader: false,
      loan_status: "",
      loan_status_list: [
        "Disbursed",
        "Completed",
        "Rejected by Cashpo",
        "Rejected by NBFC",
      ],
      rejection_reason: "",
      disbursed_amount: null,
      dusbursment_date_picker: false,
      disbursement_date: null,
      emi: null,
      due_date_picker: false,
      due_date: null,
      tenure: null,
      disable_edit_disbursal_details: false,
    };
  },
  methods: {
    openUpdateStatusDialog(item) {
      this.show_dialog = true;
      this.loan_status = item;
      if (this.loan_status == "Disbursed") {
        this.disable_edit_disbursal_details = true;
        this.getDisbursalDetails();
      }
      //   console.log("openUpdateStatusDialog", item);
    },
    closeDialog() {
      this.$refs.update_loan_status_form.reset();
      this.show_dialog = false;
      this.btn_loader = false;
    },
    updateLoanStatus() {
      if (this.$refs.update_loan_status_form.validate()) {
        const self = this;
        self.btn_loader = true;
        let look_up_key = self.decrypt(this.$route.params.id);

        var form = new FormData();
        form.append("loan_status", this.loan_status);
        if (
          this.loan_status == "Rejected by NBFC" ||
          this.loan_status == "Rejected by Cashpo"
        ) {
          form.append("rejection_reason", this.rejection_reason);
        }
        const successHandler = () => {
          if (self.loan_status == "Disbursed") {
            self.postDisbursalDetails();
          } else {
            self.btn_loader = false;
            self.$emit("reload");
            self.show_dialog = false;
          }
        };
        const failureHandler = () => {
          self.btn_loader = false;
        };
        self.request_POST(
          self,
          self.$urls.UPDATE_LOAN_STATUS + `${look_up_key}/`,
          form,
          successHandler,
          null,
          failureHandler,
          null
        );
      }
    },
    getDisbursalDetails() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        this.basic_details = response.data.result;
        this.disbursed_amount = response.data.result.disbursal_amount;
        this.disbursement_date = response.data.result.disbursement_date;
        this.emi = response.data.result.emi_amount;
        this.due_date = response.data.result.first_emi_date;
        this.tenure = response.data.result.tenure;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_POST_DISBURSAL_DETAILS,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    postDisbursalDetails() {
      const self = this;
      let look_up_key = self.decrypt(this.$route.params.id);

      var form = new FormData();
      form.append("disbursal_amount", this.disbursed_amount);
      form.append("disbursement_date", this.disbursement_date);
      // form.append("disbursement_date", this.display_date_formate(this.disbursement_date));
      form.append("emi_amount", this.emi);
      form.append("first_emi_date", this.due_date);
      // form.append("first_emi_date", this.display_date_formate(this.due_date));
      form.append("tenure", this.tenure);
      const successHandler = () => {
        self.show_dialog = false;
        self.$emit("reload");
        self.btn_loader = false;
      };
      const failureHandler = () => {
        self.btn_loader = false;
      };
      self.request_POST(
        self,
        self.$urls.GET_POST_DISBURSAL_DETAILS + `${look_up_key}/`,
        form,
        successHandler,
        null,
        failureHandler,
        null
      );
    },
  },
};
</script>
