<template>
  <div>
    <v-row class="neumorphism-flat pr-5 pl-3 pb-6 count-analysis-card">
      <v-col cols="12" class="count-analysis-card-title pb-2"
        >Growth Analysis:
      </v-col>
      <template v-if="!is_content_loading">
        <v-row class="divider-line mx-1">
          <!-- {{ growth_analysis_data }} -->
          <v-col cols="12" class="pb-0 d-flex justify-space-between">
            <div class="growth-bulk-title mr-1">Bulk</div>
            <div v-if="bulk_data">
              <v-icon
                class="rise-icon-wrapper"
                v-if="bulk_data.difference[0] == '+'"
                color="success"
              >
                mdi-trending-up
              </v-icon>
              <v-icon
                class="neutral-icon-wrapper"
                v-else-if="bulk_data.difference[0] == '0'"
                color="blue"
              >
                mdi-trending-neutral
              </v-icon>
              <v-icon
                class="drop-icon-wrapper"
                v-else-if="bulk_data.difference[0] == '-'"
                color="error"
              >
                mdi-trending-down
              </v-icon>
            </div>
          </v-col>
          <v-col v-if="bulk_data" cols="12" class="py-0">
            <div class="current-count">
              {{ bulk_data.current_month }}
            </div>
            <div>
              <span :class="growthValue(bulk_data.difference)">
                {{ bulk_data.difference }}
              </span>
              vs last month
            </div>
          </v-col>
        </v-row>
        <v-row class="mx-1">
          <!-- {{ growth_analysis_data }} -->
          <v-col cols="12" class="pb-0 d-flex justify-space-between">
            <div class="growth-bulk-title mr-1">Individual</div>
            <div v-if="individual_data">
              <v-icon
                class="rise-icon-wrapper"
                v-if="individual_data.difference[0] == '+'"
                color="success"
              >
                mdi-trending-up
              </v-icon>
              <v-icon
                class="neutral-icon-wrapper"
                v-else-if="individual_data.difference[0] == '0'"
                color="blue"
              >
                mdi-trending-neutral
              </v-icon>
              <v-icon
                class="drop-icon-wrapper"
                v-else-if="individual_data.difference[0] == '-'"
                color="error"
              >
                mdi-trending-down
              </v-icon>
            </div>
          </v-col>
          <v-col v-if="individual_data" cols="12" class="py-0">
            <div class="current-count">
              {{ individual_data.current_month }}
            </div>
            <div>
              <span :class="growthValue(individual_data.difference)">
                {{ individual_data.difference }}
              </span>
              vs last month
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="text-center loading-wrapper">
          <v-progress-circular
            class="my-5"
            :size="80"
            color="#008ffb"
            width="8"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      growth_data: "notification/getGrowthDate",
      is_loading: "notification/getIsLoading",
    }),
    growth_analysis_data() {
      return this.growth_data;
    },
    bulk_data() {
      return this.growth_analysis_data.bulk;
    },
    individual_data() {
      return this.growth_analysis_data.individual;
    },
    is_content_loading() {
      return this.is_loading;
    },
  },
  methods: {
    growthValue(value) {
      if (value[0] == "+") {
        return "success--text font-weight-bold";
      } else if (value[0] == "0") {
        return "blue--text";
      } else {
        return "red--text font-weight-bold";
      }
    },
  },
};
</script>

<style scoped>
.count-analysis-card {
  border-radius: 15px;
  height: inherit;
}
.count-analysis-card-title {
  font-size: 20px;
  font-weight: 800;
  /* color: #6d7073; */
  color: #616467;
}
.current-count {
  font-size: 25px;
  font-weight: 800;
  color: #616467;
}
.previous-count {
  font-size: 20px;
  font-weight: 800;
  color: #616467;
}
.growth-bulk-title {
  color: #616467;
  font-weight: 600;
  /* letter-spacing: 1px; */
  margin-bottom: -10px;
}
.growth-individual-title {
  color: #00e396;
  letter-spacing: 1px;
  margin-bottom: -15px;
  font-weight: 800;
}
.growth-data-title {
  color: #616467;
  font-weight: 700;
  font-size: 12px;
}
.divider-line {
  border-bottom: 2px solid #8888885f;
}
.loading-wrapper {
  width: 100%;
}
.drop-icon-wrapper {
  border-radius: 50%;
  padding: 5px;
  background: #f951512b;
}
.neutral-icon-wrapper {
  border-radius: 50%;
  padding: 5px;
  background: #5191f92b;
}
.rise-icon-wrapper {
  border-radius: 50%;
  padding: 5px;
  background: #7bf9512b;
}
</style>
