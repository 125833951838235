<style scoped>
.compare-table {
  width: 100%;
  padding: 0 0 10px 0;
}
.compare-table td {
  padding: 10px 10px;
}
.compare-table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.149) !important;
}
.compare-table-paramenter {
  width: 150px !important;
}
.compare-table-pan-details {
  width: 200px !important;
}
</style>
<template>
  <div>
    <v-dialog v-model="dialog.flag" width="900">
      <v-card class="pb-0">
        <v-card-title class="text-h6 primary_2 white--text" primary-title>
          <p>Compare Aadhaar Details</p>
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <v-btn
              v-if="dialog.aadhar_pdf_url"
              small
              text
              color=""
              class="white py-4 mx-3"
              @click="
                isImageDocument(dialog.aadhar_pdf_url)
                  ? previewImage(dialog.aadhar_pdf_url, 'Aadhaar Card')
                  : viewDocument(dialog.aadhar_pdf_url)
              "
            >
              <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
            </v-btn>
            <div v-else class="mx-3 font-weight-bold text-caption white--text">
              No PDF available
            </div>
            <v-icon large color="white" @click="dialog.flag = false">
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <table class="font-weight-bold compare-table">
            <tr class="font-weight-bold black--text">
              <td>Status</td>
              <td class="compare-table-paramenter">Paramenters</td>
              <td>Customer filled data</td>
              <td>Aadhaar Data from Signzy</td>
              <td
                class="compare-table-pan-details"
                v-if="dialog.pan_fetch_response"
              >
                PAN Fetch Data from Signzy
              </td>
            </tr>
            <tr>
              <td>
                <p
                  v-if="
                    dialog.user_fiiled_data.name ==
                      dialog.signzy_fetched_data.name
                  "
                >
                  <v-icon class="success--text">
                    mdi-check-circle-outline
                  </v-icon>
                </p>
                <p v-else>
                  <v-icon class="secondary_2--text">
                    mdi-alert-circle-outline
                  </v-icon>
                </p>
              </td>
              <td class="black--text">Full Name</td>
              <td>{{ dialog.user_fiiled_data.name }}</td>
              <td>{{ dialog.signzy_fetched_data.name }}</td>
              <td v-if="dialog.pan_fetch_response">
                {{ dialog.pan_fetch_response.name }}
              </td>
            </tr>
            <tr>
              <td>
                <p
                  v-if="
                    dialog.user_fiiled_data.dob ==
                      dialog.signzy_fetched_data.dob
                  "
                >
                  <v-icon class="success--text">
                    mdi-check-circle-outline
                  </v-icon>
                </p>
                <p v-else>
                  <v-icon class="secondary_2--text">
                    mdi-alert-circle-outline
                  </v-icon>
                </p>
              </td>
              <td class="black--text">Date of Birth</td>
              <td>{{ dialog.user_fiiled_data.dob }}</td>
              <td>{{ dialog.signzy_fetched_data.dob }}</td>
              <td v-if="dialog.pan_fetch_response">
                {{ dialog.pan_fetch_response.dob }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="black--text">Father's Name</td>
              <td>{{ dialog.user_fiiled_data.father_name }}</td>
              <td></td>
              <td v-if="dialog.pan_fetch_response">
                {{ dialog.pan_fetch_response.fatherName }}
              </td>
            </tr>
            <tr>
              <td>
                <p
                  v-if="
                    dialog.user_fiiled_data.address ==
                      dialog.signzy_fetched_data.address
                  "
                >
                  <v-icon class="success--text">
                    mdi-check-circle-outline
                  </v-icon>
                </p>
                <p v-else>
                  <v-icon class="secondary_2--text">
                    mdi-alert-circle-outline
                  </v-icon>
                </p>
              </td>
              <td class="black--text">Permanent Address</td>
              <td>{{ dialog.user_fiiled_data.permanent_address }}</td>
              <td>{{ dialog.signzy_fetched_data.address }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td class="black--text">Current Address</td>
              <td>{{ dialog.user_fiiled_data.current_address }}</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </v-card-text>
        <v-card-actions
          v-if="dialog.aadhaar_status == 'Under Review'"
          class="d-flex justify-end pb-6 pr-6"
        >
          <v-btn
            outlined
            small
            color="success"
            :disabled="btn_loader"
            :loading="btn_loader"
            class="font-weight-bold ml-2"
            @click="updateKycStatus('aadhar_status', 'Success')"
          >
            Apporve
          </v-btn>
          <v-btn
            small
            outlined
            color="error"
            :disabled="btn_loader"
            :loading="btn_loader"
            class="font-weight-bold ml-2"
            @click="updateKycStatus('aadhar_status', 'Failed')"
          >
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      btn_loader: false,
    };
  },
  methods: {
    // view document
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      // console.log("image preview", url);
      let image_url = url;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_name),
        },
      });
      window.open(route.href, "_blank");
    },
    // post updated aadhaar status
    updateKycStatus(document_type, action) {
      const self = this;
      // console.log("type", document_type, "action", action);
      self.btn_loader = true;

      let form = new FormData();
      form.append("customer_id", self.decrypt(self.$route.params.id));
      form.append("kyc_field", document_type);
      form.append("kyc_status", action);

      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("kyc update res",response);
          self.$emit("success");
          this.dialog.flag = false;
        }
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_UPDATE_KYC_STATUS,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
