<template>
  <div>
    <!-- confirmation dialog box -->
    <v-dialog v-model="dialog.flag" width="400">
      <v-card>
        <v-card-title class="headline flex-column lighten-2" primary-title>
          <v-icon color="warning" size="5rem"> mdi-check-decagram </v-icon>
          {{ $lang.CONFIRM_AMOUNT }}?
        </v-card-title>
        <v-card-text class="flex-column text-center mb-5">
          Confirm amount to charge
        </v-card-text>
        <strong class="text-center align-center mx-15 px-5">
          Note: Total overdue amount: {{ dialog.total_payable_amount }}
        </strong>

        <div class="text-field">
          <v-text-field
            height="10"
            rounded
            v-model="amount"
            type="number"
            placeholder="Confirm Amount"
          ></v-text-field>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn class="fw-bold" color="light" width="80" @click="Close()">
            Cancel
          </v-btn>
          <v-btn
            :loading="btn_loading"
            class="fw-bold ml-5"
            color="primary"
            width="80"
            @click="charge"
          >
            Proceed
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      btn_loading: false,
      amount: null,
    };
  },
  methods: {
    Close() {
      this.amount = 0;
      this.dialog.total_payable_amount = 0;
      this.dialog.flag = false;
    },
    charge() {
      const self = this;
      var form = new FormData();
      form.append("loan_id", this.dialog.loan_id);
      form.append("payment_type", this.dialog.payment_type);
      form.append("amount", this.amount);
      self.btn_loading = true;
      const successHandler = (response) => {
        self.dialog.loan_id = "";
        self.dialog.payment_type = "";
        self.dialog.flag = false;
        this.$emit("update");
      };
      const finallyHandler = () => {
        self.btn_loading = false;
      };
      this.request_POST(
        self,
        this.$urls.CHARGE_MANUALLY,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
  mounted() {
    this.amount = this.dialog.total_payable_amount;
    console.log(this.amount);
  },
};
</script>

<style scoped>
.text-field {
  width: 150px !important;
  height: 40px;
  text-align: center;
  margin: 1px auto 10px auto;
  border: 1px solid #333;
}
</style>