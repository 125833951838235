<template>
  <div>
    <v-row class="mx-0">
      <v-col md="3  ">
        <v-menu v-model="filter.date_menu" :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-text-field v-model="filter.display_daterange" v-on="on" class="filter" dense filled
              label="Filter by date Range" outlined placeholder="Filter by date Range" readonly single-line>
              <template slot="append">
                <v-icon v-if="filter.daterange.length > 1" @click="clearDateFilter()">mdi-close</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filter.daterange" :max="filter.today" range>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="
              filter.daterange = [];
            filter.date_menu = false;
            ">Cancel</v-btn>
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="3">
        <v-select v-model="payment_mode" :items="payment_mode_option" clearable dense filled hide-details
          label="Select Payment Mode" menu-props="auto" outlined placeholder="Select Loan Status" single-line
          @change="getPaymentList">
        </v-select>
      </v-col>
      <v-col md="3">
        <v-select v-model="loan_status" :items="loan_status_options" clearable dense filled hide-details menu-props="auto"
          outlined placeholder="Select Loan Status" single-line @change="getPaymentList">
        </v-select>
      </v-col>
    </v-row>

    <v-card flat>
      <v-data-table :disable-sort="true" :headers="headers" :items="payment_list" :items-per-page="$keys.PAGE_LENGTH"
        :loading="table_loading" class="border-a border-0 custome-header-bg" hide-default-footer>
        <template v-slot:item.name="{ item }">
          <p class="mt-1">{{ item.name }}</p>
          <p>{{ item.mobile }}</p>
          <!-- v-chip added -->
          <v-chip small :color="getColor(item.current_loan_status)" class="mb-1 pr-4" outlined>{{
            item.current_loan_status }}</v-chip>
          <!-- v-chip added -->
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn class="ml-1" depressed fab small @click="openCommentDialog(item)">
            <v-icon color="secondary_2">mdi-message-text</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-pagination v-model="page_number" :length="total_page_count" :total-visible="7" class="custome-pagination"
      @input="getPaymentList"></v-pagination>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      table_loading: false,
      page_number: 1,
      total_page_count: 0,
      payment_list: [],
      payment_mode: "",
      payment_mode_option: [
        "Cash",
        "Cheque",
        "RZP-Manual",
        "RZP-E-NACH",
        "RZP-GATE-WAY",
        "Other",
      ],
      loan_status: "",
      loan_status_options: [
        "On Going",
        "Closed"
      ],
      date_range: [],
      headers: [
        { text: "Loan ID", value: "loan_id" },
        { text: "Customer Details", value: "name", align: "center" },
        { text: "Amt", value: "amount", align: "end" },
        { text: "#EMI", value: "installment_list" },
        { text: "Payment ID", value: "id" },
        { text: "Payment Date", value: "created" },
        { text: "Payment Mode", value: "payment_mode" },
        { text: "Payment Status", value: "payment_status" },
        { text: "Remark", value: "remark" },
      ],
      filter: {
        date_menu: false,
        daterange: [],
        display_daterange: null,
        today: new Date().toISOString().substr(0, 10),
      },
      /*dialog data*/
      dialog_data: {
        flag: false,
        title: "",
        is_edit: false,
        loading: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
    }),
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      this.getPaymentList(true);
    },
  },
  created() {
    console.log(this.$route.meta.show_back_btn);
    this.getPaymentList(true);
  },
  methods: {
    getColor(status) {
      if (status === 'On Going') {
        return 'success';
      }
      else if (status === 'Closed') {
        return 'error';
      }
      else {
        return 'orange';
      }
    },
    formateDisplayDate() {
      this.filter.display_daterange = this.formateDisplayDateRange(
        this.filter.daterange
      );
      this.filter.date_menu = false;
      this.getPaymentList();
    },
    clearDateFilter() {
      this.filter.daterange = [];
      this.filter.display_daterange = null;
      this.getPaymentList();
    },
    formateDisplayDateRange(daterange) {
      console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.display_date_formate(from);
      to = this.display_date_formate(to);
      return `${from} to ${to}`;
    },
    /* fetching the customer list form server*/
    getPaymentList(isCancel = false) {
      const self = this;
      self.table_loading = true;
      self.toggle_loading();
      let params = {
        page_number: this.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        payment_mode: this.payment_mode,
        current_loan_status:this.loan_status,
        from_date: this.filter.daterange[0],
        to_date: this.filter.daterange[1],
      };
      console.log(params);
      const successHandler = (response) => {
        self.table_loading = false;
        if (response.data.success) {
          self.payment_list = response.data.payment_list;
          self.total_page_count = response.data.total_page_count;
        }
      };
      const finallyHandler = () => {
        // self.table_loading = false;
        // self.toggle_loading()
      };

      self.request_GET(
        self,
        self.$urls.PAYMENT_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler,
        isCancel
      );
    },
    /* Open the Add member dialog form */
    openAddDialog() {
      this.dialog_data.title = this.$lang.ADD_MEMBER;
      this.dialog_data.is_edit = false;
      this.dialog_data.flag = true;
      setTimeout(() => {
        this.$refs.add_member_dialog.resetForm();
        this.$refs.add_member_dialog.id = null;
      }, 80);
    },
    /*Open the edit dialog form*/
    openEditDialog(id) {
      this.dialog_data.title = this.$lang.EDIT_MEMBER;
      this.$refs.add_member_dialog.getMemberDetails(id);
      this.dialog_data.is_edit = true;
      this.dialog_data.flag = true;
    },
  },
};
</script>
