<template>
  <div>
    <v-dialog
      v-model="business_data.flag"
      width="600"
      scrollable
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h5 white--text primary_2 d-flex justify-space-between align-center"
        >
          <div>Edit Business Details</div>

          <div>
            <v-btn @click="business_data.flag = false" icon
              ><v-icon color="white">mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="pt-5 py-2">
          <v-form lazy-validation ref="business_update_form">
            <v-row>
              <v-col cols="12">
                <div
                  v-if="
                    entity_type === 'sole_proprietor' || entity_type === 'HUF'
                  "
                >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Co Applicant's Name"
                        v-model="sole_proprietor_info.co_applicant_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Co Applicant Father's Name"
                        v-model="sole_proprietor_info.co_applicant_fathers_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Co Applicant Mother's Name"
                        v-model="sole_proprietor_info.co_applicant_mothers_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="dateObMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="sole_proprietor_info.co_applicant_dob"
                            placeholder="Select Date"
                            label="Co Applicant DOB"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            background-color="#FFF"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="sole_proprietor_info.co_applicant_dob"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="dateObMenu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="dateObMenu = false"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Relation"
                        v-model="sole_proprietor_info.relation"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Firm Name"
                        v-model="sole_proprietor_info.firm_name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div>
                    <v-row>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Photo
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon v-if="!photo_preview" class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="photo_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="photo_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="photo"
                                v-if="checkForImage(photo_file, photo_preview)"
                                :src="photo_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                alt="photo"
                                class="rounded-lg"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coPhotoFeild"
                            @change="savePhoto()"
                            id="photo_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          PAN
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon v-if="!pan_preview" class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="pan_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="pan_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                alt="pan"
                                class="rounded-lg"
                                v-if="checkForImage(pan_file, pan_preview)"
                                :src="pan_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                alt="pan"
                                class="rounded-lg"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coPanFeild"
                            @change="savePan()"
                            id="pan_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Aadhaar Front Back
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon
                            v-if="!aadhaar_front_back_preview"
                            class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label
                            for="aadhaar_front_back_file"
                            class=" upload-box"
                          >
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="aadhaar_front_back_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                alt="aadhaar front back"
                                class="rounded-lg"
                                v-if="
                                  checkForImage(
                                    aadhaar_front_back_file,
                                    aadhaar_front_back_preview
                                  )
                                "
                                :src="aadhaar_front_back_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="aadhaar front back"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coAadhaarFrontBackFeild"
                            @change="saveAadhaarFrontBack()"
                            id="aadhaar_front_back_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Aadhaar Front
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon
                            v-if="!aadhaar_front_preview"
                            class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="aadhaar_front_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="aadhaar_front_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                alt="aadhaar front"
                                class="rounded-lg"
                                v-if="
                                  checkForImage(
                                    aadhaar_front_file,
                                    aadhaar_front_preview
                                  )
                                "
                                :src="aadhaar_front_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="aadhaar front"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="caAadhaarFrontFeild"
                            @change="saveAadhaarFront()"
                            id="aadhaar_front_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Aadhaar Back
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon
                            v-if="!aadhaar_back_preview"
                            class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="aadhaar_back_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="aadhaar_back_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="aadhaar back"
                                v-if="
                                  checkForImage(
                                    aadhaar_back_file,
                                    aadhaar_back_preview
                                  )
                                "
                                :src="aadhaar_back_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                alt="aadhaar back"
                                height="147px"
                                class="rounded-lg"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coAadhaarBackFeild"
                            @change="saveAadhaarBack()"
                            id="aadhaar_back_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div
                  v-if="entity_type === 'partnership' || entity_type === 'LLP'"
                >
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        outlined
                        dense
                        label="Company Name"
                        v-model="partnership_info.firm_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        outlined
                        dense
                        label="Co Applicant Name"
                        v-model="partnership_info.co_applicant_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-menu
                        ref="menu1"
                        v-model="dateObMenu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="partnership_info.co_applicant_dob"
                            placeholder="Select Date"
                            label="Co Applicant DOB"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            background-color="#FFF"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="partnership_info.co_applicant_dob"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="dateObMenu1 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="dateObMenu1 = false"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        outlined
                        dense
                        label="Total Numbers of Partners"
                        v-model="partnership_info.total_numbers_of_partners"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        outlined
                        dense
                        label="Address Details"
                        v-model="partnership_info.address_details"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0">
                      <v-text-field
                        outlined
                        dense
                        label="Name of Partner 3"
                        v-model="partnership_info.name_of_partner_3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        outlined
                        dense
                        label="Name of Partner 4"
                        v-model="partnership_info.name_of_partner_4"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <div> -->
                  <v-row>
                    <v-col cols="6">
                      <div class="font-weight-bold pb-2 text-body-1">
                        Photo
                      </div>
                      <div class="upload-main" elevation="0">
                        <v-icon v-if="!photo_preview" class="upload-icon"
                          >mdi-cloud-upload-outline</v-icon
                        >
                        <label for="photo_file" class=" upload-box">
                          <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                          <div v-if="photo_preview">
                            <v-img
                              width="178px"
                              height="147px"
                              class="rounded-lg"
                              alt="photo"
                              v-if="checkForImage(photo_file, photo_preview)"
                              :src="photo_preview"
                            >
                            </v-img>
                            <v-img
                              width="178px"
                              height="147px"
                              alt="photo"
                              class="rounded-lg"
                              v-else
                              src="@/assets/images/file_logo.png"
                            ></v-img>
                          </div>
                        </label>
                        <input
                          ref="coPhotoFeild"
                          @change="savePhoto()"
                          id="photo_file"
                          style="visibility: hidden"
                          accept="image/*,application/*"
                          type="file"
                        />
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="font-weight-bold pb-2 text-body-1">
                        PAN
                      </div>
                      <div class="upload-main" elevation="0">
                        <v-icon v-if="!pan_preview" class="upload-icon"
                          >mdi-cloud-upload-outline</v-icon
                        >
                        <label for="pan_file" class=" upload-box">
                          <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                          <div v-if="pan_preview">
                            <v-img
                              width="178px"
                              height="147px"
                              alt="pan"
                              class="rounded-lg"
                              v-if="checkForImage(pan_file, pan_preview)"
                              :src="pan_preview"
                            >
                            </v-img>
                            <v-img
                              width="178px"
                              height="147px"
                              alt="pan"
                              class="rounded-lg"
                              v-else
                              src="@/assets/images/file_logo.png"
                            ></v-img>
                          </div>
                        </label>
                        <input
                          ref="coPanFeild"
                          @change="savePan()"
                          id="pan_file"
                          style="visibility: hidden"
                          accept="image/*,application/*"
                          type="file"
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="font-weight-bold pb-2 text-body-1">
                        Aadhaar Front Back
                      </div>
                      <div class="upload-main" elevation="0">
                        <v-icon
                          v-if="!aadhaar_front_back_preview"
                          class="upload-icon"
                          >mdi-cloud-upload-outline</v-icon
                        >
                        <label
                          for="aadhaar_front_back_file"
                          class=" upload-box"
                        >
                          <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                          <div v-if="aadhaar_front_back_preview">
                            <v-img
                              width="178px"
                              height="147px"
                              alt="aadhaar front back"
                              class="rounded-lg"
                              v-if="
                                checkForImage(
                                  aadhaar_front_back_file,
                                  aadhaar_front_back_preview
                                )
                              "
                              :src="aadhaar_front_back_preview"
                            >
                            </v-img>
                            <v-img
                              width="178px"
                              height="147px"
                              class="rounded-lg"
                              alt="aadhaar front back"
                              v-else
                              src="@/assets/images/file_logo.png"
                            ></v-img>
                          </div>
                        </label>
                        <input
                          ref="coAadhaarFrontBackFeild"
                          @change="saveAadhaarFrontBack()"
                          id="aadhaar_front_back_file"
                          style="visibility: hidden"
                          accept="image/*,application/*"
                          type="file"
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <div class="font-weight-bold pb-2 text-body-1">
                        Aadhaar Front
                      </div>
                      <div class="upload-main" elevation="0">
                        <v-icon
                          v-if="!aadhaar_front_preview"
                          class="upload-icon"
                          >mdi-cloud-upload-outline</v-icon
                        >
                        <label for="aadhaar_front_file" class=" upload-box">
                          <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                          <div v-if="aadhaar_front_preview">
                            <v-img
                              width="178px"
                              height="147px"
                              alt="aadhaar front"
                              class="rounded-lg"
                              v-if="
                                checkForImage(
                                  aadhaar_front_file,
                                  aadhaar_front_preview
                                )
                              "
                              :src="aadhaar_front_preview"
                            >
                            </v-img>
                            <v-img
                              width="178px"
                              height="147px"
                              class="rounded-lg"
                              alt="aadhaar front"
                              v-else
                              src="@/assets/images/file_logo.png"
                            ></v-img>
                          </div>
                        </label>
                        <input
                          ref="caAadhaarFrontFeild"
                          @change="saveAadhaarFront()"
                          id="aadhaar_front_file"
                          style="visibility: hidden"
                          accept="image/*,application/*"
                          type="file"
                        />
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="font-weight-bold pb-2 text-body-1">
                        Aadhaar Back
                      </div>
                      <div class="upload-main" elevation="0">
                        <v-icon v-if="!aadhaar_back_preview" class="upload-icon"
                          >mdi-cloud-upload-outline</v-icon
                        >
                        <label for="aadhaar_back_file" class=" upload-box">
                          <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                          <div v-if="aadhaar_back_preview">
                            <v-img
                              width="178px"
                              height="147px"
                              class="rounded-lg"
                              alt="aadhaar back"
                              v-if="
                                checkForImage(
                                  aadhaar_back_file,
                                  aadhaar_back_preview
                                )
                              "
                              :src="aadhaar_back_preview"
                            >
                            </v-img>
                            <v-img
                              width="178px"
                              alt="aadhaar back"
                              height="147px"
                              class="rounded-lg"
                              v-else
                              src="@/assets/images/file_logo.png"
                            ></v-img>
                          </div>
                        </label>
                        <input
                          ref="coAadhaarBackFeild"
                          @change="saveAadhaarBack()"
                          id="aadhaar_back_file"
                          style="visibility: hidden"
                          accept="image/*,application/*"
                          type="file"
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <!-- </div> -->
                </div>
              </v-col>
              <v-col cols="12">
                <div v-if="entity_type === 'company'">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Company Name"
                        v-model="company_info.company_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Total Number of Directors"
                        v-model="company_info.total_numbers_of_partners"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Name of director 2"
                        v-model="company_info.name_of_director_2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Role of director 2"
                        v-model="company_info.role_of_director_2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Address Details"
                        v-model="company_info.address_details"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Name of Director 3"
                        v-model="company_info.name_of_director_3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        label="Name of Director 4"
                        v-model="company_info.name_of_director_4"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div>
                    <v-row>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Photo
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon v-if="!photo_preview" class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="photo_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="photo_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="photo"
                                v-if="checkForImage(photo_file, photo_preview)"
                                :src="photo_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                alt="photo"
                                class="rounded-lg"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coPhotoFeild"
                            @change="savePhoto()"
                            id="photo_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          PAN
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon v-if="!pan_preview" class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="pan_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="pan_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                alt="pan"
                                class="rounded-lg"
                                v-if="checkForImage(pan_file, pan_preview)"
                                :src="pan_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                alt="pan"
                                class="rounded-lg"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coPanFeild"
                            @change="savePan()"
                            id="pan_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Aadhaar Front Back
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon
                            v-if="!aadhaar_front_back_preview"
                            class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label
                            for="aadhaar_front_back_file"
                            class=" upload-box"
                          >
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="aadhaar_front_back_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                alt="aadhaar front back"
                                class="rounded-lg"
                                v-if="
                                  checkForImage(
                                    aadhaar_front_back_file,
                                    aadhaar_front_back_preview
                                  )
                                "
                                :src="aadhaar_front_back_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="aadhaar front back"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coAadhaarFrontBackFeild"
                            @change="saveAadhaarFrontBack()"
                            id="aadhaar_front_back_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Aadhaar Front
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon
                            v-if="!aadhaar_front_preview"
                            class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="aadhaar_front_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="aadhaar_front_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                alt="aadhaar front"
                                class="rounded-lg"
                                v-if="
                                  checkForImage(
                                    aadhaar_front_file,
                                    aadhaar_front_preview
                                  )
                                "
                                :src="aadhaar_front_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="aadhaar front"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="caAadhaarFrontFeild"
                            @change="saveAadhaarFront()"
                            id="aadhaar_front_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="font-weight-bold pb-2 text-body-1">
                          Aadhaar Back
                        </div>
                        <div class="upload-main" elevation="0">
                          <v-icon
                            v-if="!aadhaar_back_preview"
                            class="upload-icon"
                            >mdi-cloud-upload-outline</v-icon
                          >
                          <label for="aadhaar_back_file" class=" upload-box">
                            <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                            <div v-if="aadhaar_back_preview">
                              <v-img
                                width="178px"
                                height="147px"
                                class="rounded-lg"
                                alt="aadhaar back"
                                v-if="
                                  checkForImage(
                                    aadhaar_back_file,
                                    aadhaar_back_preview
                                  )
                                "
                                :src="aadhaar_back_preview"
                              >
                              </v-img>
                              <v-img
                                width="178px"
                                alt="aadhaar back"
                                height="147px"
                                class="rounded-lg"
                                v-else
                                src="@/assets/images/file_logo.png"
                              ></v-img>
                            </div>
                          </label>
                          <input
                            ref="coAadhaarBackFeild"
                            @change="saveAadhaarBack()"
                            id="aadhaar_back_file"
                            style="visibility: hidden"
                            accept="image/*,application/*"
                            type="file"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="success white--text"
            @click="updateBusinessData()"
            :loading="btn_loader"
            :disabled="btn_loader"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["business_dialog"],
  data() {
    return {
      btn_loader: false,
      dateObMenu: false,
      dateObMenu1: false,
      business_data: this.business_dialog,
      sole_proprietor_info: {
        co_applicant_name: "",
        co_applicant_fathers_name: "",
        co_applicant_mothers_name: "",
        co_applicant_dob: null,
        relation: "",
        firm_name: "",
      },
      company_info: {
        name_of_director_2: "",
        role_of_director_2: "",
        company_name: "",
        address_details: "",
        total_numbers_of_partners: "",
        name_of_director_3: "",
        name_of_director_4: "",
      },
      partnership_info: {
        firm_name: "",
        co_applicant_name: "",
        co_applicant_dob: null,
        total_numbers_of_partners: "",
        address_details: "",
        name_of_partner_3: "",
        name_of_partner_4: "",
      },
      entity_type: "",
      loading: false,
      co_applicant_details: {},
      aadhaar_front_preview: null,
      aadhaar_front_file: null,
      aadhaar_back_preview: null,
      aadhaar_back_file: null,
      aadhaar_front_back_preview: null,
      aadhaar_front_back_file: null,
      pan_preview: null,
      pan_file: null,
      photo_preview: null,
      photo_file: null,
    };
  },
  watch: {
    "business_dialog.flag": function(value) {
      if (value) {
        console.log(this.business_data);
        this.entity_type = this.business_data.business_detail.entity_type;
        this.sole_proprietor_info.co_applicant_fathers_name = this.business_data.business_detail.co_applicant_details.co_applicant_fathers_name;
        this.sole_proprietor_info.co_applicant_mothers_name = this.business_data.business_detail.co_applicant_details.co_applicant_mothers_name;
        // this.sole_proprietor_info.co_applicant_dob = this.display_date_formate2(
        //   this.business_data.business_detail.co_applicant_details
        //     .co_applicant_dob
        // );
        this.sole_proprietor_info.co_applicant_dob = this.business_data.business_detail.co_applicant_details.co_applicant_dob;
        this.sole_proprietor_info.co_applicant_name = this.business_data.business_detail.co_applicant_details.co_applicant_name;
        this.sole_proprietor_info.relation = this.business_data.business_detail.co_applicant_details.relation;
        this.sole_proprietor_info.firm_name = this.business_data.business_detail.co_applicant_details.firm_name;
        this.company_info.name_of_director_2 = this.business_data.business_detail.co_applicant_details.name_of_director_2;
        this.company_info.role_of_director_2 = this.business_data.business_detail.co_applicant_details.role_of_director_2;
        this.company_info.company_name = this.business_data.business_detail.co_applicant_details.company_name;
        this.company_info.address_details = this.business_data.business_detail.co_applicant_details.address_details;
        this.company_info.total_numbers_of_partners = this.business_data.business_detail.co_applicant_details.total_numbers_of_partners;
        this.company_info.name_of_director_3 = this.business_data.business_detail.co_applicant_details.name_of_director_3;
        this.company_info.name_of_director_4 = this.business_data.business_detail.co_applicant_details.name_of_director_4;

        this.partnership_info.co_applicant_name = this.business_data.business_detail.co_applicant_details.co_applicant_name;
        // this.partnership_info.co_applicant_dob = this.display_date_formate2(
        //   this.business_data.business_detail.co_applicant_details
        //     .co_applicant_dob
        // );
        this.partnership_info.firm_name = this.business_data.business_detail.co_applicant_details.firm_name;
        this.partnership_info.co_applicant_dob = this.business_data.business_detail.co_applicant_details.co_applicant_dob;
        this.partnership_info.total_numbers_of_partners = this.business_data.business_detail.co_applicant_details.total_numbers_of_partners;
        this.partnership_info.address_details = this.business_data.business_detail.co_applicant_details.address_details;
        this.partnership_info.name_of_partner_3 = this.business_data.business_detail.co_applicant_details.name_of_partner_3;
        this.partnership_info.name_of_partner_4 = this.business_data.business_detail.co_applicant_details.name_of_partner_4;
        this.co_applicant_details = this.business_data.co_applicant_details;
        // console.log(this.co_applicant_details);
        this.assignUrl();
      }
    },
  },
  methods: {
    savePhoto() {
      this.photo_file = this.$refs.coPhotoFeild.files.item(0);
      // console.log("PHOTO", this.$refs.coPhotoFeild.files);
      this.photo_preview = URL.createObjectURL(this.photo_file);
      // this.postKycDocuments("photo_file", this.photo_file);
    },
    savePan() {
      this.pan_file = this.$refs.coPanFeild.files.item(0);
      // console.log(this.$refs.coPanFeild.files);
      this.pan_preview = URL.createObjectURL(this.pan_file);
      // this.postKycDocuments("pan_card_file", this.pan_file);
    },
    saveAadhaarFront() {
      this.aadhaar_front_file = this.$refs.caAadhaarFrontFeild.files.item(0);
      // console.log(this.$refs.coPanFeild.files);
      this.aadhaar_front_preview = URL.createObjectURL(this.aadhaar_front_file);
      // this.postKycDocuments("addhar_front_file", this.aadhaar_front_file);
    },
    saveAadhaarBack() {
      this.aadhaar_back_file = this.$refs.coAadhaarBackFeild.files.item(0);
      // console.log(this.$refs.coPanFeild.files);
      this.aadhaar_back_preview = URL.createObjectURL(this.aadhaar_back_file);
      // this.postKycDocuments("addhar_back_file", this.aadhaar_back_file);
    },
    saveAadhaarFrontBack() {
      this.aadhaar_front_back_file = this.$refs.coAadhaarFrontBackFeild.files.item(
        0
      );
      // console.log(this.$refs.coPanFeild.files);
      this.aadhaar_front_back_preview = URL.createObjectURL(
        this.aadhaar_front_back_file
      );
      // this.postKycDocuments(
      //   "addhar_front_and_back_file",
      //   this.aadhaar_front_back_file
      // );
    },
    assignUrl() {
      if (this.co_applicant_details) {
        this.aadhaar_front_preview = this.co_applicant_details.aadhar_card_front_file;
        this.aadhaar_back_preview = this.co_applicant_details.aadhar_card_back_file;
        this.aadhaar_front_back_preview = this.co_applicant_details.aadhar_card_front_and_back;
        this.pan_preview = this.co_applicant_details.pan_card_file;
        this.photo_preview = this.co_applicant_details.upload_photo_file;
      }
    },
    checkForImage(file, url) {
      // console.log("file", file, url);
      // return true;
      if (file) {
        let type = file.type.split("/");
        let ext = type[1];
        if (ext == "png" || ext == "jpg" || ext == "jpeg") {
          return true;
        } else {
          return false;
        }
      } else {
        let text = url.split(".");
        let ext = text[text.length - 1];
        if (ext == "png" || ext == "jpg" || ext == "jpeg") {
          return true;
        } else {
          return false;
        }
      }
      // } else {
      //   this.photo_preview = '@/assets/images/file_logo.png';
      //   console.log("inside else", this.photo_preview);
      // }
    },
    updateBusinessData() {
      if (this.$refs.business_update_form.validate()) {
        const self = this;
        self.btn_loader = true;
        let look_up_key = this.business_data.id;
        let entity_type = this.entity_type;
        let form = new FormData();
        if (
          this.entity_type === "sole_proprietor" ||
          this.entity_type === "HUF"
        ) {
          form.append(
            "co_applicant_name",
            this.sole_proprietor_info.co_applicant_name
          );
          form.append(
            "co_applicant_fathers_name",
            this.sole_proprietor_info.co_applicant_fathers_name
          );
          form.append(
            "co_applicant_mothers_name",
            this.sole_proprietor_info.co_applicant_mothers_name
          );
          form.append(
            "co_applicant_dob",
            this.sole_proprietor_info.co_applicant_dob
          );
          form.append("relation", this.sole_proprietor_info.relation);
          form.append("firm_name", this.sole_proprietor_info.firm_name);
        }
        if (this.entity_type === "company") {
          form.append(
            "name_of_director_2",
            this.company_info.name_of_director_2
          );
          form.append(
            "role_of_director_2",
            this.company_info.role_of_director_2
          );
          form.append("company_name", this.company_info.company_name);
          form.append("address_details", this.company_info.address_details);
          form.append(
            "total_numbers_of_partners",
            this.company_info.total_numbers_of_partners
          );
          form.append(
            "name_of_director_3",
            this.company_info.name_of_director_3
          );
          form.append(
            "name_of_director_4",
            this.company_info.name_of_director_4
          );
        }
        if (this.entity_type === "partnership" || this.entity_type === "LLP") {
          form.append(
            "co_applicant_name",
            this.partnership_info.co_applicant_name
          );
          form.append("firm_name", this.partnership_info.firm_name);
          form.append(
            "co_applicant_dob",
            this.partnership_info.co_applicant_dob
          );
          form.append(
            "total_numbers_of_partners",
            this.partnership_info.total_numbers_of_partners
          );
          form.append("address_details", this.partnership_info.address_details);
          form.append(
            "name_of_partner_3",
            this.partnership_info.name_of_partner_3
          );
          form.append(
            "name_of_partner_4",
            this.partnership_info.name_of_partner_4
          );
        }
        const successHandler = () => {
          this.postKycDocument();
        };
        const failureHandler = () => {
          self.btn_loader = false;
        };
        self.request_PATCH(
          self,
          self.$urls.UPDATE_BUSINESS_DETAIL,
          `${look_up_key}/` + `${entity_type}/`,
          form,
          successHandler,
          null,
          failureHandler,
          null
        );
      }
    },
    postKycDocument() {
      const self = this;
      let key = `${self.decrypt(this.$route.params.id)}/`;

      let complete_url = null;
      if (
        this.entity_type === "sole_proprietor" ||
        this.entity_type === "HUF"
      ) {
        complete_url = self.$urls.GET_SOLEPROPRIETORSHIP_OR_HUF_DATA;
      } else if (
        this.entity_type === "partnership" ||
        this.entity_type === "LLP"
      ) {
        complete_url = self.$urls.GET_PARTNERSHIP_OR_LLP_DATA;
      } else if (this.entity_type === "company") {
        complete_url = self.$urls.GET_COMPANY_DATA;
      }

      let doc_form = new FormData();

      if (this.photo_file != null) {
        doc_form.append("upload_photo_file", this.photo_file);
      }
      if (this.pan_file != null) {
        doc_form.append("pan_card_file", this.pan_file);
      }
      if (this.aadhaar_front_file != null) {
        doc_form.append("aadhar_card_front_file", this.aadhaar_front_file);
      }
      if (this.aadhaar_back_file != null) {
        doc_form.append("aadhar_card_back_file", this.aadhaar_back_file);
      }
      if (this.aadhaar_front_back_file != null) {
        doc_form.append(
          "aadhar_card_front_and_back",
          this.aadhaar_front_back_file
        );
      }
      const successHandler = () => {
        self.business_data.flag = false;
        self.clearDocumentData();
        self.btn_loader = true;
        self.$emit("reload");
      };
      const finallyHandler = () => {
        self.btn_loader = false;
        // console.log("FinallyHandler");
      };
      self.request_PUT(
        self,
        complete_url,
        key,
        doc_form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    clearDocumentData() {
      this.co_applicant_details = {};
      this.aadhaar_front_preview = null;
      this.aadhaar_front_file = null;
      this.aadhaar_back_preview = null;
      this.aadhaar_back_file = null;
      this.aadhaar_front_back_preview = null;
      this.aadhaar_front_back_file = null;
      this.pan_preview = null;
      this.pan_file = null;
      this.photo_preview = null;
      this.photo_file = null;
    },
  },
};
</script>
<style scoped>
.upload-box {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  gap: 10px;
  width: 180px;
  height: 150px;
  border: 1.8px dashed #989393;
  border-radius: 8px;
  line-height: 16px;
  color: #989393;
  /* background-color: #fff; */
  cursor: pointer;
}
.upload-main {
  position: relative;
  width: 180px;
  height: 150px;
}
.upload-icon {
  position: absolute;
  left: 50%;
  /* right: 50%; */
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 33px;
  color: #989393;
}
</style>
