<template>
  <div>
    <v-dialog v-model="show_dialog" :width="dialog_width">
      <div class="neumorphism-pressed">
        <v-row class="ma-0 pa-0">
          <v-col :cols="details_cols" class="pa-0 ma-0">
            <div
              class="add-template-title-section d-flex justify-space-between align-center py-4 px-8"
            >
              <div class="topic-title">
                <span v-if="is_new_template">Add</span>
                <span v-else>Edit</span>
                Template
              </div>
              <div>
                <button class="close-btn" @click="show_dialog = false">
                  <v-icon class="close-icon">
                    mdi-window-close
                  </v-icon>
                </button>
              </div>
            </div>
            <div class="add-template-table-wrapper">
              <v-form
                @submit.prevent="addEditTemplate()"
                v-model="is_valid"
                ref="add_edit_template_form"
              >
                <div>
                  <div class="add-template-field-title">Title</div>
                  <div class="notification-type-wrapper">
                    <v-text-field
                      v-model="title"
                      class="neumorphism-pressed topic-field"
                      dense
                      placeholder="Enter template title"
                      required
                      :rules="$rules.REQURIED_FILED"
                    ></v-text-field>
                    <!-- <input
                      v-model="title"
                      placeholder="Enter template title"
                      class="add-edit-template-input neumorphism-pressed mb-4"
                      type="text"
                    /> -->
                  </div>
                </div>
                <div>
                  <div class="add-template-field-title mt-4">Type</div>
                  <div class="notification-type-wrapper">
                    <v-text-field
                      v-model="type"
                      class="neumorphism-pressed topic-field"
                      dense
                      placeholder="Enter type"
                    ></v-text-field>
                    <!-- <input
                      v-model="type"
                      placeholder="Enter type"
                      class="add-edit-template-input neumorphism-pressed mb-4"
                      type="text"
                    /> -->
                  </div>
                </div>
                <div>
                  <div class="add-template-field-title mt-4">Topic</div>
                  <div class="notification-type-wrapper">
                    <v-combobox
                      clearable
                      class="topic-field neumorphism-pressed"
                      dense
                      placeholder="Select Topic"
                      :items="topic_list"
                      item-text="topic_name"
                      item-value="topic_name"
                      :loading="topic_loading"
                      v-model="topic"
                    ></v-combobox>
                    <!-- <select v-model="topic">
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select> -->
                  </div>
                </div>
                <div>
                  <div class="add-template-field-title mt-4">Body</div>
                  <div class="notification-type-wrapper">
                    <v-textarea
                      v-model="body"
                      row-height="2"
                      dense
                      placeholder="Enter message"
                      class="neumorphism-pressed topic-field px-8"
                      :rules="$rules.REQURIED_FILED"
                    ></v-textarea>
                    <!-- <textarea
                      v-model="body"
                      rows="5"
                      placeholder="Enter message"
                      class="add-edit-template-input neumorphism-pressed mb-4"
                      type="text"
                    /> -->
                  </div>
                </div>
                <div>
                  <div class="add-template-field-title mt-4">URL</div>
                  <div class="notification-type-wrapper">
                    <v-text-field
                      v-model="url"
                      class="neumorphism-pressed topic-field"
                      dense
                      placeholder="Enter URL"
                    ></v-text-field>
                    <!-- <input
                      v-model="url"
                      placeholder="Enter URL"
                      class="add-edit-template-input neumorphism-pressed mb-4"
                      type="text"
                    /> -->
                  </div>
                </div>

                <div>
                  <div class="add-template-field-title mt-4">Image</div>
                  <div
                    class="upload-image-wrapper neumorphism-pressed"
                    elevation="0"
                  >
                    <v-icon class="upload-icon" size="60"
                      >mdi-cloud-upload-outline</v-icon
                    >
                    <label for="files" class="upload-box">
                      <v-img
                        height="178px"
                        width="498px"
                        class="rounded-lg"
                        :src="image_preview"
                      >
                      </v-img>
                    </label>
                    <input
                      ref="image_field"
                      @change="saveImage()"
                      id="files"
                      style="visibility: hidden"
                      accept="image/*"
                      type="file"
                    />
                  </div>
                </div>
              </v-form>
            </div>
            <div
              class="dialog-footer d-flex align-center justify-end px-5 py-4"
            >
              <!-- <v-btn
                @click="openPreview()"
                elevation="0"
                class="add-btn mr-5"
                :loading="btn_loader"
                :disabled="btn_loader"
                rounded
              >
                Preview
              </v-btn> -->

              <button class="show-preview-btn mr-6" @click="openPreview()">
                <div class="show-preview-icon">
                  <span v-if="show_preview">
                    Hide
                  </span>
                  <span v-else>
                    Show
                  </span>
                  Preview
                </div>
              </button>

              <v-btn
                @click="addEditTemplate()"
                elevation="0"
                class="add-btn"
                :loading="btn_loader"
                :disabled="btn_loader"
                rounded
              >
                <span v-if="is_new_template">Add</span>
                <span v-else>Edit</span>
              </v-btn>
            </div>
          </v-col>
          <v-col v-if="show_preview" class="pa-0 ma-0 d-flex align-center">
            <NotificationPreview ref="notification_preview" />
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {
    NotificationPreview: () => import("./PreviewNotification.vue"),
  },
  data() {
    return {
      show_dialog: false,
      details_cols: 12,
      dialog_width: 600,
      show_preview: false,
      btn_loader: false,
      topic_loading: false,
      is_valid: true,
      topic_list: [],
      topic: null,
      temp_topic: "",
      title: "",
      type: "",
      url: "",
      body: "",
      image: null,
      image_preview: "",
      template_id: null,
      is_new_template: true,
    };
  },
  watch: {
    show_preview(new_value) {
      if (new_value) {
        this.details_cols = 8;
        this.dialog_width = 1100;
      } else {
        this.details_cols = 12;
        this.dialog_width = 600;
      }
    },
    title(new_value) {
      this.$store.dispatch("notification/setPreviewDetails", {
        title: new_value,
        body: this.body,
        image: this.image_preview,
      });
    },
    body(new_value) {
      this.$store.dispatch("notification/setPreviewDetails", {
        title: this.title,
        body: new_value,
        image: this.image_preview,
      });
    },
    image_preview(new_value) {
      this.$store.dispatch("notification/setPreviewDetails", {
        title: this.title,
        body: this.body,
        image: new_value,
      });
    },
  },
  methods: {
    async openAddEditTemplateDialog(item) {
      this.show_dialog = true;
      await this.getTopicList();
      if (item) {
        // console.log("item",item);
        this.is_new_template = false;
        this.template_id = item.id;
        this.temp_topic = item.topic ? item.topic : "";
        this.title = item.title;
        this.type = item.type;
        this.url = item.url;
        this.body = item.body;
        this.image_preview = item.image;
        // this.checkTopicId()
      } else {
        this.is_valid = true;
        this.is_new_template = true;
        // this.topic = null;
        // this.title = "";
        // this.type = "";
        // this.url = "";
        // this.body = "";
        // this.image = null;
        this.image_preview = "";
        this.$refs.add_edit_template_form.reset();
        this.is_valid = true;
        this.show_preview = true;
      }
    },
    saveImage() {
      this.image = this.$refs.image_field.files.item(0);
      this.image_preview = URL.createObjectURL(this.image);
      // console.log("save image", this.image, this.image_preview);
    },
    openPreview() {
      this.$store.dispatch("notification/setPreviewDetails", {
        title: this.title,
        body: this.body,
        image: this.image_preview,
      });
      this.show_preview = !this.show_preview;
    },
    checkTopicId() {
      if (!this.is_new_template && this.temp_topic) {
        // console.log("checkTopicId", this.topic_list);
        this.topic_list.forEach((item) => {
          if (item.topic_name == this.temp_topic) {
            // return item.id;
            this.topic = item.topic_name;
          }
          // this.topic.id == item.id
          // console.log("id found",item.id);
        });
      } else if (!this.temp_topic) {
        this.topic = null;
      }
    },
    getTopicList() {
      const self = this;
      self.topic_loading = true;
      const lookup_key = "";
      self.topic_list = [];
      // self.tabs_item = [];
      let params = {};
      const successHandler = (response) => {
        self.topic_list = response.data.result;
        self.topic_loading = false;
        this.checkTopicId();
      };
      const failureHandler = () => {
        self.topic_loading = false;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.FCM_TOPIC,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
    addEditTemplate() {
      if (this.$refs.add_edit_template_form.validate()) {
        const self = this;

        self.btn_loader = true;
        var form = new FormData();
        form.append("title", this.title);
        if (this.topic) {
          if (typeof this.topic != "string") {
            if (this.topic.id) {
              form.append("topic", this.topic.id);
            } else {
              form.append("topic", this.topic);
            }
          }
        }
        if (this.type) {
          form.append("type", this.type);
        }
        if (this.url) {
          form.append("url", this.url);
        }
        form.append("body", this.body);
        if (this.image) {
          form.append("image", this.image);
        }

        const successHandler = (response) => {
          // console.log("createTemplate", response);
          // if (response.data.success) {
          this.$refs.add_edit_template_form.reset();
          this.image_preview = "";
          self.$emit("update");
          self.show_dialog = false;
          self.btn_loader = false;
          // }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        const failureHandler = () => {
          self.btn_loader = false;
        };
        if (this.is_new_template) {
          self.POST_request(
            self,
            self.$urls.FCM_NOTIFICATION,
            form,
            successHandler,
            null,
            null,
            failureHandler,
            finallyHandler
          );
        } else {
          let lookup_key = "";
          let params = "";
          form.append("notification_id", this.template_id);
          self.PATCH_request(
            self,
            self.$urls.FCM_NOTIFICATION,
            lookup_key,
            params,
            form,
            successHandler,
            null,
            null,
            failureHandler,
            finallyHandler
          );
        }
      } else {
        this.showSnakeBar("error", "Please fill all details");
      }
    },
  },
};
</script>
<style scoped>
.add-template-title-section {
  border-bottom: 1px solid #ffa70257 !important;
}
.topic-title {
  font-size: 20px;
  font-weight: 800;
  /* color: #6d7073; */
  color: #616467;
}
.close-btn {
  padding: 5px;
  border-radius: 50%;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.close-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: #ffd641;
  background-image: linear-gradient(90deg, #ffd641 0%, #ff003c 100%);

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.close-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.add-btn {
  padding: 10px 20px;
  border-radius: 20px;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}

.add-template-table-wrapper {
  padding: 15px 30px 20px 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}
.add-template-field-title {
  color: #616467;
  font-weight: 700;
  padding: 0 0 5px 5px;
}
.add-btn {
  padding: 0 20px;
  color: #fff;
  font-weight: 800;
  background: hsla(197, 100%, 63%, 1) !important;

  background: linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  ) !important;

  background: -moz-linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  ) !important;

  background: -webkit-linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  ) !important;

  /* box-shadow: 0px 0px 12px hsla(294, 100%, 55%, 1) !important; */

  /* box-shadow: 6px 6px 12px #b5b5b5, -6px -6px 12px #ffffff; */
  box-shadow: 6px 6px 12px #7e7e7ea9, -6px -6px 6px #ffffff !important;
  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#40C9FF", endColorstr="#E81CFF", GradientType=1 ); */
}
.add-btn:hover {
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
/* .notification-type-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
} */
.upload-image-wrapper {
  display: grid;
  place-items: center;
  border: 1px dashed #5c5c5cc2;
  border-radius: 8px;
  height: 180px;
  width: 500px;
  position: relative;
}
.upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.show-preview-btn {
  padding: 7px 15px;
  border-radius: 20px;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.show-preview-icon {
  font-weight: 900;

  background-color: hsla(270, 94%, 25%, 1);

  background-image: linear-gradient(
    90deg,
    hsla(270, 94%, 25%, 1) 0%,
    hsla(158, 94%, 49%, 1) 100%
  );

  background-image: -moz-linear-gradient(
    90deg,
    hsla(270, 94%, 25%, 1) 0%,
    hsla(158, 94%, 49%, 1) 100%
  );

  background-image: -webkit-linear-gradient(
    90deg,
    hsla(270, 94%, 25%, 1) 0%,
    hsla(158, 94%, 49%, 1) 100%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.show-preview-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.add-edit-template-input {
  width: 100%;
  border-radius: 20px;
  padding: 10px 15px;
  border-color: #8bbbe557 !important;
}
.dialog-footer {
  border-top: 1px solid #ffa70257 !important;
  width: 100%;
  border-radius: 20px;
  padding: 10px 15px;
  border-color: #8bbbe557 !important;
}
.topic-field {
  /* background: blue; */
  /* width: 100%; */
  border-radius: 40px;
  padding: 10px 20px 0px 20px;
  border-color: #8bbbe557 !important;
}
</style>
