export const namespaced = true;

export const state = {
  analysis_data: [],
  donut_chart_data: [],
  growth_analysis_data: {},
  spline_data: {},
  is_loading: false,
  refresh_api: 0,
  preview_details: {
    title: "",
    body: "",
    image: "",
  },
};

export const mutations = {
  SET_ANALYSIS_DATA(state, obj) {
    state.analysis_data = obj;
  },
  SET_DONUT_CHART_DATA(state, obj) {
    state.donut_chart_data = obj;
  },
  SET_GROWTH_DATA(state, obj) {
    state.growth_analysis_data = obj;
  },
  SET_SPLINE_DATA(state, obj) {
    state.spline_data = obj;
  },
  SET_IS_LOADING(state, value) {
    state.is_loading = value;
  },
  SET_REFRESH_API(state) {
    state.is_loading += 1;
  },
  SET_PREVIEW_DETAILS(state, obj) {
    state.preview_details.title = obj.title;
    state.preview_details.body = obj.body;
    state.preview_details.image = obj.image;
  },
};

export const actions = {
  setAnalysisData({ commit }, items) {
    commit("SET_ANALYSIS_DATA", items);
  },
  setDonutData({ commit }, items) {
    commit("SET_DONUT_CHART_DATA", items);
  },
  setGrowthDate({ commit }, items) {
    commit("SET_GROWTH_DATA", items);
  },
  setSplineData({ commit }, items) {
    commit("SET_SPLINE_DATA", items);
  },
  setIsLoading({ commit }, item) {
    commit("SET_IS_LOADING", item);
  },
  setRefreshApi({ commit }) {
    commit("SET_IS_LOADING");
  },
  setPreviewDetails({ commit }, items) {
    commit("SET_PREVIEW_DETAILS", items);
  },
};
export const getters = {
  getAnalysisData: (state) => {
    return state.analysis_data;
  },
  getDonutData: (state) => {
    return state.donut_chart_data;
  },
  getGrowthDate: (state) => {
    return state.growth_analysis_data;
  },
  getSplineData: (state) => {
    return state.spline_data;
  },
  getIsLoading: (state) => {
    return state.is_loading;
  },
  getRefreshApi: (state) => {
    return state.refresh_api;
  },
  getPreviewDetails(state) {
    return state.preview_details;
  },
};
