<template>
  <div>
    <div class="card-style mx-0 mt-5">
      <DivLoader
        v-if="loading"
        :loading_type="'list-item-two-line'"
      ></DivLoader>

      <div v-else>
        <!-- <p class="font-bold fs-18 secodry-text-3 pb-3">
          {{ $lang.VIDEO_VERIFICATION }}
        </p> -->
        <!-- empty state  -->
        <p v-if="!verification" class="label-text text-center">
          {{ $messages.VERIFICATION_DETAILS_NOT_FOUND }}
        </p>

        <v-row v-if="verification">
          <TimeSlotEdit
            @edit-slot="getVideoVerificationDetail()"
            v-if="dialog.flag"
            :dialog="dialog"
          ></TimeSlotEdit>
          <ConfirmVerificationDialog
            v-if="confirm_dialog.flag"
            @update="getVideoVerificationDetail()"
            :confirm_dialog="confirm_dialog"
          ></ConfirmVerificationDialog>
          <v-col class="py-3" cols="12" md="4">
            <p class="secodry-text-2 d-flex align-center justfy-space-between">
              {{ $lang.TIME_SLOT }}
              <v-tooltip top open-delay color="grey" v-if="!is_verified">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="editSlot"
                    style="cursor: pointer"
                    class="mx-2"
                    color="#194E76"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Edit Date and Time</span>
              </v-tooltip>
            </p>
            <p class="secodry-text-3">{{ apt_data.slot }}</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="py-2 d-flex justify-end btn" cols="12" md="6">
            <span
              v-if="!is_verified"
              style="color: orange; font-size: 20px; font-weight: bolder"
            >
              Pending</span
            >
            <span
              v-else
              style="color: green; font-size: 20px; font-weight: bolder"
            >
              Verified</span
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

export default {
  name: "VideoVerificationDetail",
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
    TimeSlotEdit: () => import("@/components/customer/dialog/TimeSlotEdit.vue"),
    ConfirmVerificationDialog: () =>
      import("@/components/customer/dialog/ConfirmVerificationDialog.vue"),
  },
  computed:{
    ...mapGetters({
        store_loan_id: "new_customer/getLoanId",
      }),
  },
  watch:{
    store_loan_id(value){
      // this.getVideoVerificationDetail();
    }
  },
  data() {
    return {
      loading: false,
      id: "",
      dialog: {
        flag: false,
        data: {},
      },
      confirm_dialog: {
        flag: false,
        data: {},
      },

      apt_data: { slot: "", apt_date: "", id: "" },
      loan_id: "",
      name: "Video Verifcation",
      verification: false,
      is_verified: false,
    };
  },
  created() {
    this.getVideoVerificationDetail();
  },
  methods: {
    editSlot() {
      this.dialog.flag = true;
      this.dialog.data = this.apt_data;
      console.log(this.dialog, "DOALOPG");
    },
    /* fetching the customer list form server*/
    getVideoVerificationDetail() {
      const self = this;
      self.loading = true;
      let params = {
        customer_id: self.decrypt(this.$route.params.id),
      };
      const successHandler = (response) => {
        console.log(response.data, "CONSTOLE");
        if (response.data.success && response.data.is_available) {
          self.apt_data = response.data.apt_data;
          self.verification = response.data.is_available;
          self.is_verified = response.data.is_verified;
          self.loan_id = response.data.loan_id;
          console.log(self.loan_id, "LOAN ID");
        } else {
          self.verification = response.data.is_available;
        }
        console.log(self.verification, "VERIFICATION");
      };
      const finallyHandler = () => {
        self.loading = false;
      };
      self.request_GET(
        self,
        self.$urls.GET_VIDEO_VERIFICATION_DATA,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    confirmMarkAsDone() {
      this.confirm_dialog.flag = true;
      this.confirm_dialog.loan_id = this.loan_id;
    },
  },
};
</script>

<style scoped>
</style>