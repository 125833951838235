export const namespaced = true;

export const state = {
  customer_id: null,
  active_loan_id: null,

  precheck_status: "Precheck Applied",
  profile_status: null,
  loan_steps: null,
  date_range: [],
  display_daterange: "",
  current_city: "",
  state: "",
  page_number: 1,
  fd_display_date: null,
  follow_up_date: null,
  label: null,
  source: null,
  // for status date filter
  status_date_range: [],
  display_status_daterange: "",

  is_rent_address_proof_verified: false,
  is_bank_statement__verified: false,
  is_rent_address_proof_uploaded: false,
  is_bank_statement__uploaded: false,
  assigned_agent: "",
  agent_type: "",
  document_type: "",
  document_request_status: "",
  alloted_nbfc: "",
  perfios_status: "",
  nbfc_status_filter: "",
  // disbursed date range filter 
  disbursed_date_range: [],
  display_disbursed_daterange: "",
};

export const mutations = {
  SET_CUSTOMER_ID(state, value) {
    state.customer_id = value;
  },
  SET_ACTIVE_LOAN_ID(state, value) {
    state.active_loan_id = value;
  },
  SET_ADDRESS_PROOF_STATUS(state, value) {
    state.is_rent_address_proof_verified = value;
  },
  SET_BANK_STATEMENT_STATUS(state, value) {
    state.is_bank_statement__verified = value;
  },
  SET_BANK_STATEMENT_UPLOAD_STATUS(state, value) {
    state.is_bank_statement__uploaded = value;
  },
  SET_ADDRESS_PROOF_UPLOAD_STATUS(state, value) {
    state.is_rent_address_proof_uploaded = value;
  },
  SET_FILTER(
    state,
    {
      precheck_status,
      profile_status,
      loan_steps,
      fd_display_date,
      date_range,
      display_daterange,
      display_status_daterange,
      status_date_range,
      page_number,
      assigned_agent,
      agent_type,
      document_type,
      document_request_status,
      alloted_nbfc,
      perfios_status,
      nbfc_status_filter,
      disbursed_date_range,
      display_disbursed_daterange
    }
  ) {
    state.precheck_status = precheck_status;
    state.profile_status = profile_status;
    state.page_number = page_number;
    state.loan_steps = loan_steps;
    state.state = state;
    state.date_range = date_range;
    state.display_daterange = display_daterange;
    state.fd_display_date = fd_display_date;

    // for status date filter
    state.status_date_range = status_date_range;
    state.display_status_daterange = display_status_daterange;

    state.assigned_agent = assigned_agent;
    state.document_request_status = document_request_status;
    state.document_type = document_type;
    state.agent_type = agent_type;
    state.alloted_nbfc = alloted_nbfc;
    state.perfios_status = perfios_status;
    state.nbfc_status_filter = nbfc_status_filter;
    state.disbursed_date_range = disbursed_date_range
    state.display_disbursed_daterange = display_disbursed_daterange
  },
};

export const actions = {
  setCustomerId({ commit }, value) {
    commit("SET_CUSTOMER_ID", value);
  },
  setActiveLoanId({ commit }, value) {
    commit("SET_ACTIVE_LOAN_ID", value);
  },
  setBankStatementStatus({ commit }, value) {
    commit("SET_BANK_STATEMENT_STATUS", value);
  },
  setAddressProofStatus({ commit }, value) {
    commit("SET_ADDRESS_PROOF_STATUS", value);
  },
  setAddressProofUploadStatus({ commit }, value) {
    commit("SET_ADDRESS_PROOF_UPLOAD_STATUS", value);
  },
  setBankStatementUploadStatus({ commit }, value) {
    commit("SET_BANK_STATEMENT_UPLOAD_STATUS", value);
  },
  setFilters({ commit }, items) {
    commit("SET_FILTER", items);
  },
};

export const getters = {
  getCustomerId: (state) => {
    return state.customer_id;
  },
  getActiveLoanId: (state) => {
    return state.active_loan_id;
  },
  getFilters: (state) => {
    return state;
  },
  getRentAddressProofStatus: (state) => {
    return state.is_rent_address_proof_verified;
  },
  getBankStatementStatus: (state) => {
    return state.is_bank_statement__verified;
  },
  getRentAddressProofUploadStatus: (state) => {
    return state.is_rent_address_proof_uploaded;
  },
  getBankStatementUploadStatus: (state) => {
    return state.is_bank_statement__uploaded;
  },
};
