<style scoped>
.vertical-line {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.percentage-chip {
  font-size: 16px;
  background: #ffffff;
  color: #f18324;
  border: 1px solid #f18324;
  border-radius: 20px;
  padding: 0 15px;
}
.percentage-chip-value {
  font-weight: 800;
}
.liveliness-details {
  border-bottom: 1px solid rgba(58, 58, 58, 0.444);
}
.selfie-details-table {
  width: 100%;
}
.selfie-details-table,
td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.456);
  border-collapse: collapse;
  padding: 8px;
}

.selfie-details-table-paramenter {
  width: 65%;
}
</style>
<template>
  <div>
    <v-dialog v-model="dialog.flag" scrollable width="850">
      <v-card class="pb-0">
        <v-card-title class="primary_2 white--text" primary-title>
          <p class="text-h6">Compare Selfie</p>
          <!-- <v-spacer></v-spacer> -->
          <!-- <p
            v-if="dialog.liveness_data.is_passive_liveness"
            class="ml-5 percentage-chip"
          >
            <span class="percentage-chip-value">
              {{ face_match_percentage }}%
            </span>
            matched
          </p> -->
          <v-spacer></v-spacer>
          <v-icon large color="white" @click="dialog.flag = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="6" class="vertical-line">
              <div class="font-weight-bold text-body-1 mb-3">
                Photo Uploaded by Customer:
              </div>
              <div>
                <img
                  width="100%"
                  alt="user uploaded photo"
                  class="selfie-pic"
                  :src="dialog.user_uploaded_photo_url"
                />
              </div>
            </v-col>
            <v-col cols="6" class="">
              <div class="font-weight-bold text-body-1 mb-3">
                Aadhaar Photo from Signzy:
              </div>
              <div>
                <img
                  width="100%"
                  alt="aadhaar photo from signzy"
                  class="selfie-pic"
                  :src="dialog.aadhar_photo_url"
                />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <table class="selfie-details-table">
                <tr class="grey lighten-2 font-weight-bold">
                  <td>Status</td>
                  <td class="selfie-details-table-paramenter">Parameters</td>
                  <td class="d-flex justify-center">
                    Actions
                  </td>
                </tr>

                <tr>
                  <td>
                    <div v-if="dialog.liveness_data.is_passive_liveness">
                      <v-icon class="success--text">
                        mdi-check-circle-outline
                      </v-icon>
                    </div>
                    <div v-else>
                      <v-icon class="secondary_2--text">
                        mdi-alert-circle-outline
                      </v-icon>
                    </div>
                  </td>
                  <td>
                    <div>
                      <p class="font-weight-black">
                        Liveliness details-
                      </p>
                      <div class="pl-4">
                        <p>
                          Passive Live Probability:
                          <span class="font-weight-bold">
                            {{ dialog.liveness_data.passive_live_probability }}
                          </span>
                        </p>
                        <p>
                          Passive Live Score:
                          <span class="font-weight-bold">
                            {{ dialog.liveness_data.passive_live_score }}
                          </span>
                        </p>
                        <p>
                          Passive Live Quality:
                          <span class="font-weight-bold">
                            {{ dialog.liveness_data.passive_live_quality }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-end">
                      <v-btn
                        outlined
                        small
                        color="success"
                        :disabled="dialog.liveness_data.is_passive_liveness || dialog.selfie_status != 'Under Review'"
                        class="font-weight-bold ml-2"
                        @click="changeLiveliness(true)"
                        :loading="liveliness_btn_loader"
                      >
                        Apporve
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        color="error"
                        :disabled="dialog.liveness_data.is_passive_liveness || dialog.selfie_status != 'Under Review'"
                        class="font-weight-bold ml-2"
                        @click="changeLiveliness(false)"
                        :loading="liveliness_btn_loader"
                      >
                        Reject
                      </v-btn>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div
                      v-if="
                        dialog.liveness_data.is_passive_liveness &&
                          dialog.selfie_status == 'Success'
                      "
                    >
                      <v-icon class="success--text">
                        mdi-check-circle-outline
                      </v-icon>
                    </div>
                    <div v-else>
                      <v-icon class="secondary_2--text">
                        mdi-alert-circle-outline
                      </v-icon>
                    </div>
                  </td>
                  <td>
                    <p>
                      <span class="">Face Match Percentage:</span>
                      <span
                        v-if="face_match_percentage"
                        class="font-weight-bold text-h6"
                      >
                        {{ face_match_percentage }}%
                      </span>
                      <span v-else class="font-weight-bold text-body-2">
                        Pending
                      </span>
                    </p>
                  </td>
                  <td>
                    <!-- v-if="dialog.selfie_status == 'Under Review'" -->
                    <div class="d-flex justify-end pt-3 pb-4">
                      <div>
                        <v-btn
                          outlined
                          small
                          color="success"
                          class="font-weight-bold ml-2"
                          :disabled="
                            !dialog.liveness_data.is_passive_liveness ||
                              dialog.selfie_status != 'Under Review'
                          "
                          @click="updateKycStatus('selfie_status', 'Success')"
                        >
                          Apporve
                        </v-btn>
                        <v-btn
                          small
                          outlined
                          color="error"
                          class="font-weight-bold ml-2"
                          :disabled="
                            !dialog.liveness_data.is_passive_liveness ||
                              dialog.selfie_status != 'Under Review'
                          "
                          @click="updateKycStatus('selfie_status', 'Failed')"
                        >
                          Reject
                        </v-btn>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <!-- <v-card-actions
          v-if="dialog.selfie_status == 'Under Review'"
          class="d-flex justify-end pb-5 pr-7"
        >
          <div v-if="dialog.liveness_data.is_passive_liveness">
            <v-btn
              outlined
              small
              color="success"
              class="font-weight-bold ml-2"
              @click="updateKycStatus('selfie_status', 'Success')"
            >
              Apporve
            </v-btn>
            <v-btn
              small
              outlined
              color="error"
              class="font-weight-bold ml-2"
              @click="updateKycStatus('selfie_status', 'Failed')"
            >
              Reject
            </v-btn>
          </div>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      liveliness_btn_loader: false,
      face_match_btn_loader: false,
      face_match_percentage: this.dialog.liveness_data
        .image_face_match_percentage,
    };
  },
  methods: {
    // post updated selfie status
    updateKycStatus(document_type, action) {
      const self = this;
      // console.log("type", document_type, "action", action);
      let form = new FormData();
      form.append("customer_id", self.decrypt(self.$route.params.id));
      form.append("kyc_field", document_type);
      form.append("kyc_status", action);
      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("kyc update res",response);
          self.$emit("success");
          this.dialog.flag = false;
        }
      };
      const finallyHandler = () => {};
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_UPDATE_KYC_STATUS,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    // approve/reject liveliness status
    changeLiveliness(liveliness_status) {
      const self = this;
      
      self.liveliness_btn_loader = true
      let form = {
        customer_id: self.decrypt(self.$route.params.id),
        liveliness_status: liveliness_status,
      };

      const successHandler = (response) => {
        if (response.data.success) {
          if (liveliness_status == true) {
            self.getFaceMatchPercentage();
          } else {
            self.$emit("success");
            this.dialog.flag = false;
          }
        }
      };
      const finallyHandler = () => {
        self.liveliness_btn_loader = false
      };
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_CHANGE_LIVELINESS_STATUS,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    // get face match percentage
    getFaceMatchPercentage() {
      const self = this;

      self.face_match_btn_loader = true
      let form = new FormData();
      form.append("customer_id", self.decrypt(self.$route.params.id));
      const successHandler = (response) => {
        self.$emit("success");
        this.dialog.flag = false;
      };
      const finallyHandler = () => {
        self.face_match_btn_loader = false
      };
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_KYC_FACE_MATCH,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
