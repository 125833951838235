<template>
  <div>
    <v-dialog width="500" v-model="show_dialog" scrollable>
      <v-card>
        <v-card-title
          class="primary_2 d-flex justify-space-between font-weight-bold white--text"
        >
          User Track Details
          <div>
            <v-btn text small fab @click="closeDialog()">
              <v-icon large color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-data-table
            :headers="headers"
            :items="track_details"
            hide-default-footer
            :items-per-page="page_length"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      track_details: [],
      page_length: -1,
      headers: [
        {
          text: "Stage",
          value: "stage",
          sortable: false,
          class: "accent_2 black--text",
        },
        {
          text: "Date & Time",
          value: "stage_date",
          sortable: false,
          class: "accent_2 black--text",
        },
      ],
    };
  },
  methods: {
    async openUserTrackDetails() {
      this.show_dialog = true;
      await this.getTrackDetails();
    },
    closeDialog() {
      this.show_dialog = false;
    },
    getTrackDetails() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        // console.log("track",response);
        this.track_details = response.data.result;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_CUSTOMER_TRACK_DATA,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
