<template>
  <v-dialog v-model="dialog.flag" persistent width="500">
    <v-card class="py-5">
      <v-card-title class="headline flex-column lighten-2" primary-title>
        <v-icon color="warning" size="8rem">
          mdi-help-circle
        </v-icon>
        {{ $lang.ARE_YOU_SURE }}?
      </v-card-title>
      <v-card-text class="flex-column text-center mb-5 ">
        <p class="fs-16">
          Are you sure want to
          <b>{{ dialog.is_serviceable ? '"Open"' : '"Close"' }}</b> this area <br/> 
          pin code
          <b>{{ dialog.pin_code }}</b> for <b> {{ formateText(dialog.loan_status) }} </b>?
          <!-- {{ formateText(dialog.loan_status) }} -->
        </p>
      </v-card-text>

      <v-card-actions class="pb-5 px-5">
        <v-spacer></v-spacer>
        <v-btn
          class="px-5  py-4 "
          color="secondary_2"
          outlined
          rounded
          width="120"
          @click="cancel"
        >
          <b> {{ $lang.CANCEL }}</b>
        </v-btn>
        <v-btn
          :loading="btn_loading"
          class="px-7  py-2 "
          color="secondary_2"
          rounded
          width="120"
          @click="toggleServiceablePinCode"
        >
          <b style="color: #ffff"> {{ $lang.CONTINUE }}</b>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog"],
  data: () => ({
    btn_loading: false,
  }),
  created() {
    // this.initialize()
  },

  methods: {
    cancel() {
      this.dialog.flag = false;
      this.$emit("update");
    },
    formateText(value) {
      let loan_status = value
        .replace(/_/g, " ")
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      console.log("dialog", this.dialog);
      return loan_status;
    },
    /*toggle enable disable pincodes*/
    toggleServiceablePinCode() {
      const self = this;
      self.btn_loading = true;
      var form = new FormData();

      let is_serviceable;
      let loan_type = self.dialog.loan_status;
      let pincode_id = self.dialog.pincode_id;
      if (loan_type == "personal_loan") {
        form.append("is_serviceable", self.dialog.is_serviceable);
      } else if (loan_type == "business_loan") {
        form.append(
          "is_serviceable",
          self.dialog.is_serviceable_for_business_loan
        );
      }

      const successHandler = () => {
        // if (response.data.success) {
        self.dialog.flag = false;
        self.$emit("update");
        // }
      };
      const finallyHandler = () => {
        self.btn_loading = false;
      };
      this.POST_request(
        self,
        this.$urls.PIN_CODE_TOGGLE_2 + `${loan_type}/` + `${pincode_id}/`,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
