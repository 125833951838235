<template>
  <v-app>
    <div>
      <Drawer/>
    </div>
    <Navbar />
    <!-- <v-main app style="background-color: #f6f7fa"> -->
    <v-main app style="background-color: #eef5fb">
      <Body/>
    </v-main>

    <SnakeBar></SnakeBar>
    <LogoutAlertDialog :dialog="dialog" />
  </v-app>
</template>
<script>
export default {
  components: {
    Navbar: () => import("./Navbar.vue"),
    Drawer: () => import("./Drawer.vue"),
    Body: () => import("./Body.vue"),
    SnakeBar: () => import("@/components/ui/SnakeBar.vue"),
    LogoutAlertDialog: () => import("@/components/ui/LogoutAlertDialog"),
  },
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      logout_timer: null,
      warning_timer: null,
      setCountDown: null,
      dialog: {
        warning_zone: true,
        countDown: 0,
      },
    };
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimer();
  },
  destroyed() {
    this.events.forEach(function (event) {
      this.request_POST(self, this.$urls.LOGOUT_TEAMMATE);
      window.removeEventListener(event, this.resetTimer);
    }, this);
    this.resetTimer();
  },
  methods: {
    resetTimer() {
      // console.log("resetTimer")
      clearTimeout(this.warning_timer);
      clearTimeout(this.logout_timer);
      this.setTimer();
    },
    setTimer() {
      //console.log("setTimer")
      this.warning_timer = setTimeout(
        this.warningMessage,
        15 * 60 * 1000 - 15 * 1000
      );
      this.logout_timer = setTimeout(this.logoutUser, 15 * 60 * 1000);
      this.dialog.warning_zone = false;
      if (this.setCountDown) clearTimeout(this.setCountDown);
    },
    warningMessage() {
      // console.log("warningMessage")
      this.dialog.warning_zone = true;
      this.dialog.countDown = 15;
      if (this.setCountDown) clearTimeout(this.setCountDown);
      this.countDownTimer();
      //console.log("this.logout_timer=", this.logout_timer)
    },
    logoutUser() {
      localStorage.removeItem("active-tab");
      this.dialog.warning_zone = false;
      this.$router.push({ name: "login" });
      this.request_POST(self, this.$urls.LOGOUT_TEAMMATE);
    },
    countDownTimer() {
      if (this.dialog.countDown > 0) {
        this.setCountDown = setTimeout(() => {
          this.dialog.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
};
</script>