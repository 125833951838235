<style>
.border-0 {
  border-radius: 0px !important;
}
</style>
<template>
  <div>
    <v-data-table :disable-sort="true" :headers="headers" :items="installment_list" :items-per-page="$keys.PAGE_LENGTH"
      :loading="table_loading" class="border-a border-0 custome-header-bg" hide-default-footer show-expand single-expand>

      <template v-slot:[`item.key`]="{ item }">
        <p style="font-size: larger;font-weight: bold;">{{ item.number_of_emi }}</p>
        <p>{{ item.due_emi }}</p>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <p class="pt-2">{{ item.name }}</p>
        <p class="pt-1">{{ item.mobile }}</p>
        <p class="pb-2">{{ item.email }}</p>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0 ma-0" style="height:auto !important">
          <!--          {{ item }}-->

          <v-simple-table class="blue-grey lighten-5 inner-table px-10" dense>
            <template v-slot:default>
              <tr class="pa-2 border-b">
                <th class="text-left pa-1 pt-2 text-right" style="width: 55%">
                  EMI#
                </th>
                <th class="text-left pa-1 pt-2 text-center" style="width: 20%">
                  EMI Date
                </th>
                <th class="text-left pa-1 pt-2 pr-5  text-right" style="width: 10%">
                  EMI Amt
                </th>
              </tr>
              <tr v-for="(installment, i) in item.installment_list" :key="i" class="border-b">
                <td class="text-left pa-1 text-right">
                  #{{ installment.installment_number }}
                </td>
                <td class="text-left pa-1 text-center">
                  {{ installment.payment_due_date }}
                </td>
                <td class="text-left pa-1 pr-5 text-right">
                  {{ installment.installment_amount }}
                </td>
              </tr>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: ["installment_list", "table_loading"],
  components: {},
  data() {
    return {
      headers: [
        { text: "SN", value: "sno" },
        { text: "Loan ID", align: "start", value: "loan_id" },
        { text: "Customer info", value: "name" },
        { text: "Loan Amt", value: "loan_amount" },
        { text: "Agent", value: "agent_assigned" },
        { text: "EMI#", value: "key" },
        { text: "EMI Date", value: "payment_due_date" },
        { text: "EMI Amt", value: "monthly_emi" },
        { text: "", value: "action", width: 50, align: "end" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {},
};
</script>
