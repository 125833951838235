<style scoped>
.search-field-text {
  width: 400px;
}
.nav-wrapper {
  background: #eef5fb !important;
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
  border-bottom: 1px solid #a9d7ff57 !important;
  /* border: 1px solid #a9d7ff57 !important; */
}
.search-box-wrapper {
  position: relative;
}
.search-box-text {
  width: 350px;
  padding: 10px 15px 10px 50px;
  margin-top: 5px;
  border-radius: 20px;
  background: #e3edf7;
  height: fit-content !important;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff;
  border: 1px solid #a9d7ff57 !important;
}
.search-box-icon-wrapper {
  position: absolute;
  top: 16px;
  left: 15px;
  /* transform: translate(0, 50%); */
  padding: 2px;
  border-radius: 50%;
  background: #eef5fb !important;
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
  border: 1px solid #a9d7ff57 !important;
  display: grid;
  place-items: center;
  /* background: blue; */
}
.search-box-icon {
  /* background: blue; */
}
.search-instruction {
  color: red;
  font-weight: 600;
  font-size: 11px !important;
  display: flex;
  justify-content: end;
}
.back-btn {
  padding: 5px;
  border-radius: 50%;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.back-icon {
  font-weight: 900;
  background-color: #ffd641;
  background-image: linear-gradient(90deg, #ffd641 0%, #ff003c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.back-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  /* padding: 5px; */
  border-radius: 50%;
  /* background: #eef5fb !important; */
  /* border: 1px solid #a9d7ff57 !important; */

  /* box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important; */
}
.close-icon {
  font-weight: 900;
  background-color: #ffd641;
  background-image: linear-gradient(90deg, #ffd641 0%, #ff003c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
<template>
  <v-app-bar
    absolute
    app
    class="elevation-0  nav-wrapper"
    color="white"
    height="75"
  >
    <!--      scroll-target="#scrolling-techniques-7"-->

    <!-- <v-btn
      v-if="$route.meta.show_back_btn"
      fab
      small
      text
      @click="$router.go(-1)"
    >
      <v-icon color="#333333">mdi-chevron-left</v-icon>
    </v-btn> -->
    <button
      v-if="$route.meta.show_back_btn"
      class="back-btn ml-2 mr-4"
      @click="$router.go(-1)"
    >
      <v-icon class="back-icon">
        mdi-chevron-left
      </v-icon>
    </button>

    <v-toolbar-title
      :class="!$route.meta.show_back_btn ? 'ml-10' : ''"
      class="font-bold"
      style="color: #333333"
    >
      <span v-if="$route.meta.title == 'LAS'">Loan Against Securities</span>
      <span v-else>
        {{ $route.meta.title }}
      </span>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <div v-if="!$route.meta.show_back_btn" class="search-box-wrapper">
      <div class="search-box-icon-wrapper">
        <v-icon size="20" class="search-box-icon">mdi-magnify</v-icon>
      </div>
      <input
        v-model="display_search_text"
        v-on:keyup.enter="saveSearchText()"
        type="text"
        class="search-box-text"
      />
      <button
        v-if="display_search_text"
        class="close-btn"
        @click="clearSearchText()"
      >
        <v-icon class="close-icon">
          mdi-window-close
        </v-icon>
      </button>
      <div class="search-instruction">
        ** Press ENTER to search
      </div>
    </div>
    <!-- <div
      v-if="$route.meta.title != 'Customer details'"
      class="search-field-text pt-2"
    >
      <v-text-field
        v-model="display_search_text"
        dense
        hide-details
        label="Search"
        outlined
        prepend-inner-icon="mdi-magnify"
        rounded
        single-line
        v-on:keyup.enter="saveSearchText()"
      >
        <template slot="append">
          <v-icon v-if="display_search_text" @click="clearSearchText()"
            >mdi-close</v-icon
          >
        </template></v-text-field
      >
      <div class="red--text text-caption text-right">
        ** Press ENTER to search
      </div>
    </div> -->
  </v-app-bar>
</template>
<script>
export default {
  props: ["filter_status"],
  data() {
    return {
      display_search_text: "",
    };
  },

  // Clear search text on route change
  // watch: {
  // $route(to, from) {
  //   console.log("here");
  //   this.search_text = "";
  //   this.$store.dispatch("setSearchText", "");
  // },
  // },

  created() {
    // console.log(this.$route.meta.show_back_btn);
  },
  methods: {
    // applySearch(status) {
    //   this.$store.dispatch("setSearchText", this.search_text);
    // },
    saveSearchText() {
      this.$store.dispatch("setSearchText", this.display_search_text);
    },
    clearSearchText() {
      this.display_search_text = "";
      this.$store.dispatch("setSearchText", "");
    },
  },
  // getting and setting search value from store
  watch: {
    search_text(newValue) {
      this.display_search_text = newValue;
    },
  },
  computed: {
    search_text: {
      get() {
        return this.$store.getters["getSearchText"];
      },
      set(newValue) {
        return this.$store.dispatch("setSearchText", newValue);
      },
    },
  },
};
</script>
