<style scoped>
.border-0 {
  border-radius: 0px !important;
}

/* for call tag */
.pointer-cursor {
  cursor: pointer;
}

.bounce-reason {
  display: inline-block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.show-bounce-reason {
  width: auto;
  white-space: normal;
  overflow: auto;
  text-overflow: clip;
}
</style>
<template>
  <div>
    <v-data-table :disable-sort="true" :headers="headers" :items="installment_list" :items-per-page="$keys.PAGE_LENGTH"
      :loading="table_loading" class="border-a border-0 custome-header-bg" hide-default-footer>
      <template v-slot:body="{ items }">
        <tbody v-if="items.length == 0" class="text-center">
          <tr>
            <td :colspan="headers.length">
              <p class="text-center py-4 w-100">No data found</p>
            </td>
          </tr>
        </tbody>
        <tbody v-for="item in items" :key="item.loan_id">
          <tr>
            <td>{{ item.sno }}</td>
            <td>{{ item.loan_id }}</td>
            <td>
              <p class="pt-2">{{ item.name }}</p>
              <p class="pt-1">{{ item.mobile }}</p>
              <p class="pt-1">{{ item.email }}</p>

              <!-- call tag -->
              <p v-if="item.call_tag" class="ma-0 py-2 caption pointer-cursor" outlined color="primary"
                @click="clickedEditCallTag(item.id, item.call_tag)">
                <v-icon v-if="item.call_tag == 'Not Answered'" small class="orange--text">
                  mdi-label
                </v-icon>
                <v-icon v-else-if="item.call_tag == 'Wrong Number'" small class="red--text">
                  mdi-label
                </v-icon>
                <v-icon v-else-if="item.call_tag == 'Switched Off'" small class="black--text">
                  mdi-label
                </v-icon>
                <v-icon v-else-if="item.call_tag == 'Calling Success'" small class="green--text">
                  mdi-label
                </v-icon>
                <v-icon v-else-if="item.call_tag == 'Busy'" small class="yellow--text">
                  mdi-label
                </v-icon>
                <v-icon v-else small class="blue--text">
                  mdi-label
                </v-icon>
                {{ item.call_tag }}
              </p>
              <p v-else class="caption blue--text py-2 pointer-cursor" @click="clickedAddCallTag(item.id)">
                <v-icon small class="blue--text">mdi-plus</v-icon>
                <span class="text-decoration-underline">add call tag</span>
              </p>
            </td>
            <td>{{ item.loan_amount }}</td>
            <td>
              <p v-if="item.agent_assigned" class="cursor-pointer" @click="openAssignAgent(item)">
                {{ item.agent_assigned }}
              </p>
              <p v-else class="blue--text text-caption cursor-pointer" @click="openAssignAgent(item)">
                <v-icon x-small color="blue">mdi-pencil</v-icon>
                <span class="text-decoration-underline">Assign Agent</span>
              </p>
            </td>
            <td>
              <p style="font-weight: bold;font-size: larger;">{{ item.number_of_emi}}</p>
              <p>{{ item.due_emi }}</p>
            </td>
            <td style="min-width: 115px">{{ item.payment_due_date }}</td>
            <td></td>
            <td class="font-weight-bold">{{ item.monthly_emi }}</td>
            <td class="font-weight-bold">
              {{ item.bounce_charge }}
            </td>
            <td class="font-weight-bold">{{ item.late_fee }}</td>
            <td class="font-weight-bold">{{ item.over_due_charge }}</td>
            <td class="font-weight-bold">{{ item.total_already_paid }}</td>
            <td class="font-weight-bold">{{ item.total_payable_amount }}</td>
            <td>
              <p v-if="item.follow_up_date">
                {{ new Date(item.follow_up_date).toDateString().slice(4) }}
              </p>
              <p v-else>-</p>
            </td>
            <td>
              {{ item.last_charged_on }}
            </td>
            <td></td>

            <td class="text-center" style="width: 120px">
              <div class="mt-4">
                <v-btn depressed small outlined color="primary" :disabled="item.is_charge_initiated"
                  @click="openManualChargeForm(item)">
                  <span class="font-bold">{{ $lang.MANUAL_PAYMENT }}</span>
                </v-btn>
              </div>
              <div class="my-1 d-flex">
                <v-btn depressed small outlined color="primary" :disabled="item.is_charge_initiated" class="mx-2"
                  @click="openConfirmationDialog(item)">
                  <span class="font-bold">{{ $lang.CHARGE }}</span>
                </v-btn>
                <v-menu open-on-hover offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="primary--text py-3 ma-0" outlined x-small dark v-bind="attrs" v-on="on">
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="menu in menuOptions" :key="menu.id" @click="clickedMenu(menu, item)">
                      <v-list-item-title>
                        {{ menu.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="mb-2">
              </div>
            </td>
          </tr>
          <tr v-for="i in item.installment_list" :key="i.id" class="border-a">
            <td colspan="5"></td>
            <td>{{ i.installment_number }}</td>
            <td>{{ i.payment_due_date }}</td>
            <td>{{ i.overdue_days }}</td>
            <td>{{ i.installment_amount }}</td>
            <td>{{ i.bounce_charge }}</td>
            <td>{{ i.late_fee }}</td>
            <td>{{ i.over_due_charge }}</td>
            <td>{{ i.already_paid }}</td>
            <td>{{ i.total_payable_amount }}</td>
            <td></td>
            <!-- <td></td> -->
            <td>
              <p v-if="i.last_charged_on">{{ i.last_charged_on }}</p>
            </td>
            <td>
              <v-tooltip top max-width="200" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="bounce-reason">{{
                    i.bounce_reason
                  }}</span>
                </template>
                <span class="show-bounce-reason">{{ i.bounce_reason }}</span>
              </v-tooltip>
            </td>
            <td></td>
          </tr>
          <!--          for border bottom    -->
          <tr class="border-a">
            <td :colspan="headers.length" style="height: 0px"></td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!--  internal comment dialog-->
    <InternalCommentsDialog ref="internal_comment_dialog" :dialog="comment_dialog_data"></InternalCommentsDialog>
    <!--    manual payment dialog -->
    <ManualPaymentDialog :dialog="manual_payment_dialog_data"></ManualPaymentDialog>
    <!--  charge to the customer dialog   -->
    <ConfirmChargeDialog @update="$emit('reload_installments')" v-if="charge_dialog_data.flag"
      :dialog="charge_dialog_data"></ConfirmChargeDialog>
    <ManualChargeConfirmDialog @update="$emit('reload_installments')" v-if="manual_charge_dialog.flag"
      :manual_charge_dialog="manual_charge_dialog"></ManualChargeConfirmDialog>
    <!-- add/edit call tag dialog -->
    <EditCallTagDialog v-if="edit_call_tag_dialog_data.flag" @success="onSuccess" :dialog="edit_call_tag_dialog_data">
    </EditCallTagDialog>
    <!-- call dialog -->
    <CallDialog v-if="call_dialog_data.flag" :dialog="call_dialog_data"></CallDialog>
    <AssignAgentDialog v-if="assign_agent_dialog.flag" :dialog="assign_agent_dialog" @update="updateAgent" />
  </div>
</template>
<script>
export default {
  props: ["installment_list", "table_loading", "is_charge_initiated"],
  components: {
    ManualChargeConfirmDialog: () =>
      import("@/components/emi-analysis/ManualChargeConfirmDialog"),
    InternalCommentsDialog: () =>
      import("@/components/emi-analysis/InternalCommentsDialog"),
    ManualPaymentDialog: () =>
      import("@/components/emi-analysis/ManualPaymentDialog"),
    ConfirmChargeDialog: () =>
      import("@/components/emi-analysis/ConfirmChargeDialog"),
    EditCallTagDialog: () =>
      import("@/components/emi-analysis/EditCallTagDialog"),
    CallDialog: () => import("@/components/common/CallDialog"),
    AssignAgentDialog: () =>
      import("@/components/loan-applied/AssignAgentDialog"),
  },
  data() {
    return {
      headers: [
        { text: "SN" },
        { text: "Loan ID" },
        { text: "Customer Details", width: 170 },
        { text: "Loan Amt" },
        { text: "Agent", width: 120 },
        { text: "EMI#" },
        { text: "EMI Date" },
        { text: "Overdue days" },
        { text: "EMI Amt" },
        { text: "Bounce Charge" },
        { text: "Late Fee" },
        { text: "Over due Charge" },
        { text: "Already Paid" },
        { text: "Total Payable" },
        { text: "Follow up date" },
        { text: "Last Charged on" },
        { text: "Bounce Reason" },
        { text: "Actions", align: "center" },
      ],
      /*dialog data*/
      manual_payment_dialog_data: {
        flag: false,
        customer_id: "",
      },
      comment_dialog_data: {
        flag: false,
        customer_id: "",
      },
      charge_dialog_data: {
        flag: false,
        loan_id: "",
        payment_type: "",
        total_payable_amount: "",
      },
      manual_charge_dialog: {
        flag: false,
        loan_id: "",
        payment_type: "",
        total_payable_amount: "",
      },
      // add/edit call tag dialog data
      edit_call_tag_dialog_data: {
        flag: false,
        loan_id: "",
        call_tag: "",
      },
      // call dialog data
      call_dialog_data: {
        flag: false,
        loan_id: "",
        id: "",
        call_to: "",
      },
      // menu options
      menuOptions: [
        { id: 1, title: "Call Customer" },
        { id: 2, title: "Internal Comments" },
      ],
      assign_agent_dialog: {
        flag: false,
        loan_id: null,
        agent: "",
      },
      agent_list: [],
      agent_loading: false,
      agent_page: 1,
      agent_search: "",
      agent: "",
    };
  },

  methods: {
    openAssignAgent(value) {
      this.assign_agent_dialog.flag = true;
      this.assign_agent_dialog.loan_id = value.id;
      this.assign_agent_dialog.agent = value.agent_assigned;
    },
    updateAgent(value) {
      let loan_id = value.loan_id;
      let agent_assigned = value.agent_assigned;

      for (let i = 0; i < this.installment_list.length; i++) {
        if (this.installment_list[i].id == loan_id) {
          this.installment_list[i].agent_assigned = agent_assigned;
        }
      }
    },
    openManualChargeForm(item) {
      let permission_list =
        JSON.parse(sessionStorage.getItem("permission_list")) || [];
      if (permission_list.includes("Manual Payment")) {
        this.manual_charge_dialog.flag = true;
        // console.log(item, "TIEM");
        this.manual_charge_dialog.loan_id = item.id;
        this.manual_charge_dialog.total_payable_amount =
          item.total_payable_amount;
      } else {
        this.showSnakeBar("error", "Access Denied");
      }
    },
    /* Open comment dialog form */
    openConfirmationDialog(item) {
      this.charge_dialog_data.flag = true;
      this.charge_dialog_data.loan_id = item.id;
      this.charge_dialog_data.total_payable_amount = item.total_payable_amount;
      this.charge_dialog_data.payment_type = this.$keys.INSTALLMENT;
    },
    /* Open comment dialog form */
    openCommentDialog(item) {
      this.comment_dialog_data.installment_id = item.installment_id;
      this.comment_dialog_data.loan_id = item.id;
      this.comment_dialog_data.follow_up_date = item.follow_up_date;
      this.$refs.internal_comment_dialog.getCommentList(
        item.installment_id,
        item.id
      );
      this.$refs.internal_comment_dialog.formateDisplayDate(
        item.follow_up_date
      );
      this.comment_dialog_data.flag = true;
    },
    /*Open the edit dialog form*/
    openManalEntryForm(id) {
      this.manual_payment_dialog_data.flag = true;
    },
    openDeleteDialog(item) {
      this.delete_dialog.id = item.id;
      this.delete_dialog.name = item.name;
      this.delete_dialog.flag = true;
    },
    // Open the add call tag dialog
    clickedAddCallTag(id) {
      this.edit_call_tag_dialog_data.flag = true;
      this.edit_call_tag_dialog_data.loan_id = id;
      this.edit_call_tag_dialog_data.call_tag = "";
    },
    // Open the edit call tag dialog
    clickedEditCallTag(id, tag) {
      this.edit_call_tag_dialog_data.flag = true;
      this.edit_call_tag_dialog_data.loan_id = id;
      this.edit_call_tag_dialog_data.call_tag = tag;
    },
    // Open the call dialog
    clickedCall(item) {
      this.call_dialog_data.flag = true;
      this.call_dialog_data.id = item.id;
      this.call_dialog_data.loan_id = item.loan_id;
      this.call_dialog_data.call_to = item.name;
    },
    // update call tag UI data
    onSuccess(value) {
      let loan_id = value.loan_id;
      let call_tag = value.call_tag;

      for (let i = 0; i < this.installment_list.length; i++) {
        if (this.installment_list[i].id == loan_id) {
          this.installment_list[i].call_tag = call_tag;
        }
      }
    },
    // handle click on menu options
    clickedMenu(menu, item) {
      if (menu.title == "Call Customer") {
        this.clickedCall(item);
      } else if (menu.title == "Internal Comments") {
        this.openCommentDialog(item);
      }
    },
  },
};
</script>
