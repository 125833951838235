<template>
  <v-dialog v-model="dialog.flag" width="500px">
    <v-card>
      <DivLoader
        v-if="dialog.loading"
        :loading_type="
          'article, list-item-two-line, list-item-two-line, actions'
        "
      >
      </DivLoader>
      <div v-else>
        <v-form
          ref="add_comment_form"
          lazy-validation
          @submit.prevent="addComment()"
        >
          <v-card-title class="pl-7">
            <span class="headline">{{ $lang.ADD_COMMENT }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="pb-0" v-if="dialog.name">
                  <div>
                    Customer Name
                  </div>
                  <div class="font-weight-black">
                    {{ dialog.name }}
                  </div>
                </v-col>
                <v-col cols="6" class="pb-0" dialog.mobile>
                  <div>
                    Mobile
                  </div>
                  <div class="font-weight-black">
                    {{ dialog.mobile }}
                  </div>
                </v-col>
                <v-col cols="12" class="" v-if="dialog.email">
                  <div>
                    Email
                  </div>
                  <div class="font-weight-black">
                    {{ dialog.email }}
                  </div>
                </v-col>
                <v-col class="py-1" cols="12" md="12" sm="12">
                  <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="display_date"
                        v-on="on"
                        :label="$lang.FOLLOW_UP_DATE"
                        :placeholder="$lang.FOLLOW_UP_DATE"
                        class="filter"
                        dense
                        readonly
                      >
                        <template slot="append">
                          <!--                    <v-icon v-if="dialog.follow_up_date" @click="clearDateFilter()">mdi-close</v-icon>-->
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="dialog.follow_up_date" :min="today">
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="date_menu = false"
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" text @click="formateDisplayDate"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col class="py-1" cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="dialog.comment"
                    :label="$lang.COMMENT"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              class="px-5  py-4 "
              color="secondary_2"
              outlined
              rounded
              @click="dialog.flag = false"
            >
              <b> {{ $lang.CANCEL }}</b>
            </v-btn>
            <v-btn
              class="px-7  py-5 "
              :loading="save_btn_loader"
              color="secondary_2"
              rounded
              type="submit"
              @click="addComment"
            >
              <b style="color: #ffff"> {{ $lang.SAVE }}</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog"],
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
  },
  data: () => ({
    display_date: null,
    date_menu: false,
    today: new Date().toISOString().substr(0, 10),
    save_btn_loader: false,
  }),
  created() {
    // this.initialize()
  },
  methods: {
    /* reset the form */
    resetForm() {
      this.$refs.add_member_form.reset();
    },
    /* function for save comments */
    addComment() {
      const self = this;
      self.save_btn_loader = true;
      if (this.$refs.add_comment_form.validate() == false) return false;
      self.login_loading = true;
      var form = new FormData();
      form.append("user_id", self.dialog.id);
      form.append("comment", self.dialog.comment);
      if (self.dialog.follow_up_date)
        form.append("follow_up_date", self.dialog.follow_up_date);
      form.append("label", self.dialog.label);

      if (self.dialog.is_edit) form.append("id", self.id);

      const successHandler = (response) => {
        if (response.data.success) {
          if (self.dialog.is_edit)
            self.showSnakeBar("success", "Successfully updated");
          else self.showSnakeBar("success", "Successfully register");

          self.dialog.flag = false;
          self.$emit("update");
        }
      };
      const finallyHandler = () => {
        self.login_loading = false;
        self.save_btn_loader = false;
      };
      this.request_POST(
        self,
        this.$urls.ADD_COMMENT,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    /* function to format the date */
    formateDisplayDate() {
      this.display_date = this.display_date_formate(this.dialog.follow_up_date);
      this.date_menu = false;
      this.getCustomerList();
    },
  },
};
</script>
