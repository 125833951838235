const URLS = {
  /*base_url*/
  // BASE_URL: "https://api.cashpo.in/api/",
  // BASE_URL: "https://009b-13-234-124-191.in.ngrok.io/api/", // staging

  BASE_URL: process.env.VUE_APP_BASE_URL,

  /* user APIs */
  LOGIN: "users/login/",
  VERIFY_OTP: "users/verify-otp/",
  RESEND_OTP: "users/resend-otp/",

  GET_BASIC_INFO: "users/get/basic-info/",

  GET_EMPLOYMENT_DETAILS: "users/get/employment-details/",
  GET_BANK_DETAILS: "users/get/bank-details/",

  GET_DOCUMENT: "users/get/document/",
  UPLOAD_DOCUMENT: "users/upload/document/",

  REGISTER_TEAMMATE: "team/register/",
  TEAMMATE_LIST: "/team/get/teammate-list/",
  TEAMMATE_SESSION_LIST: "/team/get/teammate-session-list/",
  TEAMMATE_DETAILS: "/team/get/teammate-details/",
  TEAMMATE_SESSION_HISTORY_LIST: "/team/get/teammate/session-list/history/",

  DELETE_TEAMMATE: "/team/delete/teammate/",
  LOGOUT_TEAMMATE: "/team/logout/teammate/",
  LOAN_LIST: "/loan/list/",

  INSTALLMENT_LIST: "web-panel/installment/list/",
  PAYMENT_LIST: "web-panel/payment/list",
  GET_AADHAAR_DATA: "web-panel/get/aadhaar-data/",

  ADD_COMMENT: "web-panel/add/comments/",
  CITY_LIST: "web-panel/get/city-list/",
  CUSTOMER_LIST: "web-panel/get/customer-list/",
  CUSTOMER_LIST_CSV: "web-panel/get/customer-list-csv/",
  GET_VIDEO_VERIFICATION_DATA: "web-panel/get/video-verification/detail/",
  UPDATE_VIDEO_VERIFICATION_SLOT: "web-panel/update/video-verification-slot/",
  UNBLOCK_KYC: "web-panel/unblock-kyc/",
  VIDEO_VERIFICATION_DONE: "web-panel/mark/video-verification/done/",

  INTERNAL_COMMENTS_LIST: "web-panel/get/internal-comments-list/",
  ADD_INTERNAL_COMMENTS: "web-panel/add/internal-comments/",

  CHARGE_MANUALLY: "payment/charge/manually/",
  CHARGE_MANUALLY_OTHER: "payment/charge/manually/other",

  REVIEW_LIST: "web-panel/get/review-list/",
  LEDGER_LIST: "master/ledger/list",
  PIN_CODE_LIST: "master/list/pin-code/",
  PIN_CODE_ADD: "master/add/pin-code/",
  PIN_CODE_TOGGLE: "master/toggle/serviceable-pin-code/",
  PIN_CODE_TOGGLE_2: "master/toggle_pincodes/",
  PINCODE_LIST_2: "master/pincode_list/",
  UPLOAD_PAN: "web-panel/update/pan/",
  UPLOAD_SELFIE: "web-panel/update/selfie/",
  UPLOAD_AADHAAR: "web-panel/update/aadhaar/",
  GET_AGREEEMENT: "loan/get/agreement-pdf/",

  CALL_TAG_LIST: "web-panel/get/list_of_tags/",
  EDIT_CALL_TAG: "web-panel/add/call_tag/",

  GET_EMI_AMOUNT_DETAILS: "web-panel/get/total_charges_and_amount/",

  MANUAL_CALL: "web-panel/post/click_to_call/",

  NEW_CUSTOMER_LIST: "/web-panel/customer_list/",
  NEW_CUSTOMER_COUNT: "/web-panel/customer_count/",
  NEW_CUSTOMER_BASIC_INFO: "/web-panel/basic_info/",
  NEW_CUSTOMER_CHECK_ELIGIBLITY: "/web-panel/check_eligibility/",
  NEW_CUSTOMER_APPLICATION_FORM: "/web-panel/application_form/",
  NEW_CUSTOMER_KYC_DETAILS: "/web-panel/kyc_detail/",
  NEW_CUSTOMER_BANK_DETAILS: "/web-panel/bank_statement/",
  NEW_CUSTOMER_DOCUMENT_VERIFICAION: "/web-panel/document_verification/",
  NEW_CUSTOMER_UPDATE_KYC_STATUS: "/web-panel/kyc_detail/",
  NEW_CUSTOMER_CHANGE_LIVELINESS_STATUS: "/web-panel/change_liveliness_status/",
  NEW_CUSTOMER_KYC_FACE_MATCH: "/web-panel/kyc_face_match/",

  NEW_CUSTOMER_APPROVE_KYC_SELFIE: "/web-panel/approve_kyc_selfie/",

  EMI_DETAILS: "web-panel/emi_details/",
  CUSTOMER_PAYMENT_DETAILS: "web-panel/get_payment_breakup/",

  NEW_CUSTOMER_REQUEST_OTHER_DOCUMENT: "/web-panel/request_other_doc/",
  NEW_CUSTOMER_GET_OTHER_DOCUMENT: "/users/upload_other_doc/",

  NEW_CUSTOMER_UPDATE_ELIGIBILITY_CHECK: "/web-panel/update_profile/",
  ADD_ALTERNATE_NUMBER: "/web-panel/add_alternate_contact/",
  UPDATE_ALTERNATE_NUMBER: "/web-panel/edit_alternate_contact/",

  LOANS_LIST: "/web-panel/loan_details/",
  PRE_VERIFICATION_LIST: "/web-panel/precheck_details/",
  UPDATE_PRE_VERIFICATION_STATUS: "/web-panel/change_precheck_status/",
  GET_CREATE_NBFC_REJECTION_REASON:
    "/nbfc/get_or_create_nbfc_rejection_reason/",
  REJECT_LEAD_BY_NBFC: "/web-panel/reject_lead_by_nbfc/", // POST -- lookup_key: loan_id
  GET_NBFC_LIST: "/nbfc/nbfc_list/",
  ALLOT_NBFC: "/nbfc/allot_nbfc/",
  CHANGE_NBFC_LOAN_STATUS:"/web-panel/change_nbfc_loan_status/",

  TEAM_LIST: "/web-panel/team_list/",
  ASSIGN_AGENT: "/web-panel/assign_agent/",

  OVERDUE_DATE_LIST: "/master/get_date_bucket/",
  RESET_KYC: "/web-panel/reset_kyc/",

  REJECTION_REASON: "/master/get_rejection_list/",

  GET_NEW_CUSTOMER_COUNT: "/web-panel/customer_new_total_count/",
  GET_ALL_CONTACTS: "/web-panel/get_user_contacts/",

  GET_USER_LOAN_ID: "/users/get_user_loan_id/",
  GET_SIGN: "/loan/get/agreement-pdf/",
  UPDATE_SIGNATURE: "/users/update_agreement_sign/",
  GET_ALL_DOCUMENTS: "/web-panel/get_all_docs/",

  GET_CIBIL_INFO: "/cibil/get_cibil_record/",
  UPLOAD_BANK_STATEMENT: "/web-panel/web/upload_bank_statement/",
  UPLOAD_ADDRESS_PROOF: "/web-panel/web/upload_address_proof/",

  DISBURSE_LEAD_BY_NBFC:"/web-panel/disburse_lead_by_nbfc/",

  // business loan
  GET_BUSINESS_LOAN_LIST: "/businessLoan/admin/get_loan_list/",
  GET_BUSINESS_PROFILE_CARD_DETAILS:
    "/businessLoan/admin/profile_card_details/",
  GET_BUSINESS_LOAN_BASIC_DETAILS: "/businessLoan/admin/user_basic_details/",
  GET_BUSINESS_LOAN_PERSONAL_DETAILS:
    "/businessLoan/admin/user_personal_details/",
  GET_BUSINESS_LOAN_DOCUMENTS: "/businessLoan/admin/user_document_details/",
  GET_BUSINESS_LOAN_BUSINESS_DETAILS:
    "/businessLoan/admin/user_business_details/",
  ADD_FOLLOW_UP_DATE: "/businessLoan/admin/add_follow_up_date/",
  UPDATE_LOAN_STATUS: "/businessLoan/admin/change_loan_status/",
  GET_POST_DISBURSAL_DETAILS:
    "/businessLoan/admin/get_or_post_disbursal_details/",

  GET_BUSINESS_AMOUNT_LIST: "/master/get_business_amount_list/",
  UPDATE_BASIC_DETAIL: "/businessLoan/admin/edit_basic_detail/",
  UPDATE_PERSONAL_DETAIL: "/businessLoan/admin/edit_personal_detail/",
  UPDATE_BUSINESS_DETAIL: "/businessLoan/admin/edit_business_detail/",
  GET_PINCODE: "/businessLoan/get_pincode_list/",
  REMOVE_BLOCK_STATUS: "/businessLoan/admin/remove_block_state/",
  GET_CUSTOMER_TRACK_DATA: "/businessLoan/admin/get_track_record/",
  GET_BUSINESS_NBFC_LIST: "/businessLoan/admin/get_nbfc_list/",
  POST_KYC_DETAILS: "/businessLoan/upload_general_document/",
  POST_OTHER_DOCUMENTS: "/businessLoan/upload_others_document/",
  DELETE_OTHER_DOCUMENT: "/businessLoan/delete_document/",
  GET_SOLEPROPRIETORSHIP_OR_HUF_DATA:
    "/businessLoan/get_or_update__sole_properitorship_or_HUF/",
  GET_PARTNERSHIP_OR_LLP_DATA:
    "/businessLoan/get_or_update__partnership_or_LLP/",
  GET_COMPANY_DATA: "/businessLoan/get_or_update__company/",
  GENERATE_BUSINESS_LOAN_PDF: "businessLoan/admin/generate_profile_pdf/",

  // Finvu
  GET_FINVU_LIST: "/web-panel/finvu/get_finvu_list/",
  GET_FINVU_DATA_BY_ID: "/web-panel/finvu/get_finvu_data_by_id/",

  // mutual funds
  GET_LAS_LOAN_LIST: "/lasapp/admin/get_las_loan_list/",
  ADD_INTERNAL_COMMENT: "/lasapp/admin/add_internal_comment/",

  SET_LOAN_PERMISSIONS: "/web-panel/set_loan_permission/",
  BANK_NAME_LIST: "/users/v2/bank_list/",
  GET_LOAN_TRACK_DATA: "/web-panel/get_loan_tracking_data/",

  // Notification panel
  FCM_TOPIC: "/notification/fcm_topic/",
  FCM_NOTIFICATION: "/notification/fcm_notification/",
  SUBSCRIBE_USER: "/notification/subscribe_users/",
  GET_NOTIFICATION_HISTORY: "/notification/get_notification_history/",
  SEND_NOTIFICATION_ON_TOPIC: "/notification/send_notification_on_topic/",
  GET_ANALYSIS_DATA: "/notification/get_graph_report_data/",
  GET_USER_LIST: "/notification/get_user_list/",
  SEND_INDIVIDUALLY: "/notification/send_notification_individually/",
  TEST_NOTIFICATION: "/notification/send_test_notification/",

  RESET_REQUEST: "/web-panel/reset_doc_request/",

  PUSH_LEAD_TO_LTFS: "/web-panel/admin/force_push_lead_to_ltfs/",
  CHANGE_PERFUSE_STATUS: "/web-panel/admin/change_perfios_status/",
  LAS_UPDATE_STATUS: "/lasapp/admin/change_loan_status/",
  PUSH_LEAD_TO_FIBE: "/web-panel/admin/push_leads_to_fibe/",

  GET_USER_TRACK_RECORD: "/web-panel/admin/get_user_track_record/",
};
export default URLS;
