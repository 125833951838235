<template>
	<v-snackbar v-model="getSnakbar.flag" :color="getSnakbar.color"   right top >
		<b class="text--white">
			{{ getSnakbar.text }}
		</b>
		<template v-slot:action="{ attrs }">
			<v-btn dark  small  fab text
			@click="getSnakbar.flag = !getSnakbar.flag"
			class="float-right px-0"
			v-bind="attrs" >
			<!-- <b>close</b> -->
			<v-icon>mdi-close-circle-outline</v-icon>
		</v-btn>
	</template>
</v-snackbar>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';
export default {

	computed: mapGetters({
		getSnakbar: 'snackbar/getSnakbar'
	}),

}
</script>