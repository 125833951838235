<template>
  <div>
    <v-dialog
      scrollable
      v-model="basic_data.flag"
      width="600"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h5 white--text primary_2 d-flex justify-space-between align-center"
        >
          <div>Edit Basic Details</div>

          <div>
            <v-btn @click="basic_data.flag = false" icon
              ><v-icon color="white">mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="py-5">
          <v-form lazy-validation ref="basicDetailForm">
            <v-row no-gutters>
              <v-col cols="6">
                <v-text-field
                  outlined
                  placeholder="First Name"
                  label="First Name"
                  dense
                  v-model="basic_info.customer_first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  placeholder="Last Name"
                  label="Last Name"
                  dense
                  class="pl-2"
                  v-model="basic_info.customer_last_name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="basic_info.entity_type"
                  outlined
                  dense
                  label="Choose Entity Type"
                  placeholder="Choose Entity Type"
                  :items="entity_list"
                  item-value="value"
                  item-text="text"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  :items="business_age_list"
                  item-text="text"
                  item-value="text"
                  placeholder="Business Age"
                  label="Business Age"
                  v-model="basic_info.business_age"
                  class="pl-2"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  placeholder="Loan Amount"
                  label="Loan Amount"
                  dense
                  class="pl-2"
                  type="number"
                  v-model="basic_info.loan_amount"
                ></v-text-field
              ></v-col>
              <v-col cols="6" class="pl-2">
                <v-select
                  outlined
                  dense
                  :items="turnover_list"
                  item-text="amount"
                  label="Sale/Turnover (yearly)"
                  placeholder="Sale/Turnover (yearly)"
                  v-model="basic_info.yearly_turnover"
                  @focus="getTurnoverList()"
                  :loading="turnover_list_loading"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  :items="registration_list"
                  item-text="text"
                  item-value="value"
                  placeholder="Business Registration"
                  label="Business Registration"
                  v-model="basic_info.business_registration"
                  class="pl-2"
                >
                </v-select>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-select
                  outlined
                  dense
                  :items="purpose_list"
                  item-text="text"
                  item-value="value"
                  placeholder="Loan Purpose"
                  label="Loan Purpose"
                  v-model="basic_info.loan_purpose"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  :items="residence_list"
                  item-text="text"
                  item-value="value"
                  placeholder="Residence Type"
                  v-model="basic_info.residence_type"
                  class="pl-2"
                >
                </v-select>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-select
                  outlined
                  dense
                  :items="working_experience_years"
                  item-text="text"
                  label="Work Experience"
                  placeholder="Work Experience"
                  v-model="basic_info.work_experience"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  :items="monthly_sale_list"
                  item-text="amount"
                  label="Sales (Monthly)"
                  placeholder="Sale (Monthly)"
                  v-model="basic_info.monthly_sale"
                  class="pl-2"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="secondary_2 white--text"
            @click="updateBasicData()"
            :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["basic_detail"],
  data() {
    return {
      basic_data: this.basic_detail,
      loading: false,
      turnover_list_loading: false,
      entity_list: [
        {
          text: "Sole Proprietor",
          value: "sole_proprietor",
        },
        {
          text: "Partnership",
          value: "partnership",
        },
        {
          text: "Company",
          value: "company",
        },
        {
          text: "HUF",
          value: "HUF",
        },
        {
          text: "LLP",
          value: "LLP",
        },
      ],
      monthly_sale_list: [],
      business_age_list: [
        { text: "Still Planning" },
        { text: "0-1" },
        { text: "1-2" },
        { text: "2-5" },
        { text: "5-10" },
        { text: "more than 10 years" },
      ],
      turnover_list: [],
      working_experience_years: [
        { text: "0-1" },
        { text: "1-2" },
        { text: "2-5" },
        { text: "5-10" },
        { text: "more than 10 years" },
      ],
      registration_list: [
        {
          text: "GST",
          value: "gst_certificate",
        },
      ],
      purpose_list: [
        {
          text: "Expansion",
          value: "expansion",
        },
        {
          text: "Stock purchase",
          value: "stock_purchase",
        },
        {
          text: "Asset purchase",
          value: "asset_purchase",
        },
      ],
      residence_list: [
        {
          text: "Own",
          value: "Own",
        },
        {
          text: "Rented",
          value: "Rented",
        },
        {
          text: "Family Owned",
          value: "Family owned",
        },
      ],
      basic_info: {
        work_experience: "",
        residence_type: "",
        loan_purpose: "",
        business_registration: "",
        yearly_turnover: "",
        loan_amount: "",
        business_age: "",
        entity_type: "",
        customer_first_name: "",
        customer_last_name: "",
        monthly_sale: "",
      },
    };
  },
  watch: {
    "basic_detail.flag": function(value) {
      if (value) {
        this.getTurnoverList();
        this.getMontlySaleList();
        // console.log(this.basic_data);
        this.basic_info.work_experience = this.basic_data.basic_info.work_experience;
        this.basic_info.residence_type = this.basic_data.basic_info.residence;
        this.basic_info.loan_purpose = this.basic_data.basic_info.loan_purpose;
        this.basic_info.business_registration = this.basic_data.basic_info.bussines_registration;
        this.basic_info.loan_amount = this.basic_data.basic_info.loan_amount;
        this.basic_info.yearly_turnover = this.basic_data.basic_info.turnover;
        this.basic_info.business_age = this.basic_data.basic_info.bussines_age;
        this.basic_info.entity_type = this.basic_data.basic_info.entity_type;
        this.basic_info.customer_first_name = this.basic_data.basic_info.first_name;
        this.basic_info.customer_last_name = this.basic_data.basic_info.last_name;
        this.basic_info.monthly_sale = this.basic_data.basic_info.monthly_business_sales;
      }
    },
  },
  methods: {
    getTurnoverList() {
      const self = this;
      self.turnover_list_loading = true;
      let amount_type = "yearly/";

      const successHandler = (response) => {
        // console.log("basic", this.turnover_list);
        this.turnover_list = response.data.result;
        self.turnover_list_loading = false;
      };
      const finallyHandler = () => {
        self.turnover_list_loading = false;
      };
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_AMOUNT_LIST,
        amount_type,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getMontlySaleList() {
      // console.log("one");
      const self = this;
      let amount_type = "monthly/";
      const successHandler = (response) => {
        // console.log(response);
        this.monthly_sale_list = response.data.result;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_AMOUNT_LIST,
        amount_type,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    updateBasicData() {
      if (this.$refs.basicDetailForm.validate()) {
        const self = this;
        let look_up_key = this.basic_data.basic_info.customer_id;

        let formData = {};
        formData["entity_type"] = this.basic_info.entity_type;
        formData["bussines_age"] = this.basic_info.business_age;
        formData["turnover"] = this.basic_info.yearly_turnover;
        formData["loan_purpose"] = this.basic_info.loan_purpose;
        formData[
          "bussines_registration"
        ] = this.basic_info.business_registration;
        formData["residence"] = this.basic_info.residence_type;
        formData["work_experience"] = this.basic_info.work_experience;
        formData["loan_amount"] = this.basic_info.loan_amount;
        formData["first_name"] = this.basic_info.customer_first_name;
        formData["last_name"] = this.basic_info.customer_last_name;
        formData["monthly_business_sales"] = this.basic_info.monthly_sale;
        const successHandler = () => {
          self.basic_data.flag = false;
          self.$emit("reload");
        };
        self.request_PATCH(
          self,
          self.$urls.UPDATE_BASIC_DETAIL,
          `${look_up_key}/`,
          formData,
          successHandler,
          null,
          null,
          null
        );
      }
    },
  },
};
</script>
