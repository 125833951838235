<template>
  <div>
    <v-dialog v-model="show_dialog" width="1000">
      <div class="neumorphism-pressed">
        <div
          class="topics-list-title-section d-flex justify-space-between align-center py-4 px-8"
        >
          <div class="topic-title">
            Template List
          </div>
          <div>
            <button class="close-btn" @click="show_dialog = false">
              <v-icon class="close-icon">
                mdi-window-close
              </v-icon>
            </button>
          </div>
        </div>
        <div class="topics-list-table-wrapper">
          <div class="d-flex align-center justify-end mb-4">
            <button class="add-btn" @click="openAddEditTemplate()">
              <div class="add-icon">
                Add
              </div>
            </button>
          </div>
          <v-data-table
            class="neumorphism-flat mt-2"
            :items="topics_list"
            :headers="template_headers"
            :loading="table_loading"
          >
            <template v-slot:[`item.body`]="{ item }">
              <v-tooltip bottom max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <div class="body-text-wrapper" v-bind="attrs" v-on="on">
                    {{ item.body }}
                  </div>
                </template>
                <div>{{ item.body }}</div>
              </v-tooltip>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <div class="template-image pa-3">
                <img :src="item.image" />
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <button class="close-btn" @click="openAddEditTemplate(item)">
                  <v-icon class="edit-icon">
                    mdi-text-box-edit-outline
                  </v-icon>
                </button>
                <button
                  class="close-btn ml-4"
                  @click="openDeleteConfirmationDialog(item)"
                >
                  <v-icon class="delete-icon">
                    mdi-delete-empty-outline
                  </v-icon>
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-dialog>
    <AddEditTemplate
      ref="add_edit_template_dialog"
      @update="getTemplatesList"
    />
    <DeleteConfirmationDialog
      ref="delete_template_comfirmation_dialog"
      @update="getTemplatesList"
    />
  </div>
</template>
<script>
export default {
  components: {
    AddEditTemplate: () =>
      import("@/components/notifications/dialogs/AddEditTemplate.vue"),
    DeleteConfirmationDialog: () =>
      import("@/components/notifications/dialogs/DeleteTopicTemplate.vue"),
  },
  data() {
    return {
      show_dialog: false,
      table_loading: false,
      topics_list: [],
      template_headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          // class: "primary_2 white--text",
        },
        {
          text: "Created On",
          align: "start",
          sortable: false,
          value: "created",
          // class: "primary_2 white--text",
        },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
          // class: "primary_2 white--text",
        },
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "image",
          // class: "primary_2 white--text",
        },
        {
          text: "Topic",
          align: "start",
          sortable: false,
          value: "topic",
          // class: "primary_2 white--text",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type",
          // class: "primary_2 white--text",
        },
        {
          text: "Body",
          align: "start",
          sortable: false,
          value: "body",
          // class: "primary_2 white--text",
        },
        {
          text: "URL",
          align: "start",
          sortable: false,
          value: "url",
          width: 50,
          // class: "primary_2 white--text",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
          // class: "primary_2 white--text",
        },
      ],
    };
  },
  methods: {
    async openTemplateListDialog() {
      this.show_dialog = true;
      await this.getTemplatesList();
    },
    getTemplatesList() {
      const self = this;
      self.table_loading = true;
      const lookup_key = "";
      self.topics_list = [];
      // self.tabs_item = [];
      let params = {};
      const successHandler = (response) => {
        // console.log("getTemplatesList", response);
        self.topics_list = response.data.result;
        self.table_loading = false;
      };
      const failureHandler = () => {
        self.table_loading = false;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.FCM_NOTIFICATION,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
    openAddEditTemplate(item) {
      this.$refs.add_edit_template_dialog.openAddEditTemplateDialog(item);
    },
    openDeleteConfirmationDialog(value) {
      this.$refs.delete_template_comfirmation_dialog.openDeleteConfirmation(
        "template",
        value
      );
    },
  },
};
</script>
<style scoped>
.topics-list-title-section {
  border-bottom: 1px solid #ffa70257 !important;
}
.topic-title {
  font-size: 20px;
  font-weight: 800;
  /* color: #6d7073; */
  color: #616467;
}
.close-btn {
  padding: 5px;
  border-radius: 50%;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.close-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: #ffd641;
  background-image: linear-gradient(90deg, #ffd641 0%, #ff003c 100%);

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.close-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.add-btn {
  padding: 7px 15px;
  border-radius: 20px;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.add-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: hsla(197, 100%, 63%, 1);
  background-image: linear-gradient(
    90deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.add-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.topics-list-table-wrapper {
  padding: 15px 30px 40px 30px;
}
.template-image img {
  width: 150px;
}
.body-text-wrapper {
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.edit-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: hsla(217, 100%, 50%, 1);
  background-image: linear-gradient(
    90deg,
    hsla(217, 100%, 50%, 1) 0%,
    hsla(186, 100%, 69%, 1) 100%
  );
  background-image: -moz-linear-gradient(
    90deg,
    hsla(217, 100%, 50%, 1) 0%,
    hsla(186, 100%, 69%, 1) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    hsla(217, 100%, 50%, 1) 0%,
    hsla(186, 100%, 69%, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.delete-icon {
  /* font-size: 72px; */
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  background-color: hsla(4, 84%, 49%, 1);

  background-image: linear-gradient(
    90deg,
    hsla(4, 84%, 49%, 1) 0%,
    hsla(37, 89%, 52%, 1) 100%
  );

  background-image: -moz-linear-gradient(
    90deg,
    hsla(4, 84%, 49%, 1) 0%,
    hsla(37, 89%, 52%, 1) 100%
  );

  background-image: -webkit-linear-gradient(
    90deg,
    hsla(4, 84%, 49%, 1) 0%,
    hsla(37, 89%, 52%, 1) 100%
  );
}
.edit-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
</style>
