<template>
  <div>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog.flag" width="500">
          <v-card>
            <v-card-title class="text-h6 primary white--text">
              Call Confirmation
              <v-spacer></v-spacer>
              <v-btn text dark @click="dialog.flag = false">
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pt-5 text-center text-body-1">
              <p>
                Are you sure you want to call
                <span class="font-weight-black"> {{ dialog.call_to }}</span
                >?
              </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="success rounded-pill px-8" @click="postCall()">
                Call
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {};
  },
  methods: {
    // post customer ID 
    postCall() {
      const self = this;

      var form = new FormData();
      form.append("loan_id", this.dialog.id);

      const successHandler = (response) => {
        console.log(response);
        if (response.data.success) {
          self.dialog.flag = false;
        }
      };
      self.request_POST(
        self,
        self.$urls.MANUAL_CALL,
        form,
        successHandler,
        null,
        null,
        null
      );
    },
  },
};
</script>
