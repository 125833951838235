<template>
  <div>
    <v-dialog v-model="dialog.flag" width="600" scrollable>
      <v-card min-height="400">
        <v-card-title class="primary_2 white--text">
          <div>All Documents</div>
          <v-spacer></v-spacer>
          <div class="text-right">
            <v-icon large color="white" @click="closeModal">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-data-table
            class="custom-data-table"
            :headers="headers"
            hide-default-header
            :items="all_documents"
            :items-per-page="5"
            :hide-default-footer="hide_table_footer"
          >
            <template #[`item.actions`]="{ item }">
              <td class="d-flex justify-end">
                <v-icon
                  color="deep-purple darken-4"
                  @click="openDocument(item.doc_PDF_URL)"
                  >mdi-eye-outline</v-icon
                >
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
export default {
  name: "AllDocumentModal",
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "Documents Name",
          align: "start",
          value: "doc_name",
          sortable: false,
        },
        {
          text: "Documents Url",
          value: "actions",
          sortable: false,
        },
      ],
      all_documents: [],
      dialog: {
        flag: false,
      },
    };
  },
  mounted() {
    this.customer_id = this.decrypt(this.$route.params.id);
  },
  computed: {
    hide_table_footer(){
      let page_length = this.all_documents ? this.all_documents.length : 0;
      if(page_length<5){
        return true
      }
      else{
        return false
      }
    }
  },
  methods: {
    viewAllDocuments() {
      this.dialog.flag = true;
      const self = this;
      let params = {
        customer_id: this.customer_id,
      };
      const successHandler = (res) => {
        this.all_documents = res.data.result;
      };
      self.request_GET(
        self,
        self.$urls.GET_ALL_DOCUMENTS,
        params,
        successHandler
      );
    },
    closeModal() {
      this.dialog.flag = false;
      this.all_documents = [];
    },
    openDocument(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped>
.v-data-table>>>.v-data-footer {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 0.75rem !important;
  padding: 0 8px !important;
}

.subtitle {
  background-color: #fcf7ea;
  font-weight: 800;
  font-size: initial;
}

.theme--light.v-card .v-card__subtitle {
  color: black;
}
/* #app > div:nth-child(5) > div > div > div.v-card__text.pa-4 > div > div.v-data-footer{
  position: absolute !important;
  bottom: 0 !important;
} */

</style>
