<template>
  <div>
    <div class="d-flex">
      <div class="date-div">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          class="date-text-field"
          max-width="300"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Select date"
              outlined
              dense
              color="#194E76"
              class="date-text-field mx-3"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
        </v-menu>
      </div>
      <v-btn color="#194E76" @click="getTeammateList" outlined dense
        >Filter</v-btn
      >
      <div class="mx-2 date-div">
        <v-select
          outlined
          dense
          color="#194E76"
          @change="getTeammateList"
          v-model="type"
          :items="log_values"
          clearable
        ></v-select>
      </div>
    </div>
    <v-data-table
      :disable-sort="true"
      :headers="headers"
      :items="user_data_list"
      :items-per-page="$keys.PAGE_LENGTH"
      :loading="table_loading"
      class="border-a border-0 custome-header-bg"
      hide-default-footer
    >
    </v-data-table>
    <v-pagination
      v-model="page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getTeammateList"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  name: "session_details",
  data() {
    return {
      table_loading: false,
      page_number: 1,
      total_page_count: 2,
      user_data_list: [],
      date: null,
      menu2: false,
      type: "",
      log_values: [this.$keys.LOGOUT, this.$keys.LOGIN],
      headers: [
        { text: "ID", value: "id" },
        { text: "Type", value: "log_type" },
        { text: "Action Time", value: "action_time" },
      ],
      /*dialog data*/
      dialog_data: {
        flag: false,
        title: "",
        is_edit: false,
        loading: false,
      },
      delete_dialog: {
        flag: false,
        id: "",
        name: "",
      },
    };
  },
  methods: {
    getTeammateList() {
      const self = this;
      self.table_loading = true;
      self.toggle_loading();
      let params = {
        page_number: this.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        user_id: this.$route.params.id,
        date: this.date,
        log_type: this.type,
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          console.log(response);
          self.user_data_list = response.data.user_data;
          self.total_page_count = response.data.total_page_count;
        }
        self.table_loading = false;
      };
      const finallyHandler = () => {
        self.table_loading = false;
        // self.toggle_loading()
      };

      self.request_GET(
        self,
        self.$urls.TEAMMATE_SESSION_HISTORY_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
  created() {
    this.getTeammateList();
  },
};
</script>

<style scoped>
</style>