<style scoped></style>
<template>
  <v-dialog width="450" v-model="dialog.flag">
    <v-card>
      <v-card-title
        class="text-h6 primary_2 white--text d-flex justify-space-between align-center"
      >
        <p>Assign Agent</p>
        <p>
          <v-icon large color="white" @click="dialog.flag = false">
            mdi-close
          </v-icon>
        </p>
      </v-card-title>

      <!-- <v-card-text v-if="skeletonLoader">
        <v-skeleton-loader
          class="elevation-0 pt-8 pb-0"
          v-bind="attrs"
          type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, actions"
        ></v-skeleton-loader>
      </v-card-text> -->

      <!-- <v-card-text v-else class="pb-0"> -->
      <v-card-text class="pb-0">
        <v-container class="px-0 pb-0" fluid>
          <v-combobox
            v-model="dialog.agent"
            :items="agent_list"
            :loading="agent_loading"
            :search-input.sync="agent_search"
            clearable
            dense
            filled
            hide-details
            item-text="name"
            item-value="id"
            label="Select agent"
            outlined
            placeholder="Select agent"
            single-line
            @focus="getAgentList"
            @keyup="getAgentList"
          >
            <!-- @change="getCustomerList" -->
            <template v-slot:append-item>
              <div v-intersect="endIntersect"></div>
            </template>
          </v-combobox>
        </v-container>
        <div class="d-flex justify-end">
          <v-btn
            class="white--text mt-0 mb-5 mt-3 mr-2 secondary_2 px-6 rounded-pill"
            @click="assignAgent()"
            :loading="btn_loader"
            :disabled="btn_loader"
          >
            <p>Assign</p>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog"],
  data() {
    return {
      skeletonLoader: true,
      agent_list: [],
      agent_loading: false,
      agent_page: 1,
      agent_search: "",
      btn_loader: false,
    };
  },
  mounted() {
    this.getAgentList();
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.agent_page++;
        this.getAgentList(true);
      }
    },
    getAgentList(append_agent = false) {
      const self = this;
      self.agent_loading = true;
      let params = {
        search_query: this.agent_search,
        page_number: this.agent_page,
      };
      console.log(params);
      const successHandler = (response) => {
        // console.log("Team list",response);
        self.agent_list = response.data.result.user_data;
        // console.log("Assigned Team list",self.agent_list);
        //   if (response.data.success) {
        //     if (append_agent)
        //       self.agent_list = self.agent_list.concat(response.data.agent_list);
        //     else self.agent_list = response.data.agent_list;
        //   }
      };
      const finallyHandler = () => {
        self.agent_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.TEAM_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },

    assignAgent() {
      if (this.dialog.agent) {
        const self = this;

        self.btn_loader = true;
        var form = new FormData();
        form.append("loan_id", this.dialog.loan_id);
        form.append("agent_id", this.dialog.agent.id);

        const successHandler = (response) => {
          //   console.log(response);
          // if (response.data.success) {
          self.dialog.flag = false;
          self.$emit("update", {
            loan_id: this.dialog.loan_id,
            agent_assigned: this.dialog.agent.name,
          });
          self.btn_loader = false;
          // }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        const failureHandler = () => {
          self.btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.ASSIGN_AGENT,
          form,
          successHandler,
          null,
          null,
          failureHandler,
          finallyHandler
        );
      } else {
        this.showSnakeBar("error", "Select Agent before assigning");
      }
    },
  },
};
</script>
