<template>
  <div>
    <v-dialog v-model="show_dialog" width="600">
      <v-card>
        <v-card-title class="primary_2 text-h6 white--text py-2">
          Update NBFC Loan Status
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog()">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-form ref="move_to_disbursed_form">
            <v-row class="ma-0">
              <v-col cols="12" class="pb-0">
                <v-select
                outlined
                  v-model="status"
                  :items="status_list"
                  placeholder="Select status"
                  dense
                  :rules="$rules.REQURIED_FILED"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end pt-0 pb-4">
          <v-btn
            color="success"
            rounded
            class="mx-1 white--text px-4 font-weight-bold"
            @click="updateNbfcLoanStatus()"
            :loading="btn_loader"
            depressed
            :disabled="btn_loader"
          >
            <!-- <v-icon>mdi-cancel</v-icon> -->
            Move
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      btn_loader: false,
      loan_id: "",
      status: "",
      status_list: ["Pending", "Deviated", "Sanctioned", "Approved"],
    };
  },
  computed: {},
  methods: {
    async openUpdateNbfcLoanStatus(value) {
      this.show_dialog = true;
      this.loan_id = value.id;
      this.status = value.nbfc_loan_status;
    },
    closeDialog() {
      this.btn_loader = false;
      this.loan_id = "";
      this.status = "";
      this.$refs.move_to_disbursed_form.reset();
      this.show_dialog = false;
    },
    updateNbfcLoanStatus() {
      if (this.$refs.move_to_disbursed_form.validate()) {
        const self = this;
        self.btn_loader = true;
        let form = {
          loan_id: self.loan_id,
          status: self.status,
        };
        self.btn_loader = true;

        const successHandler = () => {
          this.$emit("update");
          self.closeDialog();
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.CHANGE_NBFC_LOAN_STATUS,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
  },
};
</script>
<style scoped></style>
