<template>
  <div>
    <v-row class="neumorphism-flat pa-5 count-analysis-card">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="count-analysis-card-title py-0">
            Template List:</v-col
          >
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between py-0"
          >
            <div class="topic-count">
              {{ totalTemplateCount.total_template_count }}
            </div>
            <div>
              <button @click="openTemplateList()" class="list-btn">
                <v-icon size="30" class="icon-color">
                  mdi-view-list
                </v-icon>
              </button>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <TemplateListDialog ref="template_list_dialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TemplateListDialog: () =>
      import("@/components/notifications/dialogs/TemplatesList.vue"),
  },
  data() {
    return {};
  },
  methods: {
    openTemplateList() {
      this.$refs.template_list_dialog.openTemplateListDialog();
    },
  },
  computed: {
    ...mapGetters({
      totalTemplateCount: "notification/getAnalysisData",
    }),
  },
};
</script>

<style scoped>
.count-analysis-card {
  border-radius: 15px;
  height: inherit;
}
.count-analysis-card-title {
  font-size: 20px;
  font-weight: 800;
  /* color: #6d7073; */
  color: #616467;
}
.topic-count {
  font-size: 50px;
  font-weight: 800;
  color: #616467;
}
.list-btn {
  padding: 10px;
  border-radius: 50%;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.list-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.icon-color {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: #fad961;
  background-image: linear-gradient(90deg, #fad961 0%, #f76b1c 100%);

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
