<template>
  <div>
    <div class="d-flex">
      <div class="date-div">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          class="date-text-field"
          max-width="300"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Select date"
              outlined
              dense
              color="#194E76"
              class="date-text-field mx-3"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
        </v-menu>
      </div>
      <v-btn color="#194E76" @click="getTeammateList" outlined dense
        >Filter</v-btn
      >
    </div>
    <v-data-table
      :disable-sort="true"
      :headers="headers"
      :items="user_data_list"
      :items-per-page="$keys.PAGE_LENGTH"
      :loading="table_loading"
      class="border-a border-0 custome-header-bg"
      hide-default-footer
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          class="mr-2 btn"
          color="#fff"
          small
          text
          @click="viewLog(item.id)"
        >
          View
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getTeammateList"
    ></v-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "session_history",
  data() {
    return {
      table_loading: false,
      page_number: 1,
      total_page_count: 2,
      user_data_list: [],
      date: null,
      menu2: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Mobile No.", value: "mobile" },
        { text: "Login", value: "login" },
        { text: "Logout", value: "logout" },
        { text: "Total hours", value: "total_hrs" },
        { text: "", value: "action", align: "end" },
      ],
      /*dialog data*/
      dialog_data: {
        flag: false,
        title: "",
        is_edit: false,
        loading: false,
      },
      delete_dialog: {
        flag: false,
        id: "",
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
    }),
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      if (new_search_query != old_search_query) {
        this.getTeammateList();
      }
    },
  },
  created() {
    console.log(this.$route.meta.show_back_btn);
    this.getTeammateList();
  },
  methods: {
    /* fetching the customer list form server*/
    getTeammateList() {
      const self = this;
      self.table_loading = true;
      self.toggle_loading();
      let params = {
        page_number: this.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        date: this.date,
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          self.user_data_list = response.data.user_data;
          self.total_page_count = response.data.total_page_count;
        }
      };
      const finallyHandler = () => {
        self.table_loading = false;
        // self.toggle_loading()
      };

      self.request_GET(
        self,
        self.$urls.TEAMMATE_SESSION_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    /* view log detail page */
    viewLog(id) {
      this.$router.push(`session/detail/${id}`);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.btn {
  background-color: #f28321;
}
.date-text-field {
  width: 300px !important;
}
.date-div {
  width: 30%;
}
</style>