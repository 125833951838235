<style scoped>
.customer-tab-wrapper {
  min-width: 100px;
}

.active-tab {
  background-color: #358cff;
  color: #fff !important;
}

.customer-tab_number {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}

.customer-tab_text {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

#task-v-chip .v-chip {
  font-size: 12px;
  font-weight: 800;
}

.kyc-task-v-chip .v-chip {
  font-size: 12px;
  font-weight: 800;
}

/* task chip styling */
#task-v-chip .v-chip.Employment {
  background: #faf9e0 !important;
  color: #b5ae00 !important;
}

#task-v-chip .v-chip.General {
  background: #e7f7fb !important;
  color: #31bee8 !important;
}

#task-v-chip .v-chip.Stage1 {
  background: #f8eefe !important;
  color: #b554f1 !important;
}

#task-v-chip .v-chip.Stage2 {
  background: #e7eff6 !important;
  color: #337ab7 !important;
}

#task-v-chip .v-chip.Bank {
  background: #feebf0 !important;
  color: #ff5b8c !important;
}

#task-v-chip .v-chip.KYC {
  background: #fbe6f5 !important;
  color: #ef2cb9 !important;
}

#task-v-chip .v-chip.Rent {
  background: #fdf2e3 !important;
  color: #f0ad4e !important;
}

#task-v-chip .v-chip.Completed {
  background: #def1de !important;
  color: #5cb85c !important;
}

/* kyc status chip styling */
.kyc-task-v-chip .v-chip.Address {
  background: #e0e6f8 !important;
  color: #24377d !important;
}

.kyc-task-v-chip .v-chip.Pending {
  background: #70707019 !important;
  color: #2d2d2dc6 !important;
}

.kyc-task-v-chip .v-chip.Failed {
  background: #fc000019 !important;
  color: #d11010c6 !important;
}

.kyc-task-v-chip .v-chip.Under {
  background: #d48d001e !important;
  color: #d48d00 !important;
}

.kyc-task-v-chip .v-chip.Success {
  background: #58ea0430 !important;
  color: #58ea04 !important;
}

/* ..badge-chip-styling */

#badge .v-chip.Lead {
  background: #e0e6f8 !important;
  color: #24377d !important;
  font-weight: bold;
}

#badge .v-chip.PC {
  background: #6ce72630 !important;
  color: #338603 !important;
  font-weight: bold;
}

#badge .v-chip.Applied {
  background: #f8eefe;
  color: #b554f1;
  font-weight: bold;
}

#badge .v-chip.Rejected {
  background: #fc000019;
  color: #d11010c6;
  font-weight: bold;
}
.dot-1 {
  position: relative;
  animation: dot 500ms linear alternate-reverse infinite;
}
.dot-2 {
  position: relative;
  animation: dot 500ms 150ms linear alternate-reverse infinite;
}
.dot-3 {
  position: relative;
  animation: dot 500ms 250ms linear alternate-reverse infinite;
}
.dot-4 {
  position: relative;
  animation: dot 500ms 300ms linear alternate-reverse infinite;
}
@keyframes dot {
  from {
    opacity: 1;
    /* top: 0px; */
  }
  to {
    opacity: 0;
    /* top: -15px; */
  }
}
</style>
<template>
  <div>
    <!-- filters -->
    <v-row class="pt-1 mx-0">
      <v-col class="text-center" cols="12" md="3">
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.display_daterange"
              v-on="on"
              class="filter"
              dense
              filled
              label="Filter by Date Range"
              outlined
              placeholder="Filter by Date Range"
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.date_range.length > 1"
                  @click="clearDateFilter()"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.date_range" :max="today" range>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                filters.date_range = [];
                date_menu = false;
              "
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <v-combobox
          v-model="filters.current_city"
          :items="city_list"
          :loading="city_loading"
          :search-input.sync="city_search"
          clearable
          dense
          filled
          hide-details
          label="Select city"
          outlined
          placeholder="Select city"
          single-line
          @change="getNewCustomerList(true)"
          @focus="getCitylist"
          @keyup="getCitylist"
        >
          <template v-slot:append-item>
            <div v-intersect="endIntersect"></div>
          </template>
        </v-combobox>
      </v-col>
      <v-col
        v-if="filters.profile_status == 'KYC'"
        class="text-center"
        cols="12"
        md="3"
      >
        <v-select
          v-model="filters.kyc_step"
          :items="kyc_step"
          clearable
          item-text="name"
          item-value="value"
          dense
          filled
          hide-details
          label="Select KYC Document"
          menu-props="auto"
          outlined
          placeholder="Select KYC Document"
          single-line
          @change="clearKycStep()"
        >
        </v-select>
      </v-col>
      <v-col v-if="filters.kyc_step" class="text-center" cols="12" md="3">
        <v-select
          v-model="filters.kyc_status"
          :items="kyc_status"
          item-text="name"
          item-value="value"
          dense
          filled
          hide-details
          label="Select KYC Status"
          menu-props="auto"
          outlined
          placeholder="Select KYC Status"
          single-line
          @change="getNewCustomerList(true)"
        >
        </v-select>
      </v-col>
      <v-col class="text-center" cols="12" md="3">
        <v-select
          v-model="filters.platform"
          :items="platform_list"
          dense
          filled
          hide-details
          label="Select Platform"
          menu-props="auto"
          outlined
          placeholder="Select Platform"
          single-line
          @change="getNewCustomerList(true)"
        >
        </v-select>
      </v-col>
    </v-row>

    <div>
      <!-- tabs -->
      <v-tabs show-arrows v-model="filters.selected_tab" height="95px">
        <v-tab
          v-for="(item, id) in tabs"
          :key="id"
          class="customer-tab"
          active-class="active-tab"
          @click="tabClicked(item)"
        >
          <div class="customer-tab-wrapper">
            <div class="customer-tab_number">
              <div v-if="!count_loader">
                {{ parseInt(item.value).toLocaleString("en-IN") }}
              </div>
              <div v-else>
                <span class="dot-1">●</span>
                <span class="dot-2">●</span>
                <span class="dot-3">●</span>
                <span class="dot-4">●</span>
              </div>
            </div>
            <div class="customer-tab_text">
              {{ item.title }}
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <!-- tab content -->
      <div class="mt-6">
        <!-- kyc table -->
        <div v-if="filters.profile_status == 'KYC'">
          <v-data-table
            :disable-sort="true"
            :headers="kycHeaders"
            :items="new_customer_list"
            :loading="table_loading"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="table_loading" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">Loading items...</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length == 0" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">No data found</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="item in items" :key="item.loan_id">
                <tr @dblclick="viewNewCustomerDetails(item.customer_id)">
                  <!-- New-Data-Added-Badge -->
                  <td class="">
                    <div>{{ item.customer_id }}</div>
                    <div id="badge">
                      <v-chip :class="item.lead_status">
                        {{ item.lead_status }}
                      </v-chip>
                    </div>
                  </td>
                  <!-- New-Data-Added-Badge -->
                  <td class="text-center">
                    <div v-if="item.loan_type[0]">
                      <v-chip
                        outlined
                        class="mb-2"
                        v-for="(item, index) in item.loan_type"
                        :key="index"
                        color="secondary_2"
                      >
                        {{ item.loan_type }}
                      </v-chip>
                      <!-- {{item.loan_type}} -->
                    </div>
                    <div v-else>-</div>
                  </td>
                  <td>{{ item.lms_customer_id }}</td>
                  <td class="">
                    <div id="task-v-chip">
                      <v-chip :class="item.task">
                        {{ item.task }}
                      </v-chip>
                    </div>
                    <div
                      v-if="item.blocking_days.blocked_till"
                      class="pt-0 d-flex align-center ml-2"
                    >
                      <p>
                        <v-tooltip left bottom class="d-flex">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="d-flex align-center mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="red" small>
                                mdi-block-helper
                              </v-icon>
                              <p class="text-caption font-weight-bold ml-1">
                                {{ item.blocking_days.blocked_till }}
                                <span>day(s)</span>
                              </p>
                            </div>
                          </template>
                          <span>
                            <ul
                              v-for="item in item.blocking_days.blocked_reason"
                              :key="item.rejection_reason"
                            >
                              <li>{{ item.rejection_reason }}</li>
                            </ul>
                          </span>
                        </v-tooltip>
                      </p>
                    </div>
                  </td>
                  <td>
                    <p class="pt-2">{{ item.name }}</p>
                    <p class="py-1">{{ item.mobile }}</p>
                    <p class="pb-2">{{ item.email }}</p>
                  </td>
                  <td>{{ item.city }}</td>
                  <td>
                    <div v-if="item.created">
                      <p>{{ new Date(item.created).toLocaleTimeString() }},</p>
                      <p>
                        {{ new Date(item.created).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <!-- {{ item.KYC.aadhar_status }} -->
                    <div class="kyc-task-v-chip">
                      <v-chip :class="item.KYC.aadhar_status">
                        {{ item.KYC.aadhar_status }}
                      </v-chip>
                    </div>
                    <div
                      v-if="
                        item.blocking_days.blocked_till &&
                          item.KYC.aadhar_status == 'Failed'
                      "
                      class="pt-0 d-flex align-center"
                    >
                      <p>
                        <v-tooltip left bottom class="d-flex">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="d-flex align-center mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="red" small>
                                mdi-block-helper
                              </v-icon>
                              <p class="text-caption font-weight-bold ml-1">
                                {{ item.blocking_days.blocked_till }}
                                <span>day(s)</span>
                              </p>
                            </div>
                          </template>
                          <span>
                            <ul
                              v-for="item in item.blocking_days.blocked_reason"
                              :key="item.rejection_reason"
                            >
                              <li>{{ item.rejection_reason }}</li>
                            </ul>
                          </span>
                        </v-tooltip>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="kyc-task-v-chip">
                      <v-chip :class="item.KYC.pan_status">
                        {{ item.KYC.pan_status }}
                      </v-chip>
                    </div>
                    <div
                      v-if="
                        item.blocking_days.blocked_till &&
                          item.KYC.pan_status == 'Failed'
                      "
                      class="pt-0 d-flex align-center"
                    >
                      <p>
                        <v-tooltip left bottom class="d-flex">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="d-flex align-center mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="red" small>
                                mdi-block-helper
                              </v-icon>
                              <p class="text-caption font-weight-bold ml-1">
                                {{ item.blocking_days.blocked_till }}
                                <span>day(s)</span>
                              </p>
                            </div>
                          </template>
                          <span>
                            <ul
                              v-for="item in item.blocking_days.blocked_reason"
                              :key="item.rejection_reason"
                            >
                              <li>{{ item.rejection_reason }}</li>
                            </ul>
                          </span>
                        </v-tooltip>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="kyc-task-v-chip">
                      <v-chip :class="item.KYC.selfie_status">
                        {{ item.KYC.selfie_status }}
                      </v-chip>
                    </div>
                    <div
                      v-if="
                        item.blocking_days.blocked_till &&
                          item.KYC.selfie_status == 'Failed'
                      "
                      class="pt-0 d-flex align-center"
                    >
                      <p>
                        <v-tooltip left bottom class="d-flex">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="d-flex align-center mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="red" small>
                                mdi-block-helper
                              </v-icon>
                              <p class="text-caption font-weight-bold ml-1">
                                {{ item.blocking_days.blocked_till }}
                                <span>day(s)</span>
                              </p>
                            </div>
                          </template>
                          <span>
                            <ul
                              v-for="item in item.blocking_days.blocked_reason"
                              :key="item.rejection_reason"
                            >
                              <li>{{ item.rejection_reason }}</li>
                            </ul>
                          </span>
                        </v-tooltip>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div v-if="item.kyc_last_update_date == '-'">
                      -
                    </div>
                    <div v-else-if="item.kyc_last_update_date">
                      <p>
                        {{ new Date(item.kyc_last_update_date).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.referred_by">
                      <p>{{ item.referred_by.name }}</p>
                      <p>{{ item.referred_by.mobile }}</p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.follow_up_date">
                      <p>
                        {{ new Date(item.follow_up_date).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p v-if="item.comment">
                      {{ item.comment }}
                    </p>
                    <p v-else>
                      -
                    </p>
                  </td>
                  <td>
                    <v-btn
                      color="secondary_2"
                      fab
                      small
                      text
                      @click="addComment(item)"
                      class="mr-1"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      text
                      @click="viewNewCustomerDetails(item.customer_id)"
                    >
                      <v-icon class="primary_2--text">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>

        <!-- rejected table -->
        <div v-else-if="filters.profile_step == 'Rejected'">
          <v-data-table
            :disable-sort="true"
            :headers="rejectedHeaders"
            :items="new_customer_list"
            :loading="table_loading"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="table_loading" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">Loading items...</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length == 0" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">No data found</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="item in items" :key="item.loan_id">
                <tr @dblclick="viewNewCustomerDetails(item.customer_id)">
                  <!-- New-Data-Added-Badge -->
                  <td class="text-center">
                    <div>{{ item.customer_id }}</div>
                    <div id="badge">
                      <v-chip :class="item.lead_status">
                        {{ item.lead_status }}
                      </v-chip>
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="item.loan_type[0]">
                      <v-chip
                        outlined
                        class="mb-2"
                        v-for="(item, index) in item.loan_type"
                        :key="index"
                        color="secondary_2"
                      >
                        {{ item.loan_type }}
                      </v-chip>
                      <!-- {{item.loan_type}} -->
                    </div>
                    <div v-else>-</div>
                  </td>
                  <!-- New-Data-Added-Badge -->
                  <td>{{ item.lms_customer_id }}</td>
                  <td>
                    <div id="task-v-chip">
                      <v-chip :class="item.task">
                        {{ item.task }}
                      </v-chip>
                    </div>
                  </td>
                  <td>
                    <p class="pt-2">{{ item.name }}</p>
                    <p class="py-1">{{ item.mobile }}</p>
                    <p class="pb-2">{{ item.email }}</p>
                  </td>
                  <td>{{ item.rejection_reason }}</td>
                  <td>
                    <p v-if="item.agent_assigned">
                      {{ item.agent_assigned }}
                    </p>
                    <p v-else>-</p>
                  </td>
                  <td>{{ item.city }}</td>
                  <td>
                    <div v-if="item.created">
                      <p>{{ new Date(item.created).toLocaleTimeString() }},</p>
                      <p>
                        {{ new Date(item.created).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.referred_by">
                      <p>{{ item.referred_by.name }}</p>
                      <p>{{ item.referred_by.mobile }}</p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.follow_up_date">
                      <p>
                        {{ new Date(item.follow_up_date).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p v-if="item.comment">
                      {{ item.comment }}
                    </p>
                    <p v-else>
                      -
                    </p>
                  </td>
                  <td>
                    <v-btn
                      color="secondary_2"
                      fab
                      small
                      text
                      @click="addComment(item)"
                      class="mr-1"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      text
                      @click="viewNewCustomerDetails(item.customer_id)"
                    >
                      <v-icon class="primary_2--text">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
        <!-- Applied table -->
        <div v-else-if="filters.profile_step == 'Applied'">
          <v-data-table
            :disable-sort="true"
            :headers="applied_headers"
            :items="new_customer_list"
            :loading="table_loading"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="table_loading" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">Loading items...</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length == 0" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">No data found</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="item in items" :key="item.loan_id">
                <tr @dblclick="viewNewCustomerDetails(item.customer_id)">
                  <!-- New-Data-Added-Badge -->
                  <td class="text-center">
                    <div>{{ item.customer_id }}</div>
                    <div id="badge">
                      <v-chip :class="item.lead_status">
                        {{ item.lead_status }}
                      </v-chip>
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="item.loan_type[0]">
                      <v-chip
                        outlined
                        class="mb-2"
                        v-for="(item, index) in item.loan_type"
                        :key="index"
                        color="secondary_2"
                      >
                        {{ item.loan_type }}
                      </v-chip>
                      <!-- {{item.loan_type}} -->
                    </div>
                    <div v-else>-</div>
                  </td>
                  <!-- New-Data-Added-Badge -->
                  <td>{{ item.lms_customer_id }}</td>
                  <td class="text-center">
                    <div id="task-v-chip">
                      <v-chip :class="item.task">
                        {{ item.task }}
                      </v-chip>
                    </div>
                    <div
                      v-if="item.blocking_days.blocked_till"
                      class="pt-0 d-flex align-center ml-10"
                    >
                      <p>
                        <v-tooltip left bottom class="d-flex">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="d-flex align-center mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="red" small>
                                mdi-block-helper
                              </v-icon>
                              <p class="text-caption font-weight-bold ml-1">
                                {{ item.blocking_days.blocked_till }}
                                <span>day(s)</span>
                              </p>
                            </div>
                          </template>
                          <span>
                            <ul
                              v-for="item in item.blocking_days.blocked_reason"
                              :key="item.rejection_reason"
                            >
                              <li>{{ item.rejection_reason }}</li>
                            </ul>
                          </span>
                        </v-tooltip>
                      </p>
                    </div>
                  </td>
                  <td>
                    <p class="pt-2">{{ item.name }}</p>
                    <p class="py-1">{{ item.mobile }}</p>
                    <p class="pb-2">{{ item.email }}</p>
                  </td>
                  <td>{{ item.city }}</td>
                  <td>
                    <p v-if="item.agent_assigned">
                      {{ item.agent_assigned }}
                    </p>
                    <p v-else>-</p>
                  </td>
                  <td>
                    <div v-if="item.created">
                      <p>{{ new Date(item.created).toLocaleTimeString() }},</p>
                      <p>
                        {{ new Date(item.created).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.referred_by">
                      <p>{{ item.referred_by.name }}</p>
                      <p>{{ item.referred_by.mobile }}</p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.follow_up_date">
                      <p>
                        {{ new Date(item.follow_up_date).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p v-if="item.comment">
                      {{ item.comment }}
                    </p>
                    <p v-else>
                      -
                    </p>
                  </td>
                  <td>
                    <v-btn
                      color="secondary_2"
                      fab
                      small
                      text
                      @click="addComment(item)"
                      class="mr-1"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      text
                      @click="viewNewCustomerDetails(item.customer_id)"
                    >
                      <v-icon class="primary_2--text">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>

        <!-- other status table -->
        <div v-else>
          <v-data-table
            :disable-sort="true"
            :headers="headers"
            :items="new_customer_list"
            :loading="table_loading"
            :items-per-page="$keys.PAGE_LENGTH"
            class="border-a border-0 custome-header-bg"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                bottom
                indeterminate
                class="lighten1"
                style="width: 103%; margin-left: -20px"
              >
              </v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="table_loading" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">Loading items...</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length == 0" class="text-center">
                <tr>
                  <td :colspan="headers.length">
                    <p class="text-center py-4 w-100">No data found</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="item in items" :key="item.loan_id">
                <tr @dblclick="viewNewCustomerDetails(item.customer_id)">
                  <!-- New-Data-Added-Badge -->
                  <td class="text-center">
                    <div>{{ item.customer_id }}</div>
                    <div id="badge">
                      <v-chip :class="item.lead_status">
                        {{ item.lead_status }}
                      </v-chip>
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="item.loan_type[0]">
                      <v-chip
                        outlined
                        class="mb-2"
                        v-for="(item, index) in item.loan_type"
                        :key="index"
                        color="secondary_2"
                      >
                        {{ item.loan_type }}
                      </v-chip>
                      <!-- {{item.loan_type}} -->
                    </div>
                    <div v-else>-</div>
                  </td>
                  <!-- New-Data-Added-Badge -->
                  <td>{{ item.lms_customer_id }}</td>
                  <td class="text-center">
                    <div id="task-v-chip">
                      <v-chip :class="item.task">
                        {{ item.task }}
                      </v-chip>
                    </div>
                    <div
                      v-if="item.blocking_days.blocked_till"
                      class="pt-0 d-flex align-center ml-10"
                    >
                      <p>
                        <v-tooltip left bottom class="d-flex">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="d-flex align-center mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="red" small>
                                mdi-block-helper
                              </v-icon>
                              <p class="text-caption font-weight-bold ml-1">
                                {{ item.blocking_days.blocked_till }}
                                <span>day(s)</span>
                              </p>
                            </div>
                          </template>
                          <span>
                            <ul
                              v-for="item in item.blocking_days.blocked_reason"
                              :key="item.rejection_reason"
                            >
                              <li>{{ item.rejection_reason }}</li>
                            </ul>
                          </span>
                        </v-tooltip>
                      </p>
                    </div>
                  </td>
                  <td>
                    <p class="pt-2">{{ item.name }}</p>
                    <p class="py-1">{{ item.mobile }}</p>
                    <p class="pb-2">{{ item.email }}</p>
                  </td>
                  <td>{{ item.city }}</td>
                  <td>
                    <div v-if="item.created">
                      <p>{{ new Date(item.created).toLocaleTimeString() }},</p>
                      <p>
                        {{ new Date(item.created).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.referred_by">
                      <p>{{ item.referred_by.name }}</p>
                      <p>{{ item.referred_by.mobile }}</p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <!-- referred-by-data-->
                  <td>
                    <div v-if="item.follow_up_date">
                      <p>
                        {{ new Date(item.follow_up_date).toDateString() }}
                      </p>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td>
                    <p v-if="item.comment">
                      {{ item.comment }}
                    </p>
                    <p v-else>
                      -
                    </p>
                  </td>
                  <td>
                    <v-btn
                      color="secondary_2"
                      fab
                      small
                      text
                      @click="addComment(item)"
                      class="mr-1"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      text
                      @click="viewNewCustomerDetails(item.customer_id)"
                    >
                      <v-icon class="primary_2--text">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <v-pagination
      v-model="filters.page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getNewCustomerList(true)"
    ></v-pagination>

    <AddComments
      ref="comment_dialog"
      :dialog="comment_dialog"
      @update="getNewCustomerList(true)"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    AddComments: () => import("@/components/customer/dialog/AddComments"),
  },
  data() {
    return {
      loading: false,
      activeTab: "",
      total_customer_count: 0,
      check_eligibilty_count: 0,
      application_form_count: 0,
      kyc_count: 0,
      bank_details_count: 0,
      profile_completed_count: 0,
      applied_count: 0,
      rejected_count: 0,
      count_loader: false,
      // profile_status: "",
      profile_step: "",
      headers: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "id",
          class: "primary_2 white--text",
        },
        {
          text: "Loan Type",
          align: "start",
          width: 150,
          sortable: false,
          value: "loan_type",
          class: "primary_2 white--text",
        },
        {
          text: "CIF ID",
          align: "start",
          sortable: false,
          value: "lms_customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Task",
          value: "task",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Referred By",
          value: "referred_by",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Follow-up Date",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
          class: "primary_2 white--text",
        },
      ],
      applied_headers: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "id",
          class: "primary_2 white--text",
        },
        {
          text: "Loan Type",
          align: "start",
          sortable: false,
          value: "loan_type",
          class: "primary_2 white--text",
        },
        {
          text: "CIF ID",
          align: "start",
          sortable: false,
          value: "lms_customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Task",
          value: "task",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Agent",
          value: "agent_assigned",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Referred By",
          value: "referred_by",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Follow-up Date",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
          class: "primary_2 white--text",
        },
      ],
      kycHeaders: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "id",
          class: "primary_2 white--text",
        },
        {
          text: "Loan Type",
          align: "start",
          sortable: false,
          value: "loan_type",
          class: "primary_2 white--text",
        },
        {
          text: "CIF ID",
          align: "start",
          sortable: false,
          value: "lms_customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Task",
          value: "task",
          sortable: false,
          class: "primary_2 white--text",
          width: 150,
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          sortable: false,
          width: 150,
          class: "primary_2 white--text",
        },
        {
          text: "Aadhaar Status",
          value: "task",
          width: 120,
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "PAN Status",
          value: "task",
          width: 120,
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Selfie Status",
          value: "task",
          width: 120,
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Last Updated On",
          value: "kyc_last_update_date",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Referred By",
          value: "referred_by",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Follow-up Date",
          value: "created",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          width: 120,
          sortable: false,
          class: "primary_2 white--text",
        },
      ],
      rejectedHeaders: [
        {
          text: "Lead No.",
          align: "start",
          sortable: false,
          value: "id",
          class: "primary_2 white--text",
        },
        {
          text: "Loan Type",
          align: "start",
          sortable: false,
          value: "loan_type",
          class: "primary_2 white--text",
        },
        {
          text: "CIF ID",
          align: "start",
          sortable: false,
          value: "lms_customer_id",
          class: "primary_2 white--text",
        },
        {
          text: "Task",
          value: "task",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Customer Info",
          value: "name",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Rejection Reason",
          value: "rejection_reason",
          width: 200,
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Agent",
          value: "agent_assigned",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Signed-up At",
          value: "created",
          width: 140,
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Referred By",
          value: "referred_by",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Follow-up Date",
          value: "created",
          width: 140,
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          class: "primary_2 white--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
          class: "primary_2 white--text",
        },
      ],

      loans: [],
      table_loading: false,
      page_number: 1,
      total_page_count: 2,
      new_customer_list: [],

      active_table: "",

      date_menu: false,
      display_daterange: null,
      today: new Date().toISOString().substr(0, 10),
      city_list: [],
      city_search_query: "",
      city_loading: false,
      city_page: 1,
      city_search: "",
      comment_dialog: {
        flag: false,
        comment: null,
        follow_up_date: null,
        label: null,
        id: null,
      },
      check_eligibility_key: [
        { stage: "Check Eligibility Stage1" },
        { stage1: "Check Eligibility Stage2" },
      ],
      application_form_key:
        ("Employment Details",
        "General Info",
        "Address Details",
        "Rent Address Proofff"),

      kyc_step: [
        { name: "All", value: ["aadhaar", "pan", "selfie"] },
        { name: "Aadhaar", value: "aadhaar" },
        { name: "PAN", value: "pan" },
        { name: "Selfie", value: "selfie" },
      ],
      kyc_status: [
        { name: "Pending", value: "Pending" },
        { name: "Under Review", value: "Under Review" },
        { name: "Success", value: "Success" },
        { name: "Failed", value: "Failed" },
      ],
      platform_list: ["Web", "Android"],
    };
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
      filters: "new_customer/getFilters",
    }),
    itemsWithSno() {
      return this.new_customer_list.map((d, index) => ({
        ...d,
        sno: index + 1,
      }));
    },

    tabs() {
      return [
        {
          id: 1,
          title: "Total Customers",
          key: "",
          value: `${this.total_customer_count}`,
        },
        {
          id: 2,
          title: "Check Eligibility",
          // key: `${this.check_eligibility_key}`,
          key: ["Check Eligibility Stage1", "Check Eligibility Stage2"],
          value: `${this.check_eligibilty_count}`,
        },
        {
          id: 3,
          title: "Application Form",
          // key: `${this.application_form_key}`,
          key: [
            "Employment Details",
            "General Info",
            "Address Details",
            "Rent Address Proof",
          ],
          value: `${this.application_form_count}`,
        },
        {
          id: 4,
          title: "KYC",
          key: ["KYC"],
          value: `${this.kyc_count}`,
        },
        {
          id: 5,
          title: "Bank Details",
          key: ["Bank Statement"],
          value: `${this.bank_details_count}`,
        },
        {
          id: 6,
          title: "Profile Completed",
          key: ["Profile Completed"],
          value: `${this.profile_completed_count}`,
        },
        {
          id: 7,
          title: "Applied",
          key: ["Applied"],
          value: `${this.applied_count}`,
        },
        {
          id: 9,
          title: "Rejected",
          key: ["Rejected"],
          // value: `${this.rejected_count}`,
          value: `${this.rejected_count}`,
        },
      ];
    },
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      this.getNewCustomerList(true);
    },
  },

  created() {
    console.log(this.$route.meta.show_back_btn);
    this.getNewCustomerList(true);
  },

  methods: {
    // change profile_status value on tab click
    tabClicked(value) {
      console.log("value", value);
      // this.profile_status = value;
      if (value.key == "Applied" || value.key == "Rejected") {
        this.filters.selectedTab = value.id - 1;
        this.filters.profile_status = "";
        this.filters.profile_step = value.key;
        this.filters.page_number = 1;
        this.getNewCustomerList(true);
        this.filters.kyc_step = "";
        this.filters.kyc_status = "";
      } else {
        this.filters.selectedTab = value.id - 1;
        this.filters.profile_status = value.key;
        this.filters.profile_step = "";
        this.filters.page_number = 1;
        if (value.key != "KYC") {
          this.filters.kyc_step = "";
          this.filters.kyc_status = "";
          this.getNewCustomerList(true);
        } else {
          this.getNewCustomerList(true);
        }
      }
    },

    // redirect to customer details page
    viewNewCustomerDetails(id) {
      this.$router.push({
        name: "new-customer-details",
        params: {
          id: this.encript(id),
        },
      });
    },

    // fetch table data from server
    getNewCustomerList(isCancel = false) {
      const self = this;

      self.table_loading = true;
      self.new_customer_list = [];

      // for status date filter
      let step_status = this.filters.loan_steps
        ? "step_" + this.filters.loan_steps.replace(/ /g, "_").toLowerCase()
        : null;

      // console.log("step",step_status)

      if (step_status == null || step_status == "step_lead") {
        this.isLoanStatusLead = true;
        this.daterange = [];
        this.filters.display_status_daterange = null;
        this.filters.status_date_range = [];
      }
      if (step_status != "step_lead" && step_status != null) {
        this.isLoanStatusLead = false;
      }
      let from_date = "";
      let to_date = "";

      if (
        self.filters.date_range[0] != undefined &&
        self.filters.date_range[1] != undefined
      ) {
        from_date = self.filters.date_range[0];
        to_date = self.filters.date_range[1];
        // console.log("dates are not empty");
        // console.log(to_date);
        // console.log(from_date);
      }
      let kyc_step = "";
      let kyc_status = "";
      if (self.filters.kyc_step && self.filters.kyc_status) {
        kyc_step = self.filters.kyc_step;
        kyc_status = self.filters.kyc_status;
      }

      let form = {
        page_number: self.filters.page_number,
        page_length: self.$keys.PAGE_LENGTH,
        profile_status: self.filters.profile_status,
        loan_status: self.filters.profile_step,
        search_query: self.search_query,
        from_date: from_date,
        to_date: to_date,
        city: self.filters.current_city,
        kyc_step: kyc_step,
        kyc_status: kyc_status,
        platform: this.filters.platform,
      };

      self.getNewCustomerCount();
      const successHandler = (response) => {
        // console.log("post list",response);
        self.table_loading = false;
        self.new_customer_list = [];
        if (response.data.success) {
          self.new_customer_list = response.data.customer_list;
          self.total_page_count = response.data.total_page_count;
          // self.total_customer_count = response.data.total_customer;
          // self.check_eligibilty_count = response.data.check_eligibility;
          // self.application_form_count = response.data.application_form;
          // self.kyc_count = response.data.KYC;
          // self.bank_details_count = response.data.bank_details;
          // self.profile_completed_count = response.data.profile_complete;
          // self.applied_count = response.data.applied;
          // self.rejected_count = response.data.rejected;
        }
      };
      const finallyHandler = () => {};
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_COUNT,
        form,
        successHandler,
        null,
        null,
        finallyHandler,
        isCancel
      );
    },
    getNewCustomerCount(isCancel = false) {
      const self = this;

      self.count_loader = true;

      // for status date filter
      let step_status = this.filters.loan_steps
        ? "step_" + this.filters.loan_steps.replace(/ /g, "_").toLowerCase()
        : null;

      // console.log("step",step_status)

      if (step_status == null || step_status == "step_lead") {
        this.isLoanStatusLead = true;
        this.daterange = [];
        this.filters.display_status_daterange = null;
        this.filters.status_date_range = [];
      }
      if (step_status != "step_lead" && step_status != null) {
        this.isLoanStatusLead = false;
      }
      let from_date = "";
      let to_date = "";

      if (
        self.filters.date_range[0] != undefined &&
        self.filters.date_range[1] != undefined
      ) {
        from_date = self.filters.date_range[0];
        to_date = self.filters.date_range[1];
        // console.log("dates are not empty");
        // console.log(to_date);
        // console.log(from_date);
      }
      let kyc_step = "";
      let kyc_status = "";
      if (self.filters.kyc_step && self.filters.kyc_status) {
        kyc_step = self.filters.kyc_step;
        kyc_status = self.filters.kyc_status;
      }

      let form = {
        page_number: self.filters.page_number,
        page_length: self.$keys.PAGE_LENGTH,
        profile_status: self.filters.profile_status,
        loan_status: self.filters.profile_step,
        search_query: self.search_query,
        from_date: from_date,
        to_date: to_date,
        city: self.filters.current_city,
        kyc_step: kyc_step,
        kyc_status: kyc_status,
        platform: this.filters.platform,
      };

      const successHandler = (response) => {
        if (response.data.success) {
          self.total_customer_count = response.data.total_customer;
          self.check_eligibilty_count = response.data.check_eligibility;
          self.application_form_count = response.data.application_form;
          self.kyc_count = response.data.KYC;
          self.bank_details_count = response.data.bank_details;
          self.profile_completed_count = response.data.profile_complete;
          self.applied_count = response.data.applied;
          self.rejected_count = response.data.rejected;
        }
      };
      const finallyHandler = () => {
        self.count_loader = false;
      };
      self.request_POST(
        self,
        self.$urls.GET_NEW_CUSTOMER_COUNT,
        form,
        successHandler,
        null,
        null,
        finallyHandler,
        isCancel
      );
    },

    /* fetching the cit list form server*/
    getCitylist(append_city = false) {
      const self = this;
      self.city_loading = true;
      let params = {
        search_query: this.city_search,
        page_number: this.city_page,
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          if (append_city)
            self.city_list = self.city_list.concat(response.data.city_list);
          else self.city_list = response.data.city_list;
        }
      };
      const finallyHandler = () => {
        self.city_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.CITY_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    clearKycStep() {
      this.filters.kyc_status = "";
      this.getNewCustomerList(true);
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.city_page++;
        this.getCitylist(true);
      }
    },
    formateDisplayDate() {
      if (this.filters.date_range.length > 1)
        this.filters.display_daterange = this.formateDisplayDateRange(
          this.filters.date_range
        );
      if (this.filters.status_date_range.length > 1)
        this.filters.display_status_daterange = this.formateDisplayDateRange(
          this.filters.status_date_range
        );
      this.date_menu = false;
      this.status_date_menu = false;
      this.getNewCustomerList(true);
    },
    clearDateFilter() {
      this.daterange = [];
      this.filters.display_daterange = null;
      this.filters.date_range = [];
      this.getNewCustomerList(true);
    },
    formateDisplayDateRange(daterange) {
      console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.display_date_formate(from);
      to = this.display_date_formate(to);
      return `${from} to ${to}`;
    },
    addComment(item) {
      this.comment_dialog.comment = item.comment == " - " ? "" : item.comment;
      this.comment_dialog.id = item.customer_id;
      this.comment_dialog.follow_up_date = item.follow_up_date;
      this.comment_dialog.label = item.label;
      this.comment_dialog.flag = true;
      this.$refs.comment_dialog.formateDisplayDate();
    },
  },
};
</script>
