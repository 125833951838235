<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}

.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.report-summary-table,
.credit-account-info-table,
.application-info-table {
  width: 100%;
  border-collapse: collapse;
}
.report-summary-table-cell {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.application-info-table-cell {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.credit-account-info-table > tr > th {
  font-size: 13px;
  background-color: #cfd8dc9f;
}
.credit-account-info-table > tr > td {
  font-size: 13px;
  color: #000000;
  padding: 5px 0;
  /* font */
}
.credit-account-info-table > tr:hover {
  background-color: #e2e2e24a;
}
.display-flex {
  display: flex;
}
.title-banner {
  color: #000000;
  background-color: rgba(208, 208, 208, 0.57);
}
.red-text {
  color: #e50000;
  font-size: 10px;
}
.logo {
  width: 150px;
}
.border-around {
  border: 1px solid rgba(144, 144, 144, 0.211);
}
</style>
<template>
  <div>
    <v-dialog dialog v-model="show_dialog" fullscreen>
      <v-card>
        <v-card-title class="text-h6 primary_2 white--text">
          Complete CIBIL Report Details
          <v-spacer></v-spacer>
          <div>
            <v-btn
              depressed
              small
              color="white"
              id="print-btn"
              outlined
              @click="printDoc()"
              >Print</v-btn
            >
            <v-btn text dark @click="show_dialog = false">
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text id="print-section">
          
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import cibil_res from "../cibil-response";

export default {
  data() {
    return {
      show_dialog: false,
    };
  },
  computed: {
    cibil() {
      // return cibil_res ? cibil_res : "";
      return ""
    },
  },
  methods: {
    async openCibilAllDetails() {
      this.show_dialog = true;
    },
    checkForValue(value) {
      if (value) {
        if (value.text) return value.text;
        else return "-";
      } else {
        return "-";
      }
    },
    checkForValueEmpty(value) {
      if (value) {
        if (value.text) return value.text;
        else return "";
      } else {
        return "";
      }
    },
    printDoc() {
      // document.getElementById("print-btn").onclick = function() {
      //   printElement(document.getElementById("print-section"));
      // };
      // function printElement(elem) {
      //   var domClone = elem.cloneNode(true);
      //   var $printSection = document.getElementById("print-section");
      //   // if (!$printSection) {
      //   //   var $printSection = document.createElement("div");
      //   //   $printSection.id = "print-section";
      //   //   document.body.appendChild($printSection);
      //   // }
      //   $printSection.innerHTML = "";
      //   $printSection.appendChild(domClone);
      //   window.print();
      // }
      // works but without css
      // const printableContent = document.getElementById("print-section");
      // const printWindow = window.open("", "", "height=1000,width=1000");
      // printWindow.document.write(printableContent.innerHTML);
      // printWindow.print();
      // window.print()
    },
  },
};
</script>
