<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

/* width */
.comments-wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.comments-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.comments-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.comments-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.comments-wrapper {
  position: relative;
  overflow-y: scroll;
}
.pre-check-dialog {
  overflow-x: hidden;
}
</style>
<template>
  <div>
    <v-dialog width="750" scrollable v-model="dialog.flag">
      <v-card>
        <v-card-title
          class="primary_2 d-flex justify-space-between font-weight-bold white--text"
        >
          Pre-verify Documents
          <div>
            <v-btn
              color="white"
              fab
              small
              outlined
              @click="addComment()"
              class="mr-3"
            >
              <v-icon>mdi-message-text-outline</v-icon>
            </v-btn>
            <v-btn text small fab @click="dialog.flag = false">
              <v-icon large color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="px-0 comments-wrapper pre-check-dialog">
          <v-row class="py-3 px-5">
            <v-col cols="5">
              <p class="field-title">Follow-up Date</p>
              <p v-if="basic_info.follow_up_date" class="field-content">
                {{ basic_info.follow_up_date }}
              </p>
              <p v-else class="field-content">-</p>
            </v-col>
            <v-col cols="7">
              <p class="field-title">Comment</p>
              <p v-if="basic_info.comment" class="field-content">
                {{ basic_info.comment }}
              </p>
              <p v-else class="field-content">-</p>
            </v-col>
          </v-row>
          <KycSection />
          <RentAddressProofSection />
          <BankStatementSection />
          <OtherDocument />
          <div class="tab_header d-flex black--text font-weight-bold px-2 mb-1">
            <div
              style="width: 75%"
              class="d-flex align-center py-3"
              @click="toggleAccordion()"
            >
              <div class="text-body-1 font-weight-medium px-3">
                Finvu Details
              </div>
            </div>
            <div style="width: 25%" class="d-flex align-center justify-end">
              <v-icon
                color="black"
                class="mx-3"
                v-if="show_finvu"
                @click="toggleAccordion()"
              >
                mdi-chevron-up
              </v-icon>
              <v-icon
                color="black"
                class="mx-3"
                v-else
                @click="toggleAccordion()"
              >
                mdi-chevron-down
              </v-icon>
            </div>
          </div>
          <div v-if="show_finvu">
            <FinvuSection></FinvuSection>
          </div>
          <!-- </v-card-text> -->
          <v-divider></v-divider>
          <!-- <v-card-actions class="pa-4"> -->
          <div class="pt-3 d-flex justify-end">
            <v-btn
              dark
              small
              elevation="0"
              color="success"
              @click="openAllotNbfcDialog()"
              class="font-weight-bold"
            >
              Allot NBFC
            </v-btn>
            <v-btn
              dark
              small
              elevation="0"
              @click="openRejectionReasonDialog()"
              color="red"
              class="ml-3 mr-5 font-weight-bold"
            >
              Reject Pre-verification
            </v-btn>
          </div>
        </v-card-text>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>

    <AllotNbfc
      v-if="allot_nbfc_dialog_data.flag"
      :dialog="allot_nbfc_dialog_data"
      @closeDialog="closeDialog()"
    />
    <!-- rejection reason dialog -->
    <RejectionReasonDialog
      v-if="rejection_reason_dialog_data.flag"
      :dialog="rejection_reason_dialog_data"
      @closeDialog="closeDialog()"
    />
    <!-- v-if="comment_dialog.flag" -->
    <AddComments
      ref="comment_dialog"
      :dialog="comment_dialog"
      @update="getBasicInfo()"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["dialog"],
  components: {
    KycSection: () => import("@/components/pre-verification/KycSection"),
    RentAddressProofSection: () =>
      import("@/components/pre-verification/RentAddressProofSection"),
    BankStatementSection: () =>
      import("@/components/pre-verification/BankStatementSection"),
    OtherDocument: () => import("@/components/pre-verification/OtherDocument"),
    AllotNbfc: () => import("@/components/pre-verification/dialog/AllotNbfc"),
    RejectionReasonDialog: () =>
      import("@/components/pre-verification/dialog/RejectionReasonDialog"),
    AddComments: () => import("@/components/customer/dialog/AddComments"),
    FinvuSection: () => import("@/components/new-customer/FinvuSection"),
  },
  destroyed() {
    this.$store.dispatch("pre_verification/setCustomerId", null);
    this.$store.dispatch("pre_verification/setActiveLoanId", null);
    this.$store.dispatch("pre_verification/setBankStatementStatus", false);
    this.$store.dispatch("pre_verification/setAddressProofStatus", false);
    this.$store.dispatch("pre_verification/setAddressProofUploadStatus", false);
    this.$store.dispatch(
      "pre_verification/setBankStatementUploadStatus",
      false
    );
  },
  computed: {
    ...mapGetters({
      active_loan_id: "pre_verification/getActiveLoanId",
      is_address_proof_verified: "pre_verification/getRentAddressProofStatus",
      is_bank_statement_verified: "pre_verification/getBankStatementStatus",
      is_address_proof_uploaded:
        "pre_verification/getRentAddressProofUploadStatus",
      is_bank_statement_uploaded:
        "pre_verification/getBankStatementUploadStatus",
    }),
  },
  mounted() {
    // this.getNbfclist();
    this.getBasicInfo();
  },
  data() {
    return {
      show_finvu: true,
      headers: [
        {
          text: "Document Name",
          sortable: false,
          value: "document_type",
          class: "primary white--text",
        },
        {
          text: "Status",
          sortable: false,
          value: "document_status",
          class: "primary white--text",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          class: "primary white--text",
        },
      ],
      rejection_reason_dialog_data: {
        flag: false,
        reject_pre_verification: false,
        document_id: null,
        document_type: "",
      },
      allot_nbfc_dialog_data: {
        flag: false,
        loan_id: null,
      },
      nbfc_list: [],
      selected_nbfc: "",
      comment_dialog: {
        flag: false,
        comment: null,
        follow_up_date: null,
        label: null,
        id: null,
      },
      basic_info: [],
    };
  },
  methods: {
    toggleAccordion() {
      this.show_finvu = !this.show_finvu;
    },
    openRejectionReasonDialog() {
      this.rejection_reason_dialog_data.flag = true;
      this.rejection_reason_dialog_data.reject_pre_verification = true;
    },
    openAllotNbfcDialog() {
      if (this.is_address_proof_verified && !this.is_address_proof_uploaded) {
        this.allot_nbfc_dialog_data.flag = true;
        this.allot_nbfc_dialog_data.loan_id = this.active_loan_id;
      } else {
        if (
          !this.is_bank_statement_verified &&
          this.is_address_proof_verified
        ) {
          this.showSnakeBar(
            "error",
            "Alteast one Bank Statement needs to be verified"
          );
        } else if (
          this.is_bank_statement_verified &&
          !this.is_address_proof_verified
        ) {
          this.showSnakeBar(
            "error",
            "Alteast one Rent Address Proof needs to be verified"
          );
        } else if (
          this.is_address_proof_uploaded &&
          !this.is_bank_statement_uploaded
        ) {
          this.showSnakeBar(
            "error",
            "All the Rent Address Proof needs to be Verified or Rejected"
          );
        } else if (
          !this.is_address_proof_uploaded &&
          this.is_bank_statement_uploaded
        ) {
          this.showSnakeBar(
            "error",
            "All the Bank Statement needs to be Verified or Rejected"
          );
        } else {
          this.showSnakeBar(
            "error",
            "Alteast one Rent Address Proof and one Bank Statement needs to be verified"
          );
        }
      }
    },
    closeDialog() {
      this.dialog.flag = false;
      this.$emit("onSuccess");
    },
    getBasicInfo() {
      const self = this;

      self.loading = true;

      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };
      // console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("basic info", response);
          self.basic_info = response.data.user_data;
        }
      };
      const finallyHandler = () => {
        self.loading = false;
      };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_BASIC_INFO,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    addComment() {
      this.comment_dialog.comment =
        this.basic_info.comment == " - " ? "" : this.basic_info.comment;
      this.comment_dialog.id = this.basic_info.id;
      this.comment_dialog.follow_up_date = this.basic_info.follow_up_date;
      this.comment_dialog.label = null;
      this.comment_dialog.flag = true;
      this.$refs.comment_dialog.formateDisplayDate();
    },
  },
};
</script>
