<template>
  <div>
    <div class="send-btn-wrapper">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Dropdown
          </v-btn> -->
          <v-btn
            elevation="0"
            dark
            v-bind="attrs"
            v-on="on"
            class="send-btn"
            block
            rounded
          >
            Send Notification
          </v-btn>
        </template>
        <v-list class="send-btn-options-wrapper">
          <v-list-item
            v-for="(item, index) in send_options"
            :key="index"
            class="send-btn-option"
            @click="openSendNotificationDialog(item)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="pr-5 pl-4 mt-9">
      <TopicsListCard />
    </div>

    <div class="pr-5 pl-4 mt-9">
      <TemplatesListCard />
    </div>
    <SendNotificationDialog ref="send_notification_dialg" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      send_options: [
        { text: "Bulk", value: "bulk" },
        { text: "Individual", value: "individual" },
      ],
      show_send_options: false,
    };
  },
  components: {
    TopicsListCard: () =>
      import("@/components/notifications/cards/TopicsListCard.vue"),
    TemplatesListCard: () =>
      import("@/components/notifications/cards/TemplatesListCard.vue"),
    SendNotificationDialog: () =>
      import("@/components/notifications/dialogs/SendNotification.vue"),
  },
  methods: {
    openSendNotificationDialog(item) {
      this.show_send_options = false;
      // console.log("openSendNotificationDialog", item.value);
      this.$refs.send_notification_dialg.openSendNotificationDialog(item.value);
    },
  },
};
</script>
<style scoped>
.send-btn {
  color: #fff;
  font-weight: 800;
  background: hsla(197, 100%, 63%, 1);

  background: linear-gradient(
    45deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  background: -moz-linear-gradient(
    45deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  background: -webkit-linear-gradient(
    45deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  /* box-shadow: 0px 0px 12px hsla(294, 100%, 55%, 1) !important; */

  /* box-shadow: 6px 6px 12px #b5b5b5, -6px -6px 12px #ffffff; */
  box-shadow: 6px 6px 12px #7e7e7ea9, -6px -6px 6px #ffffff !important;
  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#40C9FF", endColorstr="#E81CFF", GradientType=1 ); */
}
.send-btn:hover {
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.send-btn-wrapper {
  position: relative;
}
/* .send-btn-option {
  padding: 5px 20px;
} */
.send-btn-options-wrapper {
  border-radius: 10px;
}
.send-btn-option:hover {
  background: #dbe1e7;
}
</style>
