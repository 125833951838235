<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <div class="px-3">
            <TopicAnalysisCard />
          </div>
        </v-col>
        <v-col cols="5" class="">
          <div class="px-3">
            <NotificationSentCardData />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="px-3">
            <NotificationCountAnalysis />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TopicAnalysisCard: () =>
      import("@/components/notifications/cards/TopicAnalysisCard.vue"),
    NotificationSentCardData: () =>
      import("@/components/notifications/cards/NotificationSentCardData.vue"),
    NotificationCountAnalysis: () =>
      import("@/components/notifications/cards/NotificationCountAnalysis.vue"),
  },
  data() {
    return {
      analysis_data: [],
    };
  },
  computed: {
    ...mapGetters({
      refresh_api: "notification/getRefreshApi",
    }),
  },
  watch: {
    refresh_api() {
      this.getNotificationAnalysisData();
    },
  },
  mounted() {
    this.getNotificationAnalysisData();
  },
  methods: {
    getNotificationAnalysisData() {
      const self = this;
      self.$store.dispatch("notification/setIsLoading", true);
      self.table_loading = true;
      const lookup_key = "";
      self.analysis_data = [];
      let params = {
        notification_type: this.notification_type,
      };
      const successHandler = (response) => {
        // console.log("getNotificationAnalysisData", response);
        self.analysis_data = response.data.result;
        this.$store.dispatch(
          "notification/setAnalysisData",
          response.data.result
        );
        this.$store.dispatch(
          "notification/setAnalysisData",
          response.data.result
        );
        this.$store.dispatch(
          "notification/setDonutData",
          response.data.result.donut_chart_data
        );
        this.$store.dispatch(
          "notification/setGrowthDate",
          response.data.result.growth_analysis_data
        );
        this.$store.dispatch(
          "notification/setSplineData",
          response.data.result.spline_data
        );
        self.$store.dispatch("notification/setIsLoading", false);
      };
      const failureHandler = () => {
        self.$store.dispatch("notification/setIsLoading", false);
      };
      const finallyHandler = () => {
        self.$store.dispatch("notification/setIsLoading", false);
      };
      self.GET_request(
        self,
        self.$urls.GET_ANALYSIS_DATA,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
  },
};
</script>
